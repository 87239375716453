// permet d'appeler des fonctions directement dans les componsants sans faire d'import de fichier.
// ces composants sont appelÃ©s this this.xxxx
// exemple : this.$api.get ...
import moment from "moment"
import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';
moment.locale('fr')

const functions = {
    install(Vue) {
        Vue.use(VueSweetalert2);
        Vue.prototype.slugify = (value, separator) => {
            return value
                .toString()
                .normalize('NFD') // split an accented letter in the base letter and the acent
                .replace(/[\u0300-\u036f]/g, '') // remove all previously split accents
                .toLowerCase()
                .trim()
                .replace(/[^a-z0-9 ]/g, '') // remove all chars not letters, numbers and spaces (to be replaced)
                .replace(/\s+/g, separator);
        }
        Vue.prototype.formatDate = (value) => {
            return moment(value).format("LLL");
        }
        Vue.prototype.copyLink = (url, code, slug, titleToast) => {
            Vue.swal({
                toast: true,
                icon: 'success',
                timer: 1000,
                title: titleToast,
                position: "center",
                showConfirmButton: false,
            });
            navigator.clipboard.writeText(url + code + '/' + slug);
        }
    },
};

export default functions;