<template>
    <div>
        <v-container>
            <div class="rounded-xl mt-5">
                <v-container class="text-center">
                    <v-btn class="bg-danger mb-5" v-if="showDeleteBtnTicket" rounded dark @click="deleteTicket()">
                        <v-icon>mdi-delete</v-icon> Supprimer le ticket
                    </v-btn>
                    <h1>{{ ticket.titre }}</h1>
                    <p>Type de problème : <strong>{{ ticket.type_probleme }}</strong></p>
                    <v-chip :color="colorStatus()" large class="fw-bold fs-5">{{ ticket.status['libelle'] }}</v-chip>
                    <p class="mt-2"><cite>Ticket ouvert le {{ ticket.dateCreation }}</cite></p>
                    <div class="d-flex justify-content-center mb-5">
                        <v-card class="text-break rounded-xl p-1">
                            <v-card-title>Explication du problème :</v-card-title>
                            <v-card-text class="text-break fs-5 text-left">{{ ticket.explications }}
                            </v-card-text>
                        </v-card>
                    </div>
                </v-container>
            </div>
            <v-textarea class="mt-5" :disabled="ticket.status['id'] != 2 ? false : true" v-model="message" filled
                rounded placeholder="Votre message . . .">
            </v-textarea>
            <div class="d-flex justify-content-end">
                <v-btn @click="sendMessage()" rounded class="bg-color-main" :loading="loadingBtn"
                    :disabled="(message.length > 0 && ticket.status['id'] != 2) ? false : true">
                    Envoyer <v-icon>
                        mdi-send</v-icon>
                </v-btn>
            </div>
            <template>
                <v-timeline dense clipped>
                    <v-timeline-item v-for="message in ticket.messages" large v-bind:key="message.id" right>
                        <template v-slot:icon>
                            <v-avatar>
                                <img :src="message.photo">
                            </v-avatar>
                        </template>
                        <v-card class="rounded-xl">
                            <v-card-title class="bg-color-main">
                                <v-icon dark size="42" class="mr-4">
                                    mdi-message
                                </v-icon>
                                <h2 class="text-h4 white--text fw-bold">
                                    {{ message.pseudo }} a dit
                                </h2>
                            </v-card-title>
                            <v-container>
                                <p class="text-break ml-5 fs-4">
                                    {{ message.message }}
                                </p>
                                <cite>{{ message.created_at }}</cite>
                            </v-container>
                        </v-card>
                    </v-timeline-item>
                </v-timeline>
            </template>
            <v-dialog v-model="dialogDeleteTicket" width="500">
                <v-card class="rounded-xl">
                    <v-toolbar class="mb-5 bg-color-main" dark>
                        <v-toolbar-title>
                            Que voulez-vous faire ?
                        </v-toolbar-title>
                        <v-spacer></v-spacer>
                        <v-icon @click="dialogDeleteTicket = false">mdi-close</v-icon>
                    </v-toolbar>
                    <v-card-text class="container lead fs-5">
                        Êtes-vous sûr de vouloir supprimer ce ticket ?
                    </v-card-text>
                    <v-card-actions class="d-flex justify-content-end">
                        <v-btn rounded class="bg-main text-dark" @click="confirmDeleteTicket()">Oui</v-btn>
                        <v-btn rounded class="bg-color-main text-white" @click="dialogDeleteTicket = false">Non</v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </v-container>
    </div>
</template>

<script>
import moment from "moment";
moment.locale("fr");
export default {
    metaInfo() {
        return {
            title: this.ticket.titre + " | Pocketuto ",
            meta: [
                { vmid: 'description', name: 'description', content: "Contenu du ticket" }
            ]
        }
    },
    props: ['idTicket'],
    data() {
        return {
            ticket: {
                titre: "",
                type_probleme: "",
                explications: "",
                messages: [],
                status: "",
            },
            dialogDeleteTicket: false,
            message: "",
            loadingBtn: false,
            user: [],
            showDeleteBtnTicket: false,
        }
    },
    activated() {
        this.getContentTicket()
        var refreshIntervalId2 = setInterval(() => {
            if (this.$store.getters["auth/dataLoad"]) {
                if (this.$store.getters["auth/dataLoad"]) {
                    this.user = this.$store.getters["auth/user"];
                }
                clearInterval(refreshIntervalId2);
            }
        }, 500);
    },
    methods: {
        getContentTicket() {
            this.$store.dispatch('tickets/getContenuTicket', this.idTicket).then((response) => {
                if (response.data.message) {
                    this.$swal({
                        toast: true,
                        icon: "error",
                        timer: 3000,
                        title: response.data.message,
                        position: "top-right",
                        showConfirmButton: false,
                    });
                    return
                }
                this.ticket = response.data.ticket
                if (this.ticket.status['id'] == 0) {
                    this.showDeleteBtnTicket = true
                } else {
                    this.showDeleteBtnTicket = false
                }
            })
        },
        sendMessage() {
            this.loadingBtn = true
            var payload = {
                ticket_id: this.ticket.id,
                message: this.message
            }
            this.$store.dispatch('tickets/storeMessage', payload).then((response) => {
                this.getContentTicket()
                this.$swal({
                    toast: true,
                    icon: "success",
                    timer: 3000,
                    title: response.data.message,
                    position: "top-right",
                    showConfirmButton: false,
                });
                this.message = ""
                this.loadingBtn = false
            })
        },
        deleteTicket() {
            this.dialogDeleteTicket = true
        },
        confirmDeleteTicket() {
            this.$axios.delete('/tickets/deleteTicket/' + this.ticket.id).then((response) => {
                if (response.data.messageError) {
                    this.$swal({
                        toast: true,
                        icon: "error",
                        timer: 3000,
                        title: response.data.messageError,
                        position: "top-right",
                        showConfirmButton: false,
                    });
                    this.dialogDeleteTicket = false
                    return false;
                }
                this.$swal({
                    toast: true,
                    icon: "success",
                    timer: 3000,
                    title: response.data.message,
                    position: "top-right",
                    showConfirmButton: false,
                });
                this.$router.push({ name: 'Support' })
            })
        },
        colorStatus() {
            switch (this.ticket.status['id']) {
                case 0:
                    return 'grey'
                case 1:
                    return 'green'
                case 2:
                    return 'red'
                default:
                    break;
            }
        },
    }
}
</script>

<style>

</style>