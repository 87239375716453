<template>
  <div>
    <v-card>
      <div class="container-xxl">
        <v-toolbar class="pt-5 sticky elevation-0 mb-5" :class="isDarkMode ? 'bg-dark-vuetify' : 'bg-white'">
          <v-spacer></v-spacer>
          <v-toolbar-items>
            <v-btn large @click="
              copyLink(
                'https://pocketuto.fr/dossier/',
                editedItem.code_dossier,
                slugify(editedItem.titre, '-'),
                'Le lien du dossier a bien été copié !'
              )
            " class="bg-color-main">
              <v-icon>mdi-link</v-icon>
            </v-btn>
          </v-toolbar-items>
          <v-toolbar-items v-if="user">
            <v-btn large @click="openActions = true" class="bg-color-main">
              <v-icon>mdi-dots-vertical</v-icon>
            </v-btn>
          </v-toolbar-items>
          <v-spacer></v-spacer>
        </v-toolbar>
        <v-list three-line subheader class="pt-3">
          <h3 v-if="!editedItem.isOwn" class="text-center mt-4" style="font-size: 15px">
            Vous avez les droits
            <strong>{{
                editedItem.hasRight ? "Admin" : "de lecture seule"
            }}</strong>
            sur ce dossier
          </h3>
          <hr />
          <v-list-item>
            <v-list-item-content>
              <v-card-title>
                <i>Informations</i>
              </v-card-title>
              <div class="mt-5 text-center">
                <v-avatar class="mr-2">
                  <v-img width="80" :src="editedItem.createur.photo"></v-img>
                </v-avatar>
                <strong>{{ editedItem.createur.pseudo }}</strong>
              </div>
              <v-list-item-subtitle class="text-center my-5">
                <span class="badge bg-primary text-right mb-2 m-1 fs-6" v-if="editedItem.isOwn">CRÉER</span>
                <span class="badge text-dark bg-warning text-right mb-2 fs-6 m-1" v-else>REÇU</span>
                <span class="badge bg-info text-right mb-2 m-1 fs-6" v-if="editedItem.is_new">NOUVEAU</span>
                <span class="badge purple text-right mb-2 m-1 fs-6" v-if="editedItem.isPrivate">PRIVÉ</span>
                <span class="badge teal darken-1 text-right mb-2 m-1 fs-6" v-else>PUBLIC</span>
              </v-list-item-subtitle>

              <v-list-item-title class="text-center fs-1"><strong>{{ editedItem.titre }}</strong>
              </v-list-item-title>
              <v-list-item-subtitle class="text-center fs-5">
                {{ editedItem.description }}</v-list-item-subtitle>

              <div class="mt-3 text-center">
                <div>
                  <v-chip color="#2596be" class="text-white m-1" v-for="mot_clef in editedItem.mot_clefs"
                    v-bind:key="mot_clef">
                    {{ mot_clef }}
                  </v-chip>
                </div>
                <div>
                  <v-chip class="text-dark bg-warning m-1" v-for="categorie in editedItem.categoriesItem"
                    v-bind:key="categorie.id">
                    {{ categorie.nom }}
                  </v-chip>
                </div>
              </div>
              <div class="text-center p-1">
                <p class="pt-2">
                  <span class="text-muted small">Date de création</span> <br />
                  <strong>{{ editedItem.created_at }}</strong>
                </p>
                <p>
                  <span class="text-muted small">Date de dernière modification</span>
                  <br />
                  <strong>{{ editedItem.updated_at }}</strong>
                </p>
              </div>
              <div class="text-center mb-5">
                <v-chip class="bg-danger text-white" v-if="editedItem.hasPassword">
                  <v-icon class="text-white">mdi-key</v-icon> Mot de passe
                  requis
                </v-chip>
              </div>
            </v-list-item-content>
          </v-list-item>
        </v-list>

        <div>
          <v-container>
            <hr />
            <v-text-field filled v-model="search" class="mt-3" clearable rounded prepend-inner-icon="mdi-magnify"
              label="Rechercher un tuto"></v-text-field>
            <v-row dense class="mt-5">
              <h2 class="fs-5">
                <i>Liste des tutos contenu dans le dossier</i>
              </h2>
              <search-filter :categories="categories" @reloadItems="filterTutos" filterFavsFunction="filterByFavsTuto"
                class="mb-2" filterCategorieFunction="filterCategoriesTutoFolder" @newItems="tutosFilter = $event"
                @resetItem="tutosFilter = tutos" :id="editedItem.id" table="folder" type="tutos"></search-filter>

              <v-row v-if="loadTuto">
                <v-col :cols="colsResponsive">
                  <v-skeleton-loader class="mx-auto my-3 rounded-xl"
                    type="card, card, card, card, card, card, card, card">
                  </v-skeleton-loader>
                </v-col>

                <v-col :cols="colsResponsive">
                  <v-skeleton-loader class="mx-auto my-3 rounded-xl"
                    type="card, card, card, card, card, card, card, card">
                  </v-skeleton-loader>
                </v-col>
                <v-col :cols="colsResponsive">
                  <v-skeleton-loader class="mx-auto my-3 rounded-xl"
                    type="card, card, card, card, card, card, card, card">
                  </v-skeleton-loader>
                </v-col>
                <v-col :cols="colsResponsive">
                  <v-skeleton-loader class="mx-auto my-3 rounded-xl"
                    type="card, card, card, card, card, card, card, card">
                  </v-skeleton-loader>
                </v-col>
              </v-row>

              <liste-tuto v-else :tutos="tutos" :categories="categories" :displayMessageNoTuto="true"
                :tutosFilter="tutosFilter"></liste-tuto>
            </v-row>
          </v-container>
        </div>
      </div>
    </v-card>
    <v-bottom-sheet v-model="openActions">
      <v-list>
        <v-list-item class="d-flex justify-content-center">
          <v-btn class="bg-color-main" rounded v-if="editedItem.isOwn || editedItem.hasRight"
            @click="editFolder(editedItem)">
            <v-icon>mdi-pencil</v-icon> Modifier le dossier
          </v-btn>
        </v-list-item>
        <v-list-item class="d-flex justify-content-center" v-if="editedItem.isOwn">
          <v-btn dark rounded class="bg-primary" @click="shareFolder()">
            <v-icon>mdi-send</v-icon> Envoyer le dossier
          </v-btn>
        </v-list-item>
        <v-list-item class="d-flex justify-content-center" v-if="editedItem.isOwn">
          <v-btn dark rounded class="bg-light text-dark" @click="dialogFolderAcces = true" v-if="editedItem.isOwn">
            <v-icon>mdi-account-wrench</v-icon> Accès et droits
          </v-btn>
        </v-list-item>
        <v-list-item class="d-flex justify-content-center">
          <v-btn class="bg-danger" @click="dialogDeleteFolder()" dark v-if="editedItem.isOwn" rounded>
            <v-icon>mdi-delete</v-icon> Supprimer le dossier
          </v-btn>
        </v-list-item>
      </v-list>
    </v-bottom-sheet>
    <v-dialog v-model="dialogPassword" persistent max-width="290">
      <v-card class="rounded-xl">
        <v-card-title class="text-h5"> Mot de passe requis </v-card-title>
        <v-card-text class="mt-3">
          <v-text-field prepend-inner-icon="mdi-account" rounded filled name="Mot de passe" v-model="passwordRequire"
            label="Mot de passe" type="password" v-on:keydown.enter.prevent="getContentFolder()" :rules="requiredRules">
          </v-text-field>
        </v-card-text>
        <v-card-actions>
          <v-btn class="text-color-main bg-white" rounded @click="$router.go(-1)">
            <v-icon>mdi-arrow-left</v-icon> Retour
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn class="bg-color-main text-white" rounded @click="getContentFolder()">
            <v-icon>mdi-send</v-icon> Valider
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialogFolderAcces" width="800" scrollable>
      <v-card class="rounded-xl" width="800">
        <v-toolbar class="bg-color-main" dark>
          <v-btn @click="dialogFolderAcces = false" icon>
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-title class="mb-5 text-break"> Accès au dossier </v-card-title>
        <v-card-actions class="d-flex justify-content-center">
          <div>
            <v-btn class="bg-color-main m-1" rounded @click="showPasswordSection = !showPasswordSection" large>
              Définir/Redéfinir un <br />
              mot de passe pour le dossier</v-btn>
            <v-btn class="bg-danger m-1" @click="dialogPasswordFolder()" rounded dark
              v-if="editedItem && editedItem.hasPassword">
              <v-icon>mdi-delete</v-icon>Supprimer le mot de passe
            </v-btn>
          </div>
        </v-card-actions>
        <v-card-text>
          <div v-if="showPasswordSection">
            <h3 class="text-break text-h5 text-dark">Mot de passe</h3>
            <label class="m-1 text-primary">Vous pouvez définir un mot de passe pour restreindre l'accès au
              dossier</label>
            <v-form v-model="valid">
              <v-text-field filled @click:append="show1 = !show1" :type="show1 ? 'text' : 'password'"
                :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'" v-model="editedItem.password" :rules="requiredRules"
                rounded label="Choisissez un mot de passe">
              </v-text-field>
              <v-text-field filled @click:append="show1 = !show1" :rules="[passwordMatch]" v-model="verify"
                :type="show1 ? 'text' : 'password'" :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'" rounded
                label="Confimer le mot de passe"></v-text-field>
              <div class="d-flex justify-content-end">
                <v-btn class="bg-color-main" @click="savePasswordFolder()" :disabled="!valid" rounded dark>
                  <v-icon>mdi-content-save</v-icon>Enregistrer
                </v-btn>
              </div>
            </v-form>
          </div>
        </v-card-text>
        <v-card-text>
          <v-list v-if="editedItem.id">
            <v-list-group v-for="user in editedItem.users" :key="user.id">
              <template v-slot:activator>
                <v-list-item-avatar>
                  <v-img :src="user.photo"></v-img>
                </v-list-item-avatar>
                <v-list-item-content>
                  <v-list-item-title v-text="user.pseudo"></v-list-item-title>
                </v-list-item-content>
              </template>

              <v-list-item>
                <v-list-item-content>
                  <v-select solo rounded :items="rights" item-text="text" item-value="value" v-model="user.hasRight"
                    @change="saveRightsFolderUser()"></v-select>
                </v-list-item-content>

                <v-list-item-action>
                  <v-btn rounded class="mb-5" @click="dialogRemoveUser(user.id)">
                    <v-icon class="text-danger">mdi-delete</v-icon>
                  </v-btn>
                </v-list-item-action>
              </v-list-item>
            </v-list-group>
          </v-list>
        </v-card-text>
      </v-card>
    </v-dialog>
    <dialog-confirm :titre="arrayDialog.titre" :message="arrayDialog.message"
      :functionCalled="arrayDialog.functionCalled" type="contentFolder"></dialog-confirm>
  </div>
</template>
  
<script>
import router from "/src/router";
import infiniteScroll from "vue-infinite-scroll";
import moment from "moment";
import SearchFilter from '@/components/modules/SearchFilter.vue';
import ListeTuto from '@/components/tutos/ListeTuto.vue';
import DialogConfirm from '@/components/modal/DialogConfirm.vue';
moment.locale("fr");
export default {
  props: ["code_dossier", "slug"],
  metaInfo() {
    return {
      title: this.editedItem.titre + " | Pocketuto ",
      meta: [
        { vmid: 'description', name: 'description', content: this.editedItem.description }
      ]
    }
  },
  directives: {
    infiniteScroll,
  },
  components: {
    ListeTuto,
    SearchFilter,
    DialogConfirm
  },

  data() {
    return {
      folders: [],
      valid: true,
      rights: [
        { value: 1, text: "Admin" },
        { value: 0, text: "Lecture" },
      ],
      dialogFolderAcces: false,
      searchFolder: null,
      show1: false,
      menu: false,
      idUser: "",
      btnLoadFolders: false,
      selectedItem: [],
      editedFolder: {
        id: "",
        titre: "",
        arrayTutos: [],
      },
      validPassword: false,
      verify: "",
      passwordRules: [
        (value) => !!value || "Taper un mot de passe s'il vous plait",
        (value) => !!value || "Le champ mot de passe est obligatoire",
        (value) =>
          (value && /\d/.test(value)) ||
          "Le mot de passe doit contenir au moins un nombre",
        (value) =>
          (value && /[A-Z]{1}/.test(value)) ||
          "Le mot de passe doit contenir au moins une majuscule",
        (value) =>
          (value && /[^A-Za-z0-9]/.test(value)) ||
          "Le mot de passe doit contenir au moins un caractere spécial",
        (value) =>
          (value && value.length > 6) ||
          "Le mot de passe doit faire au moins 6 caractères minimum",
      ],
      tutos: [],
      requiredRules: [(v) => !!v || "Le champ est obligatoire"],
      dialogPassword: false,
      passwordRequire: "",
      idFolder: "",
      loadTuto: true,
      titleFolder: null,
      active: false,
      busy: false,
      loader: true,
      idTuto: null,
      dialogFolder: true,
      editedItem: {
        titre: "",
        description: "",
        mot_clefs: "",
        categories: [],
        isOwn: 0,
        createur: [],
      },
      dialogTuto: false,
      tutosFilter: [],
      categories: [],
      favoris: false,
      categorieFilter: [],
      user: [],
      search: "",
      showPasswordSection: false,
      openActions: false,
      isSearch: false,
      displayTypeTutos: 1,
      displayTutosVisibility: 3,
      arrayDialog: {
        titre: "",
        message: "",
        functionCalled: "",
      }
    };
  },
  activated() {
    this.dialogFolder = true;
    this.getContentFolder();
    this.$store.dispatch("categories/setCategories");
    if (this.$store.getters["categories/getCategories"].length == 0) {
      var refreshIntervalId3 = setInterval(() => {
        if (this.$store.getters["categories/dataLoad"]) {
          this.categories = this.$store.getters["categories/getCategories"];
          clearInterval(refreshIntervalId3);
        }
      }, 1000);
    } else {
      this.categories = this.$store.getters["categories/getCategories"];
    }
  },
  watch: {
    search(val) {
      if (val) {
        if (val.length > 2) {
          this.$axios
            .get(
              "/folder/searchTutoFolder/" + val + "/" + this.editedItem.id
            )
            .then((response) => {
              this.tutosFilter = response.data.tutos;
              this.isSearch = true;
            });
        }
        if (val.length == 0) {
          this.tutosFilter = this.tutos;
          this.isSearch = false;
        }
      } else {
        this.tutosFilter = this.tutos;
        this.isSearch = false;
      }
    },
  },
  computed: {
    passwordMatch() {
      return () =>
        this.editedItem.password === this.verify ||
        "Les mot de passes ne sont pas les mêmes";
    },
    responsiveBtn() {
      if (window.innerWidth < 800) {
        return true;
      } else {
        return false;
      }
    },
    colsResponsive() {
      if (window.innerWidth < 800) {
        return 12;
      } else {
        return 3;
      }
    },
    heightResponsive() {
      if (window.innerWidth < 800) {
        return "";
      } else {
        return 300;
      }
    },
  },

  methods: {
    openContentTuto(tuto) {
      this.$router.push({
        name: "Contenu du tuto",
        params: {
          code_tuto: tuto.code_tuto,
          slug: this.slugify(tuto.titre, "-"),
        },
      });
      this.idTuto = tuto.id;
    },
    closeContentFolder() {
      this.dialogPassword = false;
      this.active = [];
    },
    editFolder(folder) {
      this.$router.push({
        name: "Editer le dossier",
        params: { idFolder: folder.id, nomFolder: folder.titre },
      });
    },
    filterTutos(payload = []) {
      var arrayPayload = []
      if (payload.length == 0) {
        arrayPayload = {
          displayTypeTutos: this.displayTypeTutos,
          displayTutosVisibility: this.displayTutosVisibility
        }
      } else {
        arrayPayload = {
          displayTypeTutos: payload['displayType'],
          displayTutosVisibility: payload['displayVisibility'],
        }
      }
      this.$axios.post('/folder/listsTuto/' + this.editedItem.id, arrayPayload).then((response) => {
        this.tutosFilter = response.data
      })
    },
    getContentFolder() {
      this.loadTuto = true;
      this.$axios
        .post("/folder/getContentFolder/" + this.code_dossier, {
          passwordRequire: this.passwordRequire,
        })
        .then((response) => {
          if (response.data.messageError) {
            this.$swal(
              "Oups, une erreur est survenu !",
              response.data.messageError,
              "error"
            );
            this.loader = false;
            this.passwordRequire = "";
            return;
          }
          if (response.data.needPassword) {
            this.dialogPassword = true;
            return;
          }
          this.dialogPassword = false;
          this.editedItem = response.data.folder;
          this.tutos = response.data.tutos;
          this.tutosFilter = this.tutos;
          this.loadTuto = false;
        })
        .catch(function (error) {
          if (error.response.status === 401) {
            setTimeout(() => {
              router.push({ name: "Je m'authentifie" });
            }, 1200);
          }
        });
    },
    saveRightsFolderUser() {
      this.$axios
        .post("/folder/changeRightsFolderUser", {
          users: this.editedItem.users,
        })
        .then((response) => {
          this.$swal({
            toast: true,
            title: response.data.message,
            icon: "success",
            animation: true,
            position: "top-right",
            showConfirmButton: false,
            timerProgressBar: true,
            timer: 2000,
          });
        });
    },
    dialogRemoveUser(idUser) {
      this.arrayDialog['message'] = "Voulez-vous retirer cet utilisateur du dossier ?"
      this.arrayDialog['titre'] = "Que voulez-vous faire ?"
      this.arrayDialog['functionCalled'] = "removeUserFromMyFolder"
      this.$store.dispatch('modal/setOpen', 'contentFolder')
      this.idUser = idUser
    },
    removeUserFromMyFolder() {
      this.$axios
        .get(
          "/folder/removeUserFromMyFolder/" +
          this.idUser +
          "/" +
          this.editedItem.id
        )
        .then((response) => {
          if (response.data.messageError) {
            this.$swal(
              "Oups, une erreur est survenu !",
              response.data.messageError,
              "error"
            );
            return;
          }
          this.$swal({
            toast: true,
            title: response.data.message,
            icon: "success",
            animation: true,
            position: "top-right",
            showConfirmButton: false,
            timerProgressBar: true,
            timer: 2000,
          });

          this.editedItem.users = response.data.users;
        });

    },
    dialogDeleteFolder() {
      if (this.editedItem.isOwn) {
        this.arrayDialog['message'] = "Voulez-vous vraiment supprimer ce dossier ? Toutes les personnes qui bénéficient de ce dossier le perdront !"
        this.arrayDialog['titre'] = "Que voulez-vous faire ?"
        this.arrayDialog['functionCalled'] = "confirmDeleteFolder"
      } else {
        this.arrayDialog['message'] = "Voulez-vous vraiment supprimer ce dossier de votre compte ?"
        this.arrayDialog['titre'] = "Que voulez-vous faire ?"
        this.arrayDialog['functionCalled'] = "confirmDeleteFolder"
      }
      this.$store.dispatch('modal/setOpen', 'contentFolder')
    },
    confirmDeleteFolder() {
      if (this.editedItem.isOwn) {
        this.$axios
          .delete("folder/deleteFolderCreate/" + this.editedItem.id)
          .then((response) => {
            if (response.data.messageError) {
              this.$swal(
                "Oups, un problème est survenu !",
                response.data.messageError,
                "error"
              );
              return;
            }
            this.$swal({
              toast: true,
              title: response.data.message,
              icon: "success",
              animation: true,
              position: "top-right",
              showConfirmButton: false,
              timerProgressBar: true,
              timer: 2000,
            });
            this.$router.push({ name: "Dossiers" })
            this.$store.dispatch('modal/setClose', 'contentFolder')
          });

      } else {

        this.$axios
          .delete("folder/deleteFolderReceive/" + this.editedItem.id)
          .then((response) => {
            if (response.data.messageError) {
              this.$swal(
                "Oups, un problème est survenu !",
                response.data.messageError,
                "error"
              );
              this.dialogConfirmDeleteFolder = false;
              return;
            }
            this.$swal({
              toast: true,
              title: response.data.message,
              icon: "success",
              animation: true,
              position: "top-right",
              showConfirmButton: false,
              timerProgressBar: true,
              timer: 2000,
            });
            this.$store.dispatch('modal/setClose', 'contentFolder')
            this.getFolders();
            this.dialogConfirmDeleteFolder = false;
          });
      }
    },
    savePasswordFolder() {
      this.$axios
        .post("folder/savePassword/" + this.editedItem.id, {
          password: this.editedItem.password,
        })
        .then((response) => {
          this.$swal({
            toast: true,
            title: response.data.message,
            icon: "success",
            animation: true,
            position: "top-right",
            showConfirmButton: false,
            timerProgressBar: true,
            timer: 2000,
          });
          this.dialogFolderAcces = false;
          this.passwordRequire = "";
          this.getContentFolder();
        });
    },
    dialogPasswordFolder() {
      this.arrayDialog['message'] = "Voulez-vous supprimer le mot de passe du dossier ?"
      this.arrayDialog['titre'] = "Que voulez-vous faire ?"
      this.arrayDialog['functionCalled'] = "deletePasswordFolder"
      this.$store.dispatch('modal/setOpen', 'contentFolder')
    },
    deletePasswordFolder() {
      this.$axios
        .get("folder/deletePasswordFolder/" + this.editedItem.id, {
          password: this.editedItem.password,
        })
        .then((response) => {
          this.$swal({
            toast: true,
            title: response.data.message,
            icon: "success",
            animation: true,
            position: "top-right",
            showConfirmButton: false,
            timerProgressBar: true,
            timer: 2000,
          });
          this.$store.dispatch('modal/setClose', 'contentFolder')
          this.dialogFolderAcces = false;
          this.passwordRequire = "";
          this.getContentFolder();
        });

    },
    returnBack() {
      this.dialogFolder = false;
      this.$emit("closeModalFolderCreate");
    },
    shareFolder() {
      this.$router.push({
        name: "Envoyer",
        params: {
          idElement: this.editedItem.id,
          nomElement: "dossier",
          element: this.editedItem,
        },
      });
    },
  },
};
</script>
  
<style scoped>
.card-hover {
  cursor: pointer;
}

.bd-placeholder-img {
  font-size: 1.125rem;
  text-anchor: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  height: 500px;
}

.cardVuetify {
  box-shadow: 0 6px 10px rgba(0, 0, 0, 0.08), 0 0 6px rgba(0, 0, 0, 0.05);
  transition: 0.3s transform cubic-bezier(0.155, 1.105, 0.295, 1.12),
    0.3s box-shadow,
    0.3s -webkit-transform cubic-bezier(0.155, 1.105, 0.295, 1.12);
  cursor: pointer;
}

.cardVuetify:hover {
  z-index: 3;
  transform: scale(1.02);
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.12), 0 4px 8px rgba(0, 0, 0, 0.06);
}

.sticky {
  position: -webkit-sticky;
  /* Safari */
  position: sticky;
  top: 0;
  z-index: 2;
}

@media (max-width: 900px) {
  .bd-placeholder-img {
    font-size: 3.5rem;
    height: 150px;
  }
}
</style>