<template>
  <div>
    <div class="table-responsive">
      <div class="container mt-5 rounded-xl">
        <h2 class="text-center text-dark p-3 rounded-xl bg-color-main">
          Liste des utilisateurs
        </h2>

        <div class="container">
          <v-text-field
            rounded
            filled
            clearable
            v-model="search"
            prepend-inner-icon="mdi-magnify"
            label="Rechercher un utilisateur"
          ></v-text-field>
        </div>

        <div>
          <v-data-table
            :headers="headers"
            :items="usersFilter"
            dense
            :mobile-breakpoint="0"
            @click:row="editItem($event)"
            hide-default-footer
            item-key="name"
          >
            <template v-slot:item.lastLoginDateTime="{ item }">
              <span>{{ formatDate(item.lastLoginDateTime) }}</span>
            </template>
            <template v-slot:item.verified_at="{ item }">
              <span>{{ formatDate(item.verified_at) }}</span>
            </template>
            <template v-slot:item.dateCreation="{ item }">
              <span>{{ formatDate(item.dateCreation) }}</span>
            </template>
            <template v-slot:item.ban="{ item }">
              <span>{{ item.ban == 1 ? "Oui" : "Non" }}</span>
            </template>
          </v-data-table>
          <v-pagination
            class="mt-3"
            v-model="pagination.current"
            :length="pagination.total"
            @input="onPageChange()"
            rounded
          ></v-pagination>
        </div>
      </div>
    </div>

    <v-dialog
      transition="dialog-top-transition"
      max-width="600"
      v-model="dialogUser"
    >
      <template>
        <v-card class="text-dark rounded-xl">
          <v-toolbar class="lead" color="#2596be" dark>
            <v-btn @click="dialogUser = false" icon
              ><v-icon>mdi-close</v-icon></v-btn
            >
            <v-toolbar-title> Fiche de l'utilisateur</v-toolbar-title>
          </v-toolbar>
          <v-card-text>
            <div class="text-white mt-3">
              <v-form ref="form" v-model="valid" lazy-validation>
                <div class="d-flex justify-content-center p-3">
                  <v-btn
                    rounded
                    class="bg-danger text-white"
                    @click="openModalConfirmDelete()"
                    ><v-icon>mdi-delete</v-icon>Supprimer l'utilisateur</v-btn
                  >
                </div>
                <div class="d-flex justify-content-center p-3">
                  <v-btn
                    v-if="editedItem.compteSupprime == 'Oui'"
                    class="bg-primary border border-dark text-white"
                    @click="restoreUser()"
                    >Restaurer l'utilisateur</v-btn
                  >
                </div>
                <label class="text-dark">Email :</label>
                <v-text-field
                  v-model="editedItem.email"
                  :rules="requiredRules"
                  filled
                  rounded
                  label="Email"
                  required
                ></v-text-field>
                <label class="text-dark">Banni :</label>
                <v-select
                  v-model="editedItem.ban"
                  item-text="val"
                  item-value="id"
                  rounded
                  :items="items"
                  label="Banni"
                  filled
                ></v-select>
              </v-form>
            </div>
          </v-card-text>
          <v-card-actions class="d-flex justify-content-end">
            <v-btn rounded class="bg-white text-color-main" @click="back()"
              ><v-icon>mdi-arrow-left</v-icon>Retour</v-btn
            >
            <v-btn class="bg-color-main" @click="updateUser()" rounded
              ><v-icon>mdi-content-save</v-icon>Sauvegarder</v-btn
            >
          </v-card-actions>
        </v-card>
      </template>
    </v-dialog>
    <v-dialog
      transition="dialog-bottom-transition"
      v-model="dialogConfirmDelete"
      max-width="600"
    >
      <v-card class="rounded-xl">
        <v-toolbar class="lead" style="background-color: #2596be" dark
          >Que voulez-vous faire ?</v-toolbar
        >
        <v-card-text>
          <div class="text-dark text-center mt-3 text-break">
            <h3>
              <strong
                >Tu veux vraiment supprimer <br />
                cet(te) utilisateur ?</strong
              >
            </h3>
          </div>
        </v-card-text>
        <v-card-actions class="d-flex justify-content-center">
          <v-btn
            class="bg-color-main text-white"
            style="margin-right: 10px !important"
            @click="dialogConfirmDelete = false"
            rounded
            >NON</v-btn
          >
          <v-btn text class="text-color-main" @click="confirmDeleteUser()"
            >OUI</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import moment from "moment";
moment.locale("fr");
export default {
  data() {
    return {
      requiredRules: [(v) => !!v || "Le champ est obligatoire"],
      valid: true,
      users: [],
      usersFilter: [],
      items: [
        { id: 1, val: "Oui" },
        { id: 0, val: "Non" },
      ],
      headers: [
        { text: "#", value: "id" },
        { text: "Dernière connexion", value: "lastLoginDateTime" },
        { text: "Nb tutos créer", value: "nbTutos" },
        { text: "Pseudo", value: "pseudo" },
        { text: "Date de création", value: "dateCreation" },
        { text: "Date de vérification du compte", value: "verified_at" },
        { text: "Banni", value: "ban" },
        { text: "Compte supprimé", value: "compteSupprime" },
      ],
      pagination: {
        current: 1,
        total: 0,
      },
      search: null,
      editedItem: {
        id: "",
        pseudo: "",
        email: "",
        receiveTuto: "",
        receiveRequestFriends: "",
        nbTutos: "",
        ban: "",
      },
      defaultItem: {
        id: "",
        pseudo: "",
        nbTutos: "",
        email: "",
        receiveTuto: "",
        receiveRequestFriends: "",
        ban: "",
      },
      dialogConfirmDelete: false,
      dialogUser: false,
    };
  },
  props: ["loadData"],
  watch: {
    loadData() {
      this.getUsers();
    },
    search(val) {
      if (val) {
        if (val.length > 2) {
          this.$axios.get("/admin/users/search/" + val).then((response) => {
            this.usersFilter = response.data.users;
          });
        }
        if (val.length == 0) {
          this.usersFilter = this.users;
        }
      } else {
        this.usersFilter = this.users;
      }
    },
  },
   mounted() {
    this.getUsers();
  },
  methods: {
    onPageChange() {
      this.getUsers();
    },
    openModalConfirmDelete() {
      this.dialogConfirmDelete = true;
    },
    confirmDeleteUser() {
      this.$axios
        .get("/admin/users/delete/" + this.editedItem.id)
        .then((response) => {
          this.$swal({
            toast: true,
            title: response.data.message,
            icon: "success",
            animation: true,
            position: "top-right",
            showConfirmButton: false,
            timerProgressBar: true,
            timer: 2000,
          });

          this.dialogConfirmDelete = false;
        });
    },
    getUsers() {
      this.$axios
        .get("/admin/users?page=" + this.pagination.current)
        .then((response) => {
          this.users = response.data.users;
          this.usersFilter = this.users;
          this.pagination.current = response.data.current_page;
          this.pagination.total = response.data.total;
        });
    },
    updateUser() {
      this.$axios
        .put("/admin/users/update/" + this.editedItem.id, this.editedItem)
        .then((response) => {
          if (response.data.messageError) {
            this.$swal(
              "Oups une erreur est survenu !",
              response.data.messageError,
              "error"
            );
            return;
          }

          this.$swal({
            toast: true,
            title: response.data.message,
            icon: "success",
            animation: true,
            position: "top-right",
            showConfirmButton: false,
            timerProgressBar: true,
            timer: 2000,
          });
          this.dialogUser = false;
          this.getUsers();
        });
    },
    restoreUser() {
      this.$axios
        .get("/admin/users/restore/" + this.editedItem.id)
        .then((response) => {
          this.$swal({
            toast: true,
            title: response.data.message,
            icon: "success",
            animation: true,
            position: "top-right",
            showConfirmButton: false,
            timerProgressBar: true,
            timer: 2000,
          });
          this.dialogUser = false;
        });
    },
    editItem(item) {
      this.editedItem = item;
      this.dialogUser = true;
    },
    back() {
      this.editedItem = this.defaultItem;
      this.dialogUser = false;
    },
    formatDate(date) {
      if (date) {
        if(moment(date).format("LLL") == 'Invalid date') {
   
          return date
        } else {
          return moment(date).format("LLL");
        }

      } else {
        return "Pas de connexion récente";
      }
    },
  },
};
</script>

<style>
</style>