<template>
  <div>
    <div v-if="friends.length > 0" class="container">
      <v-card-title>Mes ami(e)s bloqués</v-card-title>

      <div class="container">
        <v-btn
          outlined
          v-if="friends.length < maxFriends"
          block
          class="mb-3"
          @click="loadMoreFriends()"
          color="primary"
          rounded
        >
          Charger plus
        </v-btn>
      </div>
      <v-list rounded v-for="friends in friendsFilter" :key="friends.id">
        <v-list-item-group color="primary">
          <v-menu
            absolute
            offset-y
            transition="slide-y-transition"
            style="max-width: 600px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-list-item v-bind="attrs" v-on="on">
                <v-list-item-content>
                  <v-list-item-title
                    class="fw-bold fs-4"
                    v-text="friends.pseudo"
                  ></v-list-item-title>
                  <v-list-item-subtitle
                    >Bloqué le
                    <strong>{{
                      formatDate(friends.dateSuppression)
                    }}</strong></v-list-item-subtitle
                  >
                </v-list-item-content>
              </v-list-item>
            </template>
            <v-list class="bg-warning text-dark" dense>
              <v-list-item class="bg-warning text-dark" @click="debloquerFriend(friends)">
                <v-icon class="text-dark">mdi-account-sync</v-icon> Débloquer cet(te) ami(e)
                  ?
              </v-list-item>
            </v-list>
          </v-menu>
        </v-list-item-group>
      </v-list>
      <div v-if="loader">
        <v-skeleton-loader
          type="list-item-avatar-three-line, list-item-avatar-three-line, list-item-avatar-three-line"
        ></v-skeleton-loader>
      </div>
    </div>
    <div v-else>
      <p class="text-center m-5">Vous n'avez bloqué aucun ami(e)s</p>
    </div>
  </div>
</template>

<script>
import moment from "moment";
moment.locale("fr");
export default {
  data() {
    return {
      valid: true,
      friends: [],
      friendsFilter: [],
      loader: true,
      limitTimeLine: 10,
      nbfriendsTotal: null,
      maxFriends: 0,
      dialogRestore: false,
      dialogDelete: false,
      friendSelect: [],
      items: [
        { id: 1, val: "Oui" },
        { id: 0, val: "Non" },
      ],
      roles: [],
    };
  },
  props: ["loadData"],
  watch: {
    loadData() {
      this.getFriends();
    },
  },
   mounted() {
    this.getFriends();
  },
  methods: {
    getFriends() {
      this.$axios
        .get("/friends/friendsBloque/" + this.limitTimeLine)
        .then((response) => {
          this.friends = response.data.friends;
          this.friendsFilter = this.friends;
          this.maxFriends = response.data.maxFriends;
          this.loader = false;
        });
    },
    loadMoreFriends() {
      if (this.maxFriends > this.friends.length && !this.loader) {
        this.loader = true;
        setTimeout(() => {
          this.limitTimeLine = this.limitTimeLine + 10;
          this.getFriends();
        }, 1000);
      }
    },
    closeModal() {
      this.dialogRestore = false;
      this.friendSelect = [];
    },
    debloquerFriend(friend) {
      this.friendSelect = friend;
      this.$axios
        .get("friends/debloque/" + this.friendSelect.friend_id)
        .then((response) => {
          if (response.data.messageError) {
            this.$swal(
              "Une erreur est survenu !",
              response.data.messageError,
              "error"
            );
            return;
          }
          this.$swal({
            toast: true,
            title: response.data.message,
            icon: "success",
            animation: true,
            position: "top-right",
            showConfirmButton: false,
            timerProgressBar: true,
            timer: 2000,
          });

          this.dialogRestore = false;
          this.getFriends();
        });
    },
  },
};
</script>

<style>
</style>