<template>
  <div style="padding-bottom:150px;">
    <div class="mt-10 rounded-xl">
      <div class="container mt-10">
        <h1 class="text-center  rounded-xl p-3 bg-color-main">
          Politiques de confidentialité
        </h1>
      </div>
      <div class="container">
        <v-expansion-panels class="rounded-xl">
          <v-expansion-panel>
            <v-expansion-panel-header>
              Cliquez ici pour les lires
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <div class="container">
                <h1 class=" text-center">Préambule</h1>
                <div>
                  <p class="lead text-justify">
                    La présente politique de confidentialité a pour objectif de
                    vous informer sur les engagements et mesures pratiques pris
                    par Pocketuto, au regard de vos données à caractère
                    personnel.
                    <strong>Pocketuto</strong> veille à ne collecter et ne
                    traiter que des données strictement nécessaires au regard de
                    la finalité pour laquelle elles le sont. La présente
                    politique de confidentialité pourra évoluer en fonction du
                    contexte légal et réglementaire et de la doctrine de la
                    Cnil.
                  </p>
                  <h1 class=" text-center">Formulaires web</h1>
                  <p class="lead text-justify">
                    Les informations recueillies sur ce formulaire sont
                    enregistrées dans un fichier informatisé dans une base de
                    données pour la constitution et gestion d’un fichier de
                    contacts. Elles sont conservées pendant 3 ans à compter de
                    la collecte des données ou de votre dernier contact. Elles
                    sont destinées à votre connexion sécurisée et à l’envoi , de
                    communication concernant strictement
                    <strong>Pocketuto</strong>. En aucun cas, vos données
                    personnelles ne sont vendues à des tiers. Dans le cadre de
                    nos communications, vous pouvez être amené à recevoir, par
                    email des sollicitations commerciales ou des informations
                    pratiques sur <strong>Pocketuto</strong>. À tout moment,
                    vous pouvez vous désinscrire de nos campagnes d’informations
                    en cliquant sur le lien de désinscription.
                  </p>
                  <h1 class=" text-center">Sécurité</h1>
                  <p class="text-justify lead">
                    Pocketuto assure la sécurité de vos données personnelles en
                    mettant en place une protection des données renforcée par
                    l’utilisation de moyens de sécurisation physiques et
                    logiques. Certaines de vos données sont chiffré avec une
                    méthode de chiffrement avancé.
                  </p>
                  <h1 class=" text-center">Sécurité</h1>
                  <p class="text-justify lead">
                    Conformément au Règlement Général sur la Protection des
                    Données du Parlement européen et du Conseil, du 27 avril
                    2016 (2016/679) aussi appelé RGPD mis en application à
                    partir du 25 mai 2018, vous disposez de droit d’accès
                    (article 15), droit de rectification (article 16), droit
                    d’opposition (article 21) , droit d’effacement (article 17),
                    droit à la portabilité des données (article 20) et du droit
                    à la limitation du traitement (article 18) . Pour exercer un
                    ou plusieurs de ces droits, merci de nous contacter par
                    email :
                    <strong>pocketuto@gmail.com</strong>
                  </p>
                </div>
              </div>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </div>
    </div>
    <div class="container mt-10">
      <h1 class="text-center  p-3 rounded-xl bg-color-main">
        Mentions légales
      </h1>
    </div>
    <div class="container rounded-xl">
      <v-expansion-panels>
        <v-expansion-panel class="rounded-xl">
          <v-expansion-panel-header>
            Cliquez ici pour les lires
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <div class="container">
              <div>
                <p class="lead text-justify">
                  Dénomination sociale : <strong>Pocketuto</strong> <br />
                  Le site est hébergé par OVH. <br />
                  Responsable de la publication de l'application :
                  <strong>Codnprog Software</strong>
                </p>
                <h1 class=" text-center">
                  Droits et propriétés intellectuelles accordées aux membres du
                  site
                </h1>
                <p class="lead text-justify">
                  <strong>Pocketuto</strong> est le propriétaire exclusif de
                  tous les droits de propriété intellectuelle sur le contenu de
                  l'application Web en France, en Europe et à l’international.
                  Par conséquent, il vous est interdit de copier et / ou
                  d'utiliser le nom de la marque, le logo ou autres
                  iconographiques présentes sur l'application. La modification,
                  copie, traduction, vente, publication, imitation, utilisation,
                  impression à but commercial et distribution numérique ou dans
                  tout autre format de tout ou partie des informations, textes,
                  photos, images, vidéos et données du site susnommé sont
                  interdites sauf avec l'autorisation préalable dérogatoire. La
                  violation de ces dispositions obligatoires soumettra le
                  contrevenant et toutes les personnes responsables aux
                  sanctions pénales et civiles prévues par la loi. Si vous
                  souhaitez exploiter/utiliser tout ou partie du contenu du site
                  dans un cadre professionnel ou commercial, contactez-nous à
                  <strong>Pocketuto@gmail.com</strong>
                </p>
                <h1 class=" text-center">Sécurité</h1>
                <p class="text-justify lead">
                  PockeTuto assure la sécurité de vos données personnelles en
                  mettant en place une protection des données renforcée par
                  l’utilisation de moyens de sécurisation physiques et logiques.
                  Certaines de vos données sont chiffré avec une méthode de
                  chiffrement avancé.
                </p>
                <h1 class=" text-center">Garanties</h1>
                <p class="text-justify lead mb-5 pb-5">
                  Chaque membre du site s’engage concernant la création de tuto,
                  à ne pas : <br /><br />
                  - porter atteinte aux règles relatives à la liberté
                  personnelle (diffamation, injure, etc.) Les commentaires ne
                  doivent pas être : <br /><br />
                  - être de nature discriminatoire ou de nature à inciter à la
                  violence ou à la haine, pour des raisons d’origine ethniques,
                  politiques, d’orientation sexuelle, etc.
                  <br /><br />
                  - comporter des contenus obscènes, pornographiques,
                  dégradants, etc. <br /><br />
                  - comporter des virus, cheval de Troie, ou tout autre
                  programme informatique destiné à endommager ou à intercepter
                  tout système informatique ; <br /><br />
                  - être susceptibles d’engager la responsabilité de Pocketuto
                  en proposant des liens hypertextes renvoyant vers des contenus
                  illicites plus largement, comporter des contenus susceptibles
                  d’enfreindre les lois ou les règlements.
                </p>
              </div>
            </div>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </div>
   <!--  <div class="container mt-10">
      <h1 class="text-center  p-3 rounded-xl bg-color-main">
        Conditions générales de vente
      </h1>
    </div>
    <div class="container">
      <v-expansion-panels class="rounded-xl">
        <v-expansion-panel>
          <v-expansion-panel-header>
            Cliquez ici pour les lires
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <div class="container">
              <div>
                <h1 class=" text-center">
                  Clause n° 1 : Objet et champ d'application
                </h1>
                <p class="lead text-justify">
                  Les présentes conditions générales de vente (CGV) constituent
                  le socle de la négociation commerciale et sont
                  systématiquement adressées ou remises à chaque acheteur pour
                  lui permettre de passer commande. Les conditions générales de
                  vente décrites ci-après détaillent les droits et obligations
                  de la société
                  <strong>Codnprog Software</strong> et de son client dans le
                  cadre de la vente des marchandises suivantes : <br /><strong>
                    <ul>
                      <li>Pack x25 tutos !</li>
                      <li>Pack x50 tutos !</li>
                      <li>Pack x100 tutos !</li>
                    </ul>
                  </strong>Toute acceptation du devis/bon de commande y compris la
                  clause « Je reconnais avoir pris connaissance et j'accepte les
                  conditions générales de vente ci-annexées » implique
                  l'adhésion sans réserve de l'acheteur aux présentes conditions
                  générales de vente. Le fait que le vendeur ne se prévale pas à
                  un moment donné de l'une quelconque des présentes conditions
                  générales de vente ne peut être interprété comme valant
                  renonciation à se prévaloir ultérieurement de l'une quelconque
                  desdites conditions.
                </p>
                <br /><br />
                <h1 class=" text-center">Clause n° 2 : Prix</h1>
                <p class="text-justify lead">
                  Les prix des marchandises vendues sont ceux en vigueur au jour
                  de la prise de commande. Ils sont libellés en euros et
                  calculés TTC. La société <strong>Codnprog Software</strong>
                  s'accorde le droit de modifier ses tarifs à tout moment.
                  Toutefois, elle s'engage à facturer les marchandises
                  commandées aux prix indiqués lors de l'enregistrement de la
                  commande.
                </p>
                <br /><br />
                <h1 class=" text-center">
                  Clause n° 3 : Rabais et ristournes
                </h1>
                <p class="text-justify lead mb-5 pb-5">
                  Les tarifs proposés comprennent les rabais et ristournes que
                  la société <strong>Codnprog Software</strong> serait amenée à
                  octroyer compte tenu de ses résultats ou de la prise en charge
                  par l'acheteur de certaines prestations.
                </p>
                <h1 class=" text-center">Clause n° 4 : Escompte</h1>
                <p class="text-justify lead mb-5 pb-5">
                  Aucun escompte ne sera consenti en cas de paiement anticipé.
                </p>
                <h1 class=" text-center">
                  Clause n° 5 : Modalités de paiement
                </h1>
                <p class="text-justify lead mb-5 pb-5">
                  Le règlement des commandes s'effectue avec
                  <strong><a href="https://stripe.com" target="blank_">Stripe</a></strong>
                  par carte bancaire <br />
                </p>
              </div>
            </div>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </div> -->
  </div>
</template>

<script>
export default {
  metaInfo() {
    return {
      title: "Politique de confidentialité & Mentions légales | Pocketuto ",
      meta: [
        { vmid: 'description', name: 'description', content: "Les mentions légales, la politique de confidentialité et les condition général de ventes en une page" }
      ]
    }
  },
  methods: {
    returnBack() {
      this.$router.go(-1);
    },
  },
};
</script>

<style>

</style>