<template>
  <div class="mt-5">
    <div v-infinite-scroll="loadTimeLine" infinite-scroll-disabled="busy" :infinite-scroll-limit="tutosMax"
      infinite-scroll-distance="5">
      <div class="container">
        <div class="mb-3 d-flex justify-content-end">
          <v-btn :to="{ name: 'Créer un tuto' }" rounded large class="bg-color-main text-white">
            <v-icon>mdi-plus</v-icon> Créer
          </v-btn>
        </div>

        <search-filter :categories="categories" v-if="!loaderPage" class="mb-3" @reloadItems="getTutos"
          filterFavsFunction="filterByFavs" @newItems="tutosFilter = $event" filterCategorieFunction="filterCategories"
          @resetItem="tutosFilter = tutos" table="tutos" type="tutos"></search-filter>

        <v-text-field filled v-model="search" class="mt-3" clearable rounded prepend-inner-icon="mdi-magnify"
          label="Rechercher un tuto"></v-text-field>
        <liste-tuto v-if="!loaderPage" :loaderPage="loaderPage" :displayMessageNoTuto="true"
          :tutosFilter="tutosFilter" :tutos="tutos" :categories="categories"></liste-tuto>

        <div v-show="loaderOtherTutos" class="mt-5 rounded-xl">
          <div class="container d-flex justify-content-center mt-5">
            <div class="text-center">
              <v-progress-circular :size="70" :width="7" color="#2596be" indeterminate></v-progress-circular>
              <p class="text-center text-color-main fw-bold fs-4">
                Chargement de vos tutos
              </p>
            </div>
          </div>
        </div>
        <div v-show="loaderPage" class="mt-5 rounded-xl mb-5">
          <div class="container d-flex justify-content-center mt-5">
            <div class="text-center">
              <v-progress-circular :size="70" :width="7" color="#2596be" indeterminate></v-progress-circular>
              <p class="text-center text-color-main fw-bold fs-4">
                Chargement de vos tutos
              </p>
            </div>
          </div>
          <v-row dense>
            <v-col v-for="i in colsResponsive + 1" :key="i" :cols="colsResponsive">
              <v-skeleton-loader class="mx-auto my-3 rounded-xl" type="card, card, card, card, card, card, card, card">
              </v-skeleton-loader>
            </v-col>
          </v-row>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ListeTuto from '@/components/tutos/ListeTuto.vue';
import SearchFilter from '@/components/modules/SearchFilter.vue';
export default {
  metaInfo() {
    return {
      title: "Mes tutos | Pocketuto ",
      meta: [
        { vmid: 'description', name: 'description', content: "Liste de mes tutos" }
      ]
    }
  },
  components: {
    ListeTuto,
    SearchFilter
  },
  data() {
    return {
      tab: null,
      role: [],
      idFolder: null,
      idTuto: null,
      tutos: [],
      tutosFilter: [],
      busy: false,
      categories: [],
      tutosMax: 0,
      categorieFilter: [],
      loader: true,
      loaderOtherTutos: false,
      user: [],
      loaderPage: true,
      search: "",
      isSearch: false,
      limitTimeLine: 20,
      displayTypeTutos: 1,
      displayTutosVisibility: 3,
      favoris: false,
    };
  },
  props: ["typeTuto", "tutoId"],
  mounted() {
    this.getTutos();
    var refreshIntervalId = setInterval(() => {
      if (this.$store.getters["auth/dataLoad"]) {
        this.role = this.$store.getters["auth/user"]["role"];
        clearInterval(refreshIntervalId);
      }
    }, 500);
    var refreshIntervalId2 = setInterval(() => {
      if (this.$store.getters["tutos/dataLoad"]) {
        this.tutos = this.$store.getters["tutos/getTutos"];
        this.tutosFilter = this.tutos;
        this.loaderPage = false;
        clearInterval(refreshIntervalId2);
      }
    }, 1000);
    var refreshIntervalId3 = setInterval(() => {
      if (this.$store.getters["auth/dataLoad"]) {
        if (this.$store.getters["auth/dataLoad"]) {
          this.user = this.$store.getters["auth/user"];
        }
        clearInterval(refreshIntervalId3);
      }
    }, 500);
    this.$store.dispatch("categories/setCategories");
    if (this.$store.getters["categories/getCategories"].length == 0) {
      var refreshIntervalId4 = setInterval(() => {
        if (this.$store.getters["categories/dataLoad"]) {
          this.categories = this.$store.getters["categories/getCategories"];
          clearInterval(refreshIntervalId4);
        }
      }, 1000);
    } else {
      this.categories = this.$store.getters["categories/getCategories"];
    }
  },
  activated() {
    if (this.loaderPage == false) {
      this.loaderOtherTutos = true
      this.getTutos();
    }
  },
  watch: {
    favoris(val) {
      var params = null;
      if (val) {
        params = 1;
        this.$axios.get("/tutos/filterByFavs/" + params).then((response) => {
          this.tutosFilter = response.data;
          localStorage.setItem("filterByFavs", params);
        });
      } else {
        params = 0;
        this.$axios.get("/tutos/filterByFavs/" + params).then((response) => {
          this.tutosFilter = response.data;
          localStorage.setItem("filterByFavs", params);
        });
      }
    },
    categorieFilter(val) {
      if (val) {
        if (!this.selectAllCategorie && val.length > 0) {
          this.$axios
            .post("/tutos/filterCategories/" + this.displayTypeTutos, {
              categories: val,
            })
            .then((response) => {
              this.tutosFilter = response.data.tutos;
            });
        } else {
          this.tutosFilter = this.tutos;
        }
      } else {
        this.tutosFilter = this.tutos;
      }
    },
    search(val) {
      if (val) {
        if (val.length > 2) {
          this.$axios
            .get("/tutos/searchTuto/" + val + "/" + this.displayTypeTutos)
            .then((response) => {
              this.tutosFilter = response.data.tutos;
              this.isSearch = true;
            });
        }
        if (val.length == 0) {
          this.tutosFilter = this.tutos;
          this.isSearch = false;
        }
      } else {
        this.tutosFilter = this.tutos;
        this.isSearch = false;
      }
    },
    displayTypeTutos() {
      this.loaderPage = true;
      this.getTutos();
    },
    displayTutosVisibility() {
      this.loaderPage = true
      this.getTutos();
    }
  },
  computed: {
    chipResponsive() {
      if (window.innerWidth < 800) {
        return 3;
      } else {
        return 8;
      }
    },
    colsResponsive() {
      if (window.innerWidth < 800) {
        return 12;
      } else {
        return 3;
      }
    },
    selectAllCategorie() {
      return this.categorieFilter.length === this.categories.length;
    },
    selectedSomeCategorie() {
      return this.categorieFilter.length > 0 && !this.selectAllCategorie;
    },
    icon() {
      if (this.selectAllCategorie) return "mdi-close-box";
      if (this.selectedSomeCategorie) return "mdi-minus-box";
      return "mdi-checkbox-blank-outline";
    },
  },
  methods: {
    toggle() {
      this.$nextTick(() => {
        if (this.selectAllCategorie) {
          this.categorieFilter = [];
        } else {
          this.categorieFilter = this.categories;
          this.tutosFilter = this.tutos;
        }
      });
    },
    getTutos(payload = []) {
      var arrayPayload = []
      if (payload.length == 0) {
        arrayPayload = {
          limitTimeLine: this.limitTimeLine,
          displayTypeTutos: this.displayTypeTutos,
          displayTutosVisibility: this.displayTutosVisibility
        }
      } else {
        arrayPayload = {
          limitTimeLine: this.limitTimeLine,
          displayTypeTutos: payload['displayType'],
          displayTutosVisibility: payload['displayVisibility'],
        }
      }
      this.$store.dispatch('tutos/setTutos', arrayPayload).then(() => {
        var refreshIntervalId = setInterval(() => {
          if (this.$store.getters["tutos/dataLoad"]) {
            this.tutos = this.$store.getters["tutos/getTutos"];
            this.tutosMax = this.$store.getters["tutos/getMaxTutos"];
            this.tutosFilter = this.tutos;
            this.loaderOtherTutos = false
            if (this.loaderPage != false) {
              this.loaderPage = false;
            } else {
              this.loaderOtherTutos = false;
            }

            clearInterval(refreshIntervalId);
          }
        }, 1000);
      });

    },
    loadTimeLine() {
      if (this.tutosMax > this.tutos.length && !this.loaderOtherTutos && !this.isSearch) {
        this.loaderOtherTutos = true;
        setTimeout(() => {
          this.limitTimeLine = this.limitTimeLine + 20;
          this.getTutos();
        }, 1000);
      }
    },
  }
};
</script>

<style scoped>
.sticky {
  position: -webkit-sticky;
  /* Safari */
  position: sticky;
  top: 45px;
  z-index: 4;
}
</style>