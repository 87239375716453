<template>
  <div data-app class="mt-10 pb-5">
    <div>
      <div class="container">
        <center>
          <v-avatar size="208"
            ><v-img
              class="rounded-circle mb-2"
              @click="triggerInputFile()"
              :src="inputFileSelect"
              :disabled="loader"
            ></v-img
          ></v-avatar>
          <v-file-input
            type="file"
            id="img-profil"
            :disabled="loader"
            ref="input2"
            style="display: none"
            accept=".png, .jpg, .jpeg"
            @change="changePreview($event)"
          ></v-file-input>
          <p class="text-muted small text-break p-2">
            Cliquer sur l'image pour changer <br />
            votre photo de profil et n'oubliez <br />
            pas d'enregistrer ensuite !
          </p>
        </center>

        <div class="d-flex justify-content-center">
          <v-btn
            class="bg-primary text-white ml-2 mt-3 mb-3"
            rounded
            :disabled="loader"
            @click="dialogChangePassword = true"
          >
            <v-icon>mdi-key-change</v-icon>Je change mon mot de passe
          </v-btn>
        </div>
        <v-form
          ref="form"
          class="text-white container"
          v-model="valid"
          lazy-validation
        >
          <v-text-field
            v-model="editedItem.pseudo"
            disabled
            filled
            label="Pseudo"
            :loading="loader"
            required
            rounded
          ></v-text-field>
          <v-text-field
            v-model="editedItem.email"
            :rules="emailRules"
            label="Email"
            :loading="loader"
            rounded
            filled
            required
          ></v-text-field>
          <div style="margin-bottom: 50px">
            <center>
              <v-btn
                :disabled="!valid || loader"
                :loading="loadingBtnProfil"
                rounded
                v-if="!mobileFormat"
                block
                class="mt-3 bg-color-main text-white ml-2"
                @click="confirmationFormTuto()"
              >
                <v-icon>mdi-content-save</v-icon> Enregistrer les modifications
              </v-btn>
              <v-btn
                :disabled="!valid || loader"
                :loading="loadingBtnProfil"
                rounded
                v-else
                class="mt-3 bg-color-main text-white ml-2"
                @click="confirmationFormTuto()"
              >
                <v-icon>mdi-content-save</v-icon> Enregistrer les modifications
              </v-btn>
            </center>
          </div>
        </v-form>

        <p class="fw-bold text-dark">Mes trophées !</p>
        <v-row v-if="loader" class="d-flex justify-content-center">
          <v-col :cols="colsResponsive">
            <v-skeleton-loader
              class="mx-auto my-3 rounded-xl"
              type="card, card, card, card, card, card, card, card"
            ></v-skeleton-loader>
          </v-col>
          <v-col :cols="colsResponsive">
            <v-skeleton-loader
              class="mx-auto my-3 rounded-xl"
              type="card, card, card, card, card, card, card, card"
            ></v-skeleton-loader>
          </v-col>
          <v-col :cols="colsResponsive">
            <v-skeleton-loader
              class="mx-auto my-3 rounded-xl"
              type="card, card, card, card, card, card, card, card"
            ></v-skeleton-loader>
          </v-col>
        </v-row>
        <v-card-text style="font-size: 20px" v-else-if="trophies.length > 0">
          <div class="pb-5">
            <v-row>
              <v-col
                :cols="colsResponsive"
                class="d-flex justify-content-center"
                v-for="trophie in trophies"
                :key="trophie.id"
              >
                <v-card width="300" class="mt-2 p-2 cardColor elevation-0">
                  <center>
                    <v-img
                      width="200"
                      height="200"
                      :src="trophie.image"
                    ></v-img>
                  </center>

                  <div class="rounded bg-color-main rounded-xl">
                    <v-card-title class="text-break">{{
                      trophie.libelle
                    }}</v-card-title>
                    <v-card-text class="text-break text-white">
                      {{ trophie.explications }}
                    </v-card-text>
                  </div>
                </v-card>
              </v-col>
            </v-row>
          </div>
        </v-card-text>
        <v-card-text v-else style="padding-bottom: 100px"
          ><h3 class="text-center lead text-dark">
            Vous n'avez pas encore gagné de trophée !
          </h3></v-card-text
        >
      </div>
    </div>
    <v-dialog v-model="dialogChangePassword" persistent max-width="600px">
      <v-card class="rounded-xl">
        <v-card-title>
          <span class="text-h5 text-break">Changer votre mot de passe </span>
        </v-card-title>
        <v-form v-model="validPassword">
          <v-card-text>
            <v-container>
              <v-row>
                <v-text-field
                  filled
                  v-model="oldPassword"
                  :append-icon="show2 ? 'mdi-eye' : 'mdi-eye-off'"
                  :rules="[rules.minRules]"
                  :type="show1 ? 'text' : 'password'"
                  name="input-10-1"
                  label="Ancien mot de passe"
                  rounded
                  hint="Le mot de passe doit faire au minimum 6 caractères"
                  counter
                  @click:append="show2 = !show2"
                ></v-text-field>
                <v-text-field
                  filled
                  v-model="password"
                  :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                  :rules="[rules.minRules]"
                  :type="show1 ? 'text' : 'password'"
                  name="input-10-1"
                  label="Mot de passe"
                  rounded
                  hint="Le mot de passe doit faire au minimum 6 caractères"
                  counter
                  @click:append="show1 = !show1"
                ></v-text-field>
                <v-text-field
                  v-model="verify"
                  filled
                  rounded
                  :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                  :rules="[passwordMatch]"
                  :type="show1 ? 'text' : 'password'"
                  name="input-10-1"
                  label="Confirmer le mot de passe"
                  counter
                  @click:append="show1 = !show1"
                ></v-text-field>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="#ffffff"
              rounded
              class="text-color-main m-1"
              @click="dialogChangePassword = false"
            >
              <v-icon>mdi-arrow-left</v-icon>Fermer
            </v-btn>
            <v-btn
              class="bg-color-main text-white m-1"
              :loading="btnLoadPassword"
              :disabled="!validPassword"
              rounded
              @click="changePassword()"
            >
              <v-icon>mdi-content-save</v-icon> Sauvegarder
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  metaInfo() {
    return {
      title:"Mon profil | Pocketuto ",
      meta: [
        { vmid: 'description', name: 'description', content: "Informations de mon profil" }
      ]
    }
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.getMyProfil();
    });
  },
  computed: {
    passwordMatch() {
      return () =>
        this.password === this.verify ||
        "Les mots de passes ne sont pas les mêmes";
    },

    chipResponsive() {
      if (window.innerWidth < 800) {
        return 3;
      } else {
        return 8;
      }
    },
    mobileFormat() {
      if (window.innerWidth < 800) {
        return true;
      } else {
        return false;
      }
    },
    colsResponsive() {
      if (window.innerWidth < 800) {
        return 12;
      } else {
        return 3;
      }
    },
  },
  data() {
    return {
      loader: true,
      tab: "",
      dialogChangePassword: false,
      dialogTrophies: false,
      editedItem: {
        id: "",
        pseudo: "",
        email: "",
      },
      categories: [],
      trophies: [],
      loadingBtnProfil: false,
      btnLoadPassword: false,
      oldPassword: "",
      password: "",
      dialog: false,
      inputFileSelect: require("/src/assets/user-placeholder.png"),
      valid: true,
      validPassword: true,
      verify: "",
      stockage: [],
      show1: false,
      show2: false,
      requiredRules: [(v) => !!v || "Le champ est obligatoire"],
      numberRules: [
        (v) => !!v || "Le champ est obligatoire",
        (v) => !isNaN(v) || "Ce n'est pas un nombre",
      ],
      emailRules: [
        (v) => !!v || "Le champ doit être remplis",
        (v) => /.+@.+\..+/.test(v) || "L'adresse email est invalide",
      ],
      rules: {
        requiredRules: (v) => !!v || "Le champ est obligatoire",
        minRules: (v) =>
          (v && v.length >= 6) ||
          "Le mot de passe doit faire au minimum 6 caractères",
      },
      numberRulesNoObligatory: [(v) => !isNaN(v) || "Ce n'est pas un nombre"],
    };
  },
   mounted() {
    this.$store.dispatch("categories/setCategories");
    if (this.$store.getters["categories/getCategories"].length == 0) {
      var refreshIntervalId = setInterval(() => {
        if (this.$store.getters["categories/dataLoad"]) {
          this.categories = this.$store.getters["categories/getCategories"];
          clearInterval(refreshIntervalId);
        }
      }, 1000);
    } else {
      this.categories = this.$store.getters["categories/getCategories"];
    }
  },
  methods: {
    getMyProfil() {
      var refreshIntervalId = setInterval(() => {
        if (this.$store.getters["auth/dataLoad"]) {
          this.editedItem = this.$store.getters["auth/user"];
          this.editedItem.changeLogo = false;
          this.inputFileSelect = this.editedItem.photo;
          this.categoriesUser = this.editedItem.categories;
          this.trophies = this.editedItem.trophies;
          this.loader = false;
          clearInterval(refreshIntervalId);
        }
      }, 1000);
    },
    triggerInputFile() {
      var fileUpload = document.getElementById("img-profil");
      if (fileUpload != null) {
        fileUpload.click();
      }
    },
    changePreview(event) {
      var reader = new FileReader();
      if (event.size > 2e6) {
        this.$swal(
          "L'image est trop volumineuse !",
          "Veuillez changer choisir une image pesant moins de 2 MB !",
          "warning"
        );
      } else {
        var image = event;
        this.editedItem.photo = event;
        this.editedItem.changeLogo = true;
        reader.readAsDataURL(image);
        reader.onload = () => {
          this.inputFileSelect = reader.result;
        };
      }
    },
    confirmationFormTuto() {
      if (this.editedItem.email == "") {
        this.$swal({
          toast: true,
          title: "Tous les champs doivent être remplis !",
          icon: "warning",
          animation: true,
          position: "center",
          showConfirmButton: false,
          timerProgressBar: true,
          timer: 2000,
        });
      } else {
        this.validate();
      }
    },
    updateCategorieUser(event) {
      this.$axios.post("users/updateCategories", event);
    },
    validate() {
      if (this.loadingBtnProfil == false) {
        this.loadingBtnProfil = true;
        let formData = new FormData();

        formData.append("email", this.editedItem.email);
        formData.append("photo", this.editedItem.photo);
        formData.append("changeLogo", this.editedItem.changeLogo);

        this.$axios.post("/users/updateProfil", formData).then((response) => {
          if (response.data.messageError) {
            this.$swal(
              "Oups, une erreur est survenu !",
              response.data.messageError,
              "error"
            ).then(() => {
              setTimeout(() => {
                this.loadingBtnProfil = false;
              }, 3000);
            });

            return;
          }
          this.$swal({
            toast: true,
            title: response.data.message,
            icon: "success",
            animation: true,
            position: "top-right",
            showConfirmButton: false,
            timerProgressBar: true,
            timer: 2000,
          });
          this.editedItem = response.data.user;
          this.$store.dispatch("setUser", this.editedItem);
          setTimeout(() => {
            this.loadingBtnProfil = false;
          }, 10000);
        });
      }
    },
    changePassword() {
      this.btnLoadPassword = true;
      if (this.password != this.verify) {
        this.$swal({
          toast: true,
          title: "Les mots de passes ne sont pas les mêmes !",
          icon: "warning",
          animation: true,
          position: "center",
          showConfirmButton: false,
          timerProgressBar: true,
          timer: 2000,
        });

        this.btnLoadPassword = false;
      } else {
        this.$axios
          .post("/users/changePassword/" + this.editedItem.id, {
            password: this.password,
            oldPassword: this.oldPassword,
          })
          .then((response) => {
            if (response.data.messageError) {
              this.$swal(
                "Oups, une erreur est survenu !",
                response.data.messageError,
                "error"
              ).then(() => {
                this.btnLoadPassword = false;
                this.dialogConfirmDelete = false;
              });
              return;
            }
            this.$swal({
              toast: true,
              title: response.data.message,
              icon: "success",
              animation: true,
              position: "top-right",
              showConfirmButton: false,
              timerProgressBar: true,
              timer: 2000,
            });
            this.dialogChangePassword = false;
            this.password = "";
            this.verify = "";
            this.btnLoadPassword = false;
          });
      }
      this.btnLoadPassword = false;
    },
  },
};
</script>

<style scoped>
.sticky {
  position: -webkit-sticky; /* Safari */
  position: sticky;
  top: 0;
  z-index: 2;
}
.cardColor {
   background-color: rgba(255, 255, 255, 0.0) !important;
 }
</style>