<template>
  <div data-app style="margin-bottom: 80px;">
    <v-app-bar v-if="isAuth == null" :elevation="5" flat background-color="#2596be" class="text-white" fixed dense app>
      <v-progress-linear indeterminate color="blue darken-2"></v-progress-linear>
    </v-app-bar>
    <v-app-bar v-if="isAuth" flat color="#2596be" class="text-white" :elevation="5" fixed app clipped-left dense>
      <v-app-bar-nav-icon class="fw-bold text-white" @click="drawer = true" v-if="!displayComputer">
      </v-app-bar-nav-icon>
      <v-app-bar-nav-icon class="fw-bold text-white" @click="$router.go(-1)">
        <v-icon>mdi-arrow-left</v-icon>
      </v-app-bar-nav-icon>
      <v-toolbar-title class="fw-bold fs-3" style="cursor:pointer"
        @click="(currentRouteName == 'POCKETUTO') ? navigate('Tutos') : navigate('POCKETUTO')">
        {{ currentRouteName }} <small class="lead ml-3" style="font-size: 7px">v.2.0.2</small>
      </v-toolbar-title>

      <v-spacer></v-spacer>

      <template v-slot:extension>
        <v-tabs v-if="isAuth && isValidated" color="#2596be" dark align-with-title v-model="tab">
          <v-tab @click="navigate('Tutos')" active-class="text-white">
            <v-icon dark>mdi-format-list-bulleted-triangle</v-icon>
            Tutos
          </v-tab>
          <v-tab @click="navigate('Dossiers')" active-class="text-white">
            <v-icon dark>mdi-folder-table</v-icon> Dossiers
          </v-tab>
          <v-tab @click="navigate('Profil')" active-class="text-white">
            <v-icon dark>mdi-account</v-icon> Profil
          </v-tab>
        </v-tabs>
      </template>

      <Recherche :isNotPageAccueil="true"></Recherche>
      <Notifications></Notifications>

    </v-app-bar>
    <v-app-bar v-else-if="isAuth === false" clipped-left color="#2596be" class="text-white" dense :elevation="5" app>
      <v-app-bar-nav-icon class="fw-bold text-white" @click="drawer = true" v-if="!displayComputer">
      </v-app-bar-nav-icon>
      <v-toolbar-title @click="mainPage()" class="text-white"></v-toolbar-title>
      <v-app-bar-nav-icon v-if="$route.name != 'POCKETUTO'" class="text-white fw-bold"
        @click="$router.push({ name: 'POCKETUTO' })">
        <v-icon>mdi-arrow-left</v-icon>
      </v-app-bar-nav-icon>
      <v-toolbar-title class="fw-bold fs-3" style="cursor:pointer" @click="navigate('POCKETUTO')">
        {{ currentRouteName }}
      </v-toolbar-title><small class="lead ml-3" style="font-size: 7px">v.2.0.2</small>
      <v-spacer></v-spacer>

      <v-btn text class="text-white" to="/authentification">
        <v-icon left>mdi-account-key</v-icon>
        Connexion / Inscription
      </v-btn>


    </v-app-bar>

    <v-navigation-drawer clipped class="text-color-main" app v-if="displayComputer" permanent expand-on-hover>

      <div class="mt-5" align="center">

        <v-img width="400px" style="cursor:pointer"
          @click="(currentRouteName == 'POCKETUTO') ? navigate('Tutos') : navigate('POCKETUTO')" v-if="!isDarkMode"
          src="@/assets/logo-pocketuto/logo-text-bleu-blanc.png"></v-img>
        <v-img width="400px" style="cursor:pointer"
          @click="(currentRouteName == 'POCKETUTO') ? navigate('Tutos') : navigate('POCKETUTO')" v-else
          src="@/assets/logo-pocketuto/logo-text-blanc-dark.png"></v-img>

      </div>
      <hr class="bg-dark" />
      <div class="d-flex justify-content-center">
        <v-list-item>
          <v-list-item-icon>
            <v-icon>mdi-theme-light-dark</v-icon>
          </v-list-item-icon>
          <v-switch v-model="darkMode" class="text-dark"></v-switch><v-list-item-title>Mode Sombre</v-list-item-title>
        </v-list-item>
      </div>
      <v-list nav dense v-if="isAuth && isValidated">
        <v-list-item-group v-model="group">
          <v-list-item @click="navigate('Ami(e)s')">
            <v-list-item-icon>
              <v-icon class="text-info">mdi-account-group</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Ami(e)s</v-list-item-title>
          </v-list-item>
          <v-list-item @click="navigate('Corbeille')">
            <v-list-item-icon>
              <v-icon :class="isDarkMode ? 'text-white' : 'text-dark'">mdi-delete-variant</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Corbeille</v-list-item-title>
          </v-list-item>

          <!--    <v-list-item @click="navigate('Boutique')">
            <v-list-item-icon>
              <v-icon class="text-warning">mdi-crown</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Boutique</v-list-item-title>
          </v-list-item> -->
          <v-list-item @click="navigate('Panel Admin')" v-if="showSuperadminThings">
            <v-list-item-icon>
              <v-icon class="text-danger">mdi-shield-account</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Panel admin</v-list-item-title>
          </v-list-item>
          <v-list-item @click="navigate('Paramètres')">
            <v-list-item-icon>
              <v-icon :class="isDarkMode ? 'text-white' : 'text-dark'">mdi-cogs</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Paramètres</v-list-item-title>
          </v-list-item>
          <v-list-item @click="navigate('Support')">
            <v-list-item-icon>
              <v-icon class="text-primary">mdi-face-agent</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Support</v-list-item-title>
          </v-list-item>
          <v-list-item @click="navigate('Partager')">
            <v-list-item-icon>
              <v-icon :class="isDarkMode ? 'text-white' : 'text-dark'">mdi-qrcode</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Partager l'application</v-list-item-title>
          </v-list-item>
        </v-list-item-group>
      </v-list>
      <template v-slot:append>
        <v-list>
          <v-list-item-group>
            <v-list-item @click="openModalConfirmSignout()">
              <v-list-item-icon>
                <v-icon :class="isDarkMode ? 'text-white' : 'text-dark'">mdi-logout</v-icon>
              </v-list-item-icon>
              <v-list-item-title class="fw-bold">Se déconnecter</v-list-item-title>
            </v-list-item>
          </v-list-item-group>
        </v-list>
      </template>
    </v-navigation-drawer>
    <v-navigation-drawer clipped class="text-color-main" app  v-model="drawer">
      <v-btn @click="drawer = false" icon class="m-3">
        <v-icon>mdi-arrow-left</v-icon>
      </v-btn>
      <div class="mt-5" align="center">
        <v-img width="400px" v-if="!isDarkMode" src="@/assets/logo-pocketuto/logo-text-bleu-blanc.png"></v-img>
        <v-img width="400px" v-else src="@/assets/logo-pocketuto/logo-text-blanc-dark.png"></v-img>
      </div>
      <hr class="bg-dark" />
      <div class="d-flex justify-content-center">
        <v-list-item>
          <v-list-item-icon>
            <v-icon>mdi-theme-light-dark</v-icon>
          </v-list-item-icon>
          <v-switch v-model="darkMode" class="text-dark"></v-switch><v-list-item-title>Mode Sombre</v-list-item-title>
        </v-list-item>
      </div>
      <v-list nav dense v-if="isAuth && isValidated">
        <v-list-item-group v-model="group">
          <v-list-item @click="navigate('Ami(e)s')">
            <v-list-item-icon>
              <v-icon class="text-info">mdi-account-group</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Ami(e)s</v-list-item-title>
          </v-list-item>
          <v-list-item @click="navigate('Corbeille')">
            <v-list-item-icon>
              <v-icon :class="isDarkMode ? 'text-white' : 'text-dark'">mdi-delete-variant</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Corbeille</v-list-item-title>
          </v-list-item>

          <!--        <v-list-item @click="navigate('Boutique')">
            <v-list-item-icon>
              <v-icon class="text-warning">mdi-crown</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Boutique</v-list-item-title>
          </v-list-item> -->
          <v-list-item @click="navigate('Panel Admin')" v-if="showSuperadminThings">
            <v-list-item-icon>
              <v-icon class="text-danger">mdi-shield-account</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Panel admin</v-list-item-title>
          </v-list-item>

          <v-list-item @click="navigate('Paramètres')">
            <v-list-item-icon>
              <v-icon :class="isDarkMode ? 'text-white' : 'text-dark'">mdi-cogs</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Paramètres</v-list-item-title>
          </v-list-item>
          <v-list-item @click="navigate('Support')">
            <v-list-item-icon>
              <v-icon class="text-primary">mdi-face-agent</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Support</v-list-item-title>
          </v-list-item>
          <v-list-item @click="navigate('Partager')">
            <v-list-item-icon>
              <v-icon :class="isDarkMode ? 'text-white' : 'text-dark'">mdi-qrcode</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Partager l'application</v-list-item-title>
          </v-list-item>
        </v-list-item-group>
      </v-list>
      <template v-slot:append v-if="isAuth">
        <v-list style="margin-bottom:100px;">
          <v-list-item-group>
            <v-list-item @click="openModalConfirmSignout()">
              <v-list-item-icon>
                <v-icon :class="isDarkMode ? 'text-white' : 'text-dark'">mdi-logout</v-icon>
              </v-list-item-icon>
              <v-list-item-title class="fw-bold">Se déconnecter</v-list-item-title>
            </v-list-item>
          </v-list-item-group>
        </v-list>
      </template>
    </v-navigation-drawer>
    <dialog-confirm :titre="arrayDialog.titre" :message="arrayDialog.message" :functionCalled="arrayDialog.functionCalled"
      type="disconnect"></dialog-confirm>
  </div>
</template>

<script>
/* eslint-disable */
import Notifications from "./Notifications.vue";
import { mapActions } from "vuex";
import Recherche from './Recherche.vue';
import DialogConfirm from '@/components/modal/DialogConfirm.vue';
export default {
  components: { Notifications, Recherche, DialogConfirm },
  props: ["showNavigationDrawer"],
  data() {
    return {
      drawer: false,
      loadingRefreshList: false,
      tab: null,
      group: null,
      dialogSignout: false,
      user: [],
      isValidated: false,
      nbTutosRestantCreer: null,
      isAuth: null,
      arrayDialog: {
        titre: "",
        message: "",
        functionCalled: "",
      },
      darkMode: false,
    };
  },
  created() {
    this.darkMode =
      localStorage.getItem("darkModePocketuto") === "true" ? true : false;
  },
  mounted() {
    this.isAuth = this.$store.getters["auth/authenticated"];
    var refreshIntervalId = setInterval(() => {
      if (this.$store.getters["auth/dataLoad"]) {
        this.isValidated = this.$store.getters['auth/isValidated']
        this.isAuth = this.$store.getters["auth/authenticated"];
        this.user = this.$store.getters["auth/user"];
        if (this.user.arrayTrophiesWin) {
          var trophyWins = this.user.arrayTrophiesWin;
          Object.entries(trophyWins).forEach(([key, val]) => {
            const options = {
              title: val.libelle,
              imageUrl: val.image,
              imageHeight: "100px",
              text: val.message,
            };
            this.$swal(options);
          });
        }
        this.nbTutosRestantCreer =
          this.$store.getters["auth/user"]["nbTutosRestantCreer"];
        clearInterval(refreshIntervalId);
      }
    }, 500);
    switch (this.currentRouteName) {
      case "Mes abonnements":
        this.tab = 0;
        break;
      case "Mes tutos":
        this.tab = 1;
        break;
      case "Mes dossiers":
        this.tab = 2;
        break;
      case "Mon compte":
        this.tab = 3;
        break;

      default:
        break;
    }
  },
  watch: {
    navDrawer() {
      this.drawer = true;
    },
    darkMode(val) {
      if (val === true) {
        this.$vuetify.theme.dark = true;
        localStorage.setItem("darkModePocketuto", true);
      } else {
        this.$vuetify.theme.dark = false;
        localStorage.setItem("darkModePocketuto", false);
      }
    },
  },
  computed: {
    navDrawer() {
      return this.showNavigationDrawer;
    },
    showSuperadminThings() {
      if (this.$store.getters["auth/role"] == "Superadmin") {
        return true;
      } else {
        return false;
      }
    },
    displayComputer() {
      if (window.innerWidth < 800) {
        return false;
      } else {
        return true;
      }
    },
    currentRouteName() {
      return this.$route.name;
    },
  },
  methods: {
    ...mapActions({
      logout: "auth/logout",
    }),

    openModalConfirmSignout() {
      this.arrayDialog['message'] = "Se déconecter de votre compte ?"
      this.arrayDialog['titre'] = "Que voulez-vous faire ?"
      this.arrayDialog['functionCalled'] = "confirmSignout"
      this.$store.dispatch('modal/setOpen', 'disconnect')
    },
    confirmSignout() {
      this.logout().then(() => {
        this.$store.dispatch('modal/setClose', 'disconnect')
        this.$router.push({ name: "Je m'authentifie" }).then(() => {
          document.location.reload()
        });
      });
    },
    showRefreshListMyTutos() {
      if (this.$route.name == "Mes tutos") {
        return true;
      } else {
        return false;
      }
    },
    navigate(to) {
      if (to == this.currentRouteName) {
        this.drawer = false;
      } else {
        this.tab = this.currentRouteName;
        this.$router.push({ name: to });
      }
    },
    refresh() {
      this.loadingRefreshList = true;
      document.location.reload();
      setTimeout(() => {
        this.loadingRefreshList = false;
      }, 5000);
    },
  },
};
</script>

<style>
.title-app {
  font-family: Antara;
  cursor: pointer;
  font-size: 40px;
}

.imgHover :hover {
  cursor: pointer;
}
</style>