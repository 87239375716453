import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import router from './router'
import Vuex from 'vuex'
import axios from "axios"
import store from "./store/index.js";
import VueSweetalert2 from 'vue-sweetalert2';
import infiniteScroll from 'vue-infinite-scroll'
import VueRecaptcha from "vue-recaptcha";
import VueTypedJs from 'vue-typed-js'
import Ads from 'vue-google-adsense'
import Editor from 'vue-editor-js/src/index'
import VueMeta from 'vue-meta'
import globals from './services/globalFunctions'
import viewMixin from "@/Mixins/viewMixin.js";

require('@/assets/styles/styles.css')

export const bus = new Vue();

import 'sweetalert2/dist/sweetalert2.min.css';
import '../public/manifest.json';

Vue.config.productionTip = false

Vue.mixin(viewMixin);
Vue.use(VueMeta)
Vue.use(globals)
Vue.use(Vuex)
Vue.use(VueTypedJs)
Vue.use(VueSweetalert2);
Vue.use(Editor)
Vue.use(infiniteScroll);
Vue.use(require('vue-script2'))
Vue.use(Ads.Adsense)
Vue.use(Ads.InArticleAdsense)
Vue.use(Ads.InFeedAdsense)

Vue.component('vue-recaptcha', VueRecaptcha);

require("@/store/subscriber");
require('@/assets/styles/styles.css')

if (window.location.href.includes('http://localhost')) {
    axios.defaults.baseURL = "http://tutoriallapi/api/$2y$10$3qq00R0Pea65j8uW0ASGEuZ3L85F7R.rQsAbSrLQjdQ.EL0wZliI/B80800313C2479AB097C2EAD7515D280/";
} else if (window.location.href.includes('https://pocketuto.fr')) {
    axios.defaults.baseURL = "https://my.pocketuto.fr/api/$2y$10$3qq00R0Pea65j8uW0ASGEuZ3L85F7R.rQsAbSrLQjdQ.EL0wZliI/B80800313C2479AB097C2EAD7515D280/"
} else if (window.location.href.includes('http://192.168.0.11')) {
    axios.defaults.baseURL = "https://my.pocketuto.fr/api/$2y$10$3qq00R0Pea65j8uW0ASGEuZ3L85F7R.rQsAbSrLQjdQ.EL0wZliI/B80800313C2479AB097C2EAD7515D280/"
}
store.dispatch("auth/attempt", localStorage.getItem("tokenPocketuto"));

Vue.prototype.$axios = axios
Vue.prototype.$store = store


new Vue({
    vuetify,
    axios,
    store,
    router,
    render: h => h(App)
}).$mount('#app')