/* eslint-disable */
import axios from 'axios'
export default {
    namespaced: true,
    state: {
        categories: [],
        dataLoad: false,
    },
    mutations: {
        SET_CATEGORIES(state, categories) {
            state.categories = categories
        },
        SET_DATALOAD(state, data) {
            state.dataLoad = data
        },
    },
    getters: {
        getCategories(state) {
            return state.categories;
        },
        dataLoad(state) {
            return state.dataLoad;
        }
    },
    actions: {
        setCategories({ commit }) {
            axios.get("/categories/index").then((response) => {
                commit("SET_CATEGORIES", response.data);
                commit('SET_DATALOAD', true)
            });
        },

    }
}