<template>
  <div>
    <input type="hidden" v-model="updatePage" />
    <div class="d-flex justify-content-center pt-4">
      <v-alert class="text-h5" color="#2596be" shaped type="info">
        Renseigner les <span class="fw-bold text-warning">informations du tuto puis enregistrer </span> pour écrire le
        contenu
      </v-alert>
    </div>

    <v-card-title class="text-h6 font-weight-regular d-flex justify-content-center">
      <div class=" text-center">Image de converture <i class='fw-bold'>Facultatif</i></div>
    </v-card-title>

    <div class="d-flex justify-content-center p-3">
      <v-img @click="triggerInputFile()" max-width="500" class="rounded-xl hoverImage" :src="inputFileSelect">
      </v-img>
      <v-file-input type="file" id="couverture-tuto" ref="input1" style="display: none" accept=".png, .jpg, .jpeg"
        @change="changePreview($event)"></v-file-input>
    </div>
    <div class="d-flex justify-content-center">
      <v-btn @click="removeCouvertureImage()" class="bg-danger" rounded v-if="editedItem.image_couverture"><v-icon>mdi-delete</v-icon>Retirer
        l'image</v-btn>
    </div>

    <div class="pa-4 mt-5">

      <p class="fw-light  text-center">Visibilité du dossier :</p>
      <div class="mb-5 d-flex justify-content-center">

        <v-btn class="bg-color-main m-1" :loading="loader" :style="borderChoosenPrivate"
          @click="openDialogIsPrivate(1)">
          <v-icon>mdi-lock</v-icon> Privé
        </v-btn>
        <v-btn class="bg-color-main m-1" :loading="loader" :style="borderChoosenPublic" @click="openDialogIsPrivate(0)">
          <v-icon>mdi-lock-open</v-icon> Public
        </v-btn>
      </div>

      <v-form ref="form" autocomplete="on" v-model="valid" lazy-validation>
        <v-text-field v-model="editedItem.titre" :rules="requiredRules" label="Titre *"
          prepend-inner-icon="mdi-format-title" :counter="80" filled :loading="loader" rounded
          :maxlength="80" required></v-text-field>

        <label class="text-danger fst-italic">Appuyer sur votre touche "Entrée" pour enregistrer le mot clé</label>
        <v-combobox v-model="editedItem.mot_clefs" :items="[]" prepend-inner-icon="mdi-keyboard-variant"
          :rules="requiredRules" filled rounded chips label="Ecrivez des mots clés" multiple :counter="8" required>
          <template v-slot:selection="{ attrs, item, selected }">
            <v-chip v-bind="attrs" class="bg-color-main rounded-xl" rounded :input-value="selected" label>
              <span class="pr-2">
                {{ item }}
              </span>
            </v-chip>
          </template>
        </v-combobox>
        <v-select label="Catégories du tuto" v-model="editedItem.categories" :items="categories" item-text="nom"
          item-value="id" filled :loading="loader" rounded multiple>
          <template v-slot:selection="{ item }">
            <v-chip class="bg-warning text-dark">
              <span>{{ item.nom }}</span>
            </v-chip>
          </template>
        </v-select>
      </v-form>
    </div>

    <v-divider></v-divider>

    <v-card-actions class="pb-5 d-flex justify-content-end btn-group-vertical">
      <div>
        <v-btn rounded class="bg-warning text-dark mt-3" @click="resetForm()">
          <v-icon>mdi-reload</v-icon>Réinitialiser les infos du tuto
        </v-btn>

        <v-btn :loading="loadingBtnEdit" :disabled="!valid" x-large rounded class="bg-color-main text-white mt-3"
          @click="confirmationFormTuto()">
          <v-icon>mdi-content-save</v-icon> Enregistrer les informations
        </v-btn>
      </div>
    </v-card-actions>
    <dialog-confirm :titre="arrayDialog.titre" :message="arrayDialog.message"
      :functionCalled="arrayDialog.functionCalled" type="formTuto"></dialog-confirm>
  </div>
</template>

<script>
/* eslint-disable */
import DialogConfirm from '@/components/modal/DialogConfirm.vue';
export default {
  props: ["typeFormulaire", "idTuto"],
  components: { DialogConfirm },
  created() {
    if (this.typeFormulaire == "edition") {
      this.loader = true;
      this.$axios.get("/tutos/getTutoById/" + this.idTuto).then((response) => {
        this.editedItem = response.data;
        this.editedItem.changeLogo = false;
        this.loader = false;
        this.editedItem.changeImages = [];
        this.inputFileSelect = this.editedItem.image_couverture;
      });
    }
  },
  data() {
    return {
      valid: true,
      loadBtn: false,
      updatePage: 0,
      textFields: [],
      name: "",
      textQuill: 1,
      dialogContenuFree: false,
      dialogContenuModele: false,
      bgBtn: "bg-dark",
      loader: false,
      textSpeak: null,
      loadingBtnEdit: false,
      categories: [],
      dialogTextFieldFullScreen: false,
      fullScreenEditor: {
        value: '',
        index: '',
      },
      pdf: [],
      editedItem: {
        titre: "",
        mot_clefs: "",
        isPrivate: 0,
        image_couverture: "",
        categories: [],
        changeImages: [],
      },
      heightEditor: 20,
      previewImage: [],
      files: [],
      inputFileSelect: require("/src/assets/placeholder.jpg"),
      requiredRules: [(v) => !!v || "Le champ est obligatoire"],
      numberRules: [
        (v) => !!v || "Le champ est obligatoire",
        (v) => !isNaN(v) || "Ce n'est pas un nombre",
      ],
      emailRules: [
        (v) => !!v || "Le champ doit être rempli",
        (v) => /.+@.+\..+/.test(v) || "L'adresse email est invalide",
      ],
      passwordConfirm: [
        (v) =>
          this.validatePassword(v) || "Les mots de passes ne concorde pas !",
      ],
      rules: {
        requiredRules: (v) => !!v || "Le champ est obligatoire",
        minRules: (v) =>
          (v && v.length >= 6) ||
          "Le mot de passe doit faire au minimum 6 caractères",
      },
      numberRulesNoObligatory: [(v) => !isNaN(v) || "Ce n'est pas un nombre"],
      borderChoosenPublic: "",
      borderChoosenPrivate: "",
      valueIsPrivate: "",
      arrayDialog: {
        titre: "",
        message: "",
        functionCalled: "",
      }
    };
  },
  mounted() {
    this.$store.dispatch("categories/setCategories");
    if (this.$store.getters["categories/getCategories"].length == 0) {
      var refreshIntervalId = setInterval(() => {
        if (this.$store.getters["categories/dataLoad"]) {
          this.categories = this.$store.getters["categories/getCategories"];
          clearInterval(refreshIntervalId);
        }
      }, 1000);
    }
    else {
      this.categories = this.$store.getters["categories/getCategories"];
    }
    if (this.editedItem.isPrivate) {
      this.borderChoosenPrivate = "border:3px solid red; "
      this.borderChoosenPublic = ""
    } else {
      this.borderChoosenPublic = "border: 3px solid red;"
      this.borderChoosenPrivate = ""
    }
  },
  watch: {
    motClefs(val) {
      if (val.length > 8) {
        this.$nextTick(() => this.editedItem.mot_clefs.pop())
      }
    },
    isPrivate(val) {
      if (val) {
        this.borderChoosenPrivate = "border:3px solid red; "
        this.borderChoosenPublic = ""
      } else {
        this.borderChoosenPublic = "border: 3px solid red;"
        this.borderChoosenPrivate = ""
      }
    },
  },
  computed: {
    motClefs() {
      return this.editedItem.mot_clefs
    },
    isPrivate() {
      return this.editedItem.isPrivate
    },
  },
  methods: {
    maxKeyWords(value) {
      if (Number(value) > Number(10)) value = 10;
    },
    openDialogIsPrivate(isPrivate) {
      this.valueIsPrivate = isPrivate
      if (this.valueIsPrivate == this.editedItem.isPrivate) {
        return false
      }
      if (this.editedItem.isInFolder && this.editedItem.isPrivate == 0) {
        this.arrayDialog['message'] = "Voulez-vous mettre ce tuto en privé ? Il sera supprimer de tous les dossiers public dans lequel il se trouve"
        this.arrayDialog['titre'] = "Que voulez-vous faire ?"
        this.arrayDialog['functionCalled'] = "chooseIsPrivate"
        this.$store.dispatch('modal/setOpen', 'formTuto')
      } else {
        this.chooseIsPrivate()
      }
    },
    chooseIsPrivate() {

      if (this.editedItem.isInFolder && this.editedItem.isPrivate == 0) {

        this.editedItem.isPrivate = this.valueIsPrivate
        if (this.valueIsPrivate) {
          this.borderChoosenPrivate = "border:3px solid red; "
          this.borderChoosenPublic = ""
        } else {
          this.borderChoosenPublic = "border: 3px solid red;"
          this.borderChoosenPrivate = ""
        }

      } else {
        this.editedItem.isPrivate = this.valueIsPrivate
        if (this.valueIsPrivate) {
          this.borderChoosenPrivate = "border:3px solid red; "
          this.borderChoosenPublic = ""
        } else {
          this.borderChoosenPublic = "border: 3px solid red;"
          this.borderChoosenPrivate = ""
        }
      }
      this.$store.dispatch('modal/setClose', 'formTuto')

    },
    confirmationFormTuto() {
      if (
        this.editedItem.titre == "" ||
        this.editedItem.mot_clefs == "" ||
        !this.editedItem.categories ||
        this.editedItem.categories == 0
      ) {
        this.$swal({
          toast: true,
          title:
            "Tous les champs doivent être rempli et vous devez choisir au moins une catégorie pour votre tuto !",
          icon: "warning",
          animation: true,
          position: "center",
          showConfirmButton: false,
          timerProgressBar: true,
          timer: 3000,
        });
      } else {
        this.validate();
      }
    },

    resetForm() {
      this.arrayDialog['message'] = "Voulez-vous réinitialiser les informations du tuto"
      this.arrayDialog['titre'] = "Que voulez-vous faire ?"
      this.arrayDialog['functionCalled'] = "confirmResetForm"
      this.$store.dispatch('modal/setOpen', 'formTuto')
    },
    confirmResetForm() {
      this.editedItem = {
        titre: "",
        mot_clefs: "",
        categories: [],
        image_couverture: "",
        isPrivate: 0,
        changeImages: [],
      };
    },
    triggerInputFile() {
      var fileUpload = document.getElementById("couverture-tuto");
      if (fileUpload != null) {
        fileUpload.click();
      }
    },
    removeCouvertureImage() {
      this.editedItem.image_couverture = "";
      this.editedItem.changeLogo = false;
      this.inputFileSelect = require("/src/assets/placeholder.jpg");
    },
    changePreview(event) {
      var reader = new FileReader();
      if (event) {
        if (event.size > 2e6) {
          this.$swal(
            "L'image est trop volumineuse !",
            "Veuillez changer choisir une image pesant moins de 2 MB !",
            "warning"
          );
        } else {
          var image = event;
          this.editedItem.image_couverture = event;
          this.editedItem.changeLogo = true;
          reader.readAsDataURL(image);
          reader.onload = () => {
            this.inputFileSelect = reader.result;
          };
        }
      }
    },
    clearContenu() {
      this.editedItem.content = "";
    },
    validate() {
      this.loadingBtnEdit = true;
      let formData = new FormData();
      formData.append("titre", this.editedItem.titre);
      if (this.editedItem.changeLogo) {
        formData.append("image_couverture", this.editedItem.image_couverture);
      } else {
        formData.append("image_couverture", null);
      }
      formData.append("mot_clefs", this.editedItem.mot_clefs);
      formData.append("isPrivate", this.editedItem.isPrivate == "0" ? 0 : 1);
      formData.append("changeLogo", this.editedItem.changeLogo);
      formData.append("categories", JSON.stringify(this.editedItem.categories));
      formData.append("editContent", false);

      if (this.typeFormulaire == "edition") {
        var payload = { idTuto: this.idTuto, editedItem: formData };
        this.$store.dispatch("tutos/editTuto", payload).then((response) => {
          if (response.data.messageError) {
            this.loadingBtnEdit = false;
            this.$swal("Oups !", response.data.messageError, "error");
            return;
          }
          this.$swal({
            toast: true,
            title: response.data.message,
            icon: "success",
            animation: true,
            position: "top-right",
            showConfirmButton: false,
            timerProgressBar: true,
            timer: 2000,
          });
          this.loadingBtnEdit = false;
          this.$router.push({ name: "Contenu du tuto", params: { code_tuto: response.data.tuto.code_tuto, slug: this.slugify(response.data.tuto.titre, '-') } })
          this.loadingBtnEdit = false;
        });
      } else {
        this.$store.dispatch("tutos/addTuto", formData).then((response) => {
          if (response.data.messageError) {
            this.loadingBtnEdit = false;
            this.$swal("Oups !", response.data.messageError, "error");
            return;
          }
          this.$swal({
            toast: true,
            title: response.data.message,
            icon: "success",
            animation: true,
            position: "top-right",
            showConfirmButton: false,
            timerProgressBar: true,
            timer: 2000,
          });

          this.loadingBtnEdit = false;

          if (response.data.trophyWins) {
            var trophyWins = response.data.trophyWins;
            Object.entries(trophyWins).forEach(([key, val]) => {
              const options = {
                title: val.libelle,
                imageUrl: val.image,
                imageHeight: "100px",
                text: val.message,
              };
              this.$swal(options);
            });
          }

          this.resetForm();
          this.$router.push({ name: "Contenu du tuto", params: { code_tuto: response.data.tuto.code_tuto, slug: this.slugify(response.data.tuto.titre, '-') } })
          this.loadingBtnEdit = false;
          this.$store.dispatch('modal/setClose', 'formTuto')
        });
        this.$store.dispatch('modal/setClose', 'formTuto')
      }
    },
  },
};
</script>

<style scoped>
div {
  color: white;
}


.v-counter {
  color: white;
}

.fixed-bar {
  position: sticky;
  position: -webkit-sticky;
  /* for Safari */
  z-index: 2;
  top: 0;
}

.hoverImage {
  cursor: pointer;
}
</style>