import Vue from 'vue'
import VueRouter from 'vue-router'
import store from "@/store/index.js";
import Index from "@/views/Index.vue";
import Authentification from "@/views/auth/Authentification.vue"
import Profil from "@/views/profil/Profil.vue"
import CreateTuto from "@/views/tutos/CreateTuto.vue"
import ContentTuto from "@/views/tutos/ContentTuto.vue"
import EditTuto from "@/views/tutos/EditTuto.vue"
import SendRequest from "@/components/SendRequest.vue"
import PaymentFailed from "@/components/payment/PaymentFailed.vue";
import PaymentSuccess from "@/components/payment/PaymentSuccess.vue";
import ForgotPassword from "@/views/password/ForgotPassword.vue"
import ChangePassword from "@/views/password/ChangePassword.vue"
import MyFriends from "@/views/friends/MyFriends.vue"
import Parametre from "@/components/Parametre.vue";
import PanelAdmin from "@/views/admin/PanelAdmin.vue";
import Corbeille from "@/views/corbeille/Corbeille.vue";
import Policy from "@/views/Policy.vue";
import Share from "@/views/Share.vue";
import ContentFolder from "@/views/folders/ContentFolder.vue"
import Tutos from "@/views/tutos/Tutos.vue"
import Folder from "@/views/folders/Folder.vue";
import CreateFolder from "@/views/folders/CreateFolder.vue";
import EditFolder from "@/views/folders/EditFolder.vue";
import PageNotFound from "@/views/PageNotFound.vue"
import VerifySuccess from "@/views/confirmAccount/VerifySuccess.vue";
import WaitVerifyEmail from "@/views/confirmAccount/WaitVerifyEmail";
import Support from "@/views/support/Support.vue"
import CreateTicket from "@/views/support/CreateTicket.vue"
import ContenuTicket from "@/views/support/ContenuTicket.vue"

Vue.use(VueRouter)

const router = new VueRouter({
    mode: "history",
    routes: [{
            path: "/",
            name: "POCKETUTO",
            component: Index,
            props: true,
            meta: {
                requiresAuth: false
            },
        },
        {
            path: '/mot_de_passe_oublie',
            name: 'Mot de passe oublié',
            props: true,
            component: ForgotPassword,
        },
        {
            path: '/reinitialisation_mot_de_passe/*',
            name: 'Changer le mot de passe',
            props: true,
            component: ChangePassword,
        },
        {
            path: '/verification/email/reussi',
            name: 'Compte validé',
            component: VerifySuccess,
            beforeEnter: (to, from, next) => {
                setTimeout(() => {
                    if (store.getters['auth/authenticated'] && store.getters["auth/isValidated"]) {
                        next("/tutos");
                    } else if (!store.getters['auth/authenticated']) {
                        next("/authentification");
                    } else {
                        next();
                    }
                }, 500)

            }

        },
        {
            path: '/verification/email/en_attente',
            name: 'Compte en attente de validation',
            component: WaitVerifyEmail,
            beforeEnter: (to, from, next) => {
                setTimeout(() => {
                    if (store.getters['auth/authenticated'] && store.getters["auth/isValidated"]) {
                        next("/tutos");
                    } else if (!store.getters['auth/authenticated']) {
                        next("/authentification");
                    } else {
                        next();
                    }
                }, 500)

            }
        }, {
            path: '/authentification',
            name: "Je m'authentifie",
            component: Authentification,
            props: true,
            meta: {
                loggedin: true
            },
            beforeEnter: (to, from, next) => {
                setTimeout(() => {
                    if (store.getters["auth/authenticated"]) {
                        next("/tutos");
                    } else {
                        next();
                    }
                }, 500)

            }
        },
        {
            path: '/dossier',
            name: 'Dossiers',
            component: Folder,
            props: true,
            meta: {
                requiresAuth: true
            },
        },
        {
            path: '/tuto/:code_tuto/:slug',
            name: 'Contenu du tuto',
            component: ContentTuto,
            props: true,

        },
        {
            path: '/creer_un_dossier',
            name: 'Créer un dossier',
            component: CreateFolder,
            props: true,
            meta: {
                requiresAuth: true
            }
        }, {
            path: '/editer_le_dossier/:idFolder',
            name: 'Editer le dossier',
            component: EditFolder,
            props: true,
            meta: {
                requiresAuth: true
            }
        },
        {
            path: '/dossier/:code_dossier/:slug',
            name: 'Contenu du dossier',
            component: ContentFolder,
            props: true,
        },
        {
            path: '/panel_admin',
            name: 'Panel Admin',
            props: true,
            component: PanelAdmin,
            meta: {
                requiresAuth: true
            },
            beforeEnter: (to, from, next) => {
                setTimeout(() => {
                    if (store.getters['auth/role'] == "Superadmin") {
                        next();
                    } else {
                        next(false);
                    }
                }, 500)

            }
        },
        {
            path: '/mon_profil',
            name: 'Profil',
            props: true,
            component: Profil,
            meta: {
                requiresAuth: true
            },
        },
        {
            path: '/politique_de_confidentialite&mentions_legales',
            name: 'Politique du site',
            props: true,
            component: Policy,
            meta: {
                loggedin: true
            },
        },
        {
            path: '/ma_corbeille',
            name: 'Corbeille',
            props: true,
            component: Corbeille,
            meta: {
                requiresAuth: true
            },
        },
        {
            path: '/mes_amies',
            name: 'Ami(e)s',
            props: true,
            component: MyFriends,
            meta: {
                requiresAuth: true
            },
        },
        {
            path: '/tutos',
            name: 'Tutos',
            props: true,
            component: Tutos,
            meta: {
                requiresAuth: true
            },
        },
        {
            path: '/partager_notre_application',
            name: 'Partager',
            props: true,
            component: Share,
            meta: {
                requiresAuth: true
            },
        },
        {
            path: '/creer_un_tuto',
            name: 'Créer un tuto',
            props: true,
            component: CreateTuto,
            meta: {
                requiresAuth: true
            },
            beforeEnter: (to, from, next) => {
                setTimeout(() => {
                    if (store.getters['auth/role'] == "Createur" || store.getters["auth/role"] == "Superadmin") {
                        next();
                    } else {
                        next(false);
                    }
                }, 500)


            }
        },
        {
            path: '/envoyer/:nomElement/:idElement',
            name: 'Envoyer',
            props: true,
            component: SendRequest,
            meta: {
                requiresAuth: true
            },
        },
        {
            path: '/parametres',
            name: 'Paramètres',
            props: true,
            component: Parametre,
            meta: {
                requiresAuth: true
            },
        },
        {
            path: '/editer_tuto/:tutoId',
            name: 'Editer le tuto',
            props: true,
            component: EditTuto,
            meta: {
                requiresAuth: true
            },
            beforeEnter: (to, from, next) => {
                setTimeout(() => {
                    if (store.getters['auth/role'] == "Createur" || store.getters["auth/role"] == "Superadmin") {
                        next();
                    } else {
                        next(false);
                    }
                }, 500)

            }
        },
        {
            path: "/support/tickets",
            name: "Support",
            component: Support,
            meta: {
                requiresAuth: true
            },
        },
        {
            path: "/support/creer_un_ticket",
            name: "Créer un ticket",
            component: CreateTicket,
            meta: {
                requiresAuth: true
            },
        },
        {
            path: "/support/ticket/:idTicket",
            name: "Contenu du ticket",
            component: ContenuTicket,
            props: true,
            meta: {
                requiresAuth: true
            },
        },
        {
            path: "/paiement_reussi",
            name: "Paiement réussi",
            component: PaymentSuccess,
            meta: {
                requiresAuth: true
            },
        },
        {
            path: "/paiement_echoue",
            name: "Paiement échoué",
            component: PaymentFailed,
            meta: {
                requiresAuth: true
            },
        },
        {
            path: '/auth/:provider/callback',
            component: {
                template: '<div class="auth-component"></div>'
            }
        },
        {
            path: '**',
            name: 'Page inexistante',
            component: PageNotFound
        }
    ]
})
router.beforeEach((to, from, next) => {
    try {
        if (to.meta.requiresAuth) {
            if (store.getters["auth/authenticated"]) {
                var refreshIntervalId = setInterval(() => {
                    if (store.getters["auth/dataLoad"]) {
                        if (store.getters['auth/isValidated']) {
                            clearInterval(refreshIntervalId);
                            next();
                        } else {
                            clearInterval(refreshIntervalId);
                            next('/verification/email/en_attente');
                        }
                    }
                }, 100);

            } else {
                next('/authentification');
            }
        } else {
            next();
        }
    } catch (error) {
        //
    }

});

export default router