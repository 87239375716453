<template>
  <div class="container mt-10" style="margin-bottom:100px">
    <form-tuto :typeFormulaire="'edition'" :idTuto="tutoId"></form-tuto>
  </div>
</template>

<script>
import FormTuto from "@/components/tutos/FormTuto.vue";
export default {
  metaInfo() {
    return {
      title: "Modifier le tuto | Pocketuto ",
      meta: [
        { vmid: 'description', name: 'description', content: "Modifier le tuto"}
      ]
    }
  },
  beforeRouteLeave(to, from, next) {
    if (from.name == "Editer le tuto") {
      var editedItem = {
        titre: "",
        description: "",
        mot_clefs: "",
        content: "",
        image_couverture: "",
      };
      localStorage.setItem("editedItem", JSON.stringify(editedItem));
    }
    next();
  },
  components: {
    FormTuto,
  },
  props: ["tutoId"],
  computed: {},
  data() {
    return {};
  },
  methods: {
    returnBack() {
      this.$router.go(-1);
    },
  },
};
</script>

<style>
</style>