<template>
    <div>
        <v-expansion-panels class="rounded-xl mt-3" inset accordion flat>
            <v-expansion-panel :style="isDarkMode ? 'background-color: #000' : 'background-color: #e2e4e7'">
                <v-expansion-panel-header expand-icon="mdi-menu-down"
                    class="d-flex flex-row-start text-color-main fw-bold text-h6">
                    <label class="text-color-main">
                        <v-icon>mdi-filter-menu</v-icon> Filtre de recherche
                    </label>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                    <div class=" container rounded-xl">
                        <v-radio-group v-model="displayType" row class="">
                            <v-radio color="#2596be" class="" :value="1">
                                <template slot="label">
                                    Afficher
                                    <b class="text-color-main">&nbsp;tous les {{ type }}</b>
                                </template>
                            </v-radio>
                            <v-radio class="" :value="2" color="#2596be"><template slot="label">
                                    Afficher les
                                    <b class="text-color-main">&nbsp;les {{ type }} reçus</b>
                                    <span class="badge text-dark bg-warning text-right mb-2 m-1">REÇU</span>
                                </template></v-radio>
                            <v-radio class="" color="#2596be" :value="3"><template slot="label">
                                    Afficher les
                                    <b class="text-color-main">&nbsp;{{ type }} créés</b>
                                    <span class="badge bg-primary text-right mb-2 m-1">CRÉER</span>
                                </template></v-radio>
                        </v-radio-group>
                        <v-radio-group v-model="displayVisibility" row class="">
                            <v-radio color="#2596be" class="" :value="3">
                                <template slot="label">
                                    Afficher
                                    <b class="text-color-main">&nbsp;tous les {{ type }}</b>
                                </template>
                            </v-radio>
                            <v-radio color="#2596be" class="" :value="1">
                                <template slot="label">
                                    Afficher
                                    <b class="text-color-main">&nbsp;les {{ type }}</b><span
                                        class="badge purple text-right mb-2 m-1">PRIVÉ</span>
                                </template>
                            </v-radio>
                            <v-radio class="" :value="0" color="#2596be"><template slot="label">
                                    Afficher
                                    <b class="text-color-main">&nbsp;les {{ type }}</b>
                                    <span class="badge teal darken-1 text-right mb-2 m-1">PUBLIC</span>
                                </template></v-radio>
                        </v-radio-group>
                        <v-select v-model="categorieFilter" placeholder="Categories"
                            :items="categories" item-text="nom" rounded item-value="id" multiple filled>
                            <template v-slot:prepend-item>
                                <v-list-item ripple @mousedown.prevent @click="toggle">
                                    <v-list-item-action>
                                        <v-icon :color="
                                            categorieFilter.length > 0 ? 'indigo darken-4' : ''
                                        ">
                                            {{ icon }}
                                        </v-icon>
                                    </v-list-item-action>
                                    <v-list-item-content>
                                        <v-list-item-title> Tous </v-list-item-title>
                                    </v-list-item-content>
                                </v-list-item>
                                <v-divider class="mt-2"></v-divider>
                            </template>
                            <template v-slot:selection="{ item, index }">
                                <span v-if="index === chipResponsive" class="grey--text text-caption">
                                    (+{{ categorieFilter.length - chipResponsive }}
                                    autres)
                                </span>
                                <v-chip class="bg-warning" v-if="index < chipResponsive">
                                    <span>{{ item.nom }}</span>
                                </v-chip>
                            </template>
                        </v-select>
                        <div>
                            <div class="mt-2 mb-5 text-muted" v-if="displayType != 3 && type == 'tutos'">
                                <v-switch v-model="favoris" color="warning">
                                    <template v-slot:label>
                                        N'afficher que&nbsp;
                                        <b class="text-color-main">les favoris</b>
                                        <v-icon dark class="text-warning rounded-circle fs-1 p-1">
                                            mdi-star
                                        </v-icon>
                                    </template>
                                </v-switch>
                            </div>
                        </div>
                    </div>
                </v-expansion-panel-content>
            </v-expansion-panel>
        </v-expansion-panels>
    </div>
</template>

<script>
export default {
    props: ["type", "table", "categories", "id", 'filterCategorieFunction', 'filterFavsFunction'],
    data() {
        return {
            favoris: false,
            categorieFilter: [],
            menu: false,
            displayType: 1,
            displayVisibility: 3,
            limit: 10,
        };
    },
    computed: {
        chipResponsive() {
            if (window.innerWidth < 800) {
                return 3;
            } else {
                return 8;
            }
        },
        selectedSomeCategorie() {
            return this.categorieFilter.length > 0 && !this.selectAllCategorie;
        },
        selectAllCategorie() {
            return this.categorieFilter.length === this.categories.length;
        },
        icon() {
            if (this.selectAllCategorie) return "mdi-close-box";
            if (this.selectedSomeCategorie) return "mdi-minus-box";
            return "mdi-checkbox-blank-outline";
        },
        heightResponsive() {
            if (window.innerWidth < 800) {
                return "";
            } else {
                return 300;
            }
        },
        colsResponsive() {
            if (window.innerWidth < 800) {
                return 12;
            } else {
                return 3;
            }
        },
    },
    watch: {
        favoris(val) {
            var params = null;
            if (this.table == 'folder') {
                if (val) {
                    params = 1;
                    this.$axios.get("/" + this.table + "/" + this.filterFavsFunction + "/" + params + "/" + this.id).then((response) => {
                        this.$emit("newItems", response.data);
                    });
                } else {
                    params = 0;
                    this.$axios.get("/" + this.table + "/" + this.filterFavsFunction + "/" + params + "/" + this.id).then((response) => {
                        this.$emit("newItems", response.data);
                    });
                }
            } else {
                if (val) {
                    params = 1;
                    this.$axios.get("/" + this.table + "/" + this.filterFavsFunction + "/" + params).then((response) => {
                        this.$emit("newItems", response.data);
                    });
                } else {
                    params = 0;
                    this.$axios.get("/" + this.table + "/" + this.filterFavsFunction + "/" + params).then((response) => {
                        this.$emit("newItems", response.data);
                    });
                }
            }



        },
        displayType(val) {
            this.loadTuto = true;
            var payload = {
                displayType: val,
                displayVisibility: this.displayVisibility
            }
            this.$emit("reloadItems", payload);
        },
        displayVisibility(val) {
            this.loadTuto = true;
            var payload = {
                displayType: this.displayType,
                displayVisibility: val
            }
            this.$emit("reloadItems", payload);
        },
        categorieFilter(val) {
            if (val) {
                if (!this.selectAllCategorie && val.length > 0) {
                    this.$axios
                        .post("/" + this.table + "/" + this.filterCategorieFunction + "/" + this.displayType, {
                            categories: val,
                            idFolder: this.id
                        })
                        .then((response) => {
                            if(this.table == 'tutos') {
                                this.$emit("newItems", response.data.tutos);
                            } else {
                                this.$emit("newItems", response.data.folders);
                            }

                        });
                } else {
                    this.$emit('resetItem')
                }
            } else {
                this.$emit('resetItem')
            }
        },
    },
    methods: {
        toggle() {
            this.$nextTick(() => {
                if (this.selectAllCategorie) {
                    this.categorieFilter = [];
                } else {
                    this.categorieFilter = this.categories;
                    this.$emit('resetItem')
                }
            });
        },
    }
};
</script>

<style>

</style>