<template>
  <div>
    <v-form v-model="valid" style="margin-bottom: 100px">
      <p class="fw-light text-center">Visibilité du dossier :</p>
      <div class="mb-5 d-flex justify-content-center">

        <v-btn class="bg-color-main m-1" :loading="loader" :style="borderChoosenPrivate"
          @click="openDialogIsPrivate(1)">
          <v-icon>mdi-lock</v-icon> Privé
        </v-btn>
        <v-btn class="bg-color-main m-1" :loading="loader" :style="borderChoosenPublic" @click="openDialogIsPrivate(0)">
          <v-icon>mdi-lock-open</v-icon> Public
        </v-btn>
      </div>

      <v-text-field filled rounded v-model="editedFolder.titre" :rules="requiredRules" :loading="loader" :counter="40"
        :maxlength="40" label="Nom du dossier"></v-text-field>
      <v-textarea filled rounded prepend-inner-icon="mdi-image-text" label="Description" :loading="loader"
        :counter="200" :maxlength="200" v-model="editedFolder.description"></v-textarea>
      <label class="text-danger fst-italic">Appuyer sur votre touche "Entrée" pour enregistrer le mot clé</label>
      <v-combobox v-model="editedFolder.mot_clefs" :items="[]" prepend-inner-icon="mdi-keyboard-variant"
        :rules="requiredRules" filled rounded chips label="Ecrivez des mots clés" multiple :counter="8" required>
        <template v-slot:selection="{ attrs, item, selected }">
          <v-chip v-bind="attrs" class="bg-color-main rounded-xl" rounded :input-value="selected" label>
            <span class="pr-2">
              {{ item }}
            </span>
          </v-chip>
        </template>
      </v-combobox>
      <v-select label="Catégories du dossier" v-model="editedFolder.categories" :items="categories" item-text="nom"
        item-value="id" filled :loading="loader" rounded multiple>
        <template v-slot:selection="{ item }">
          <v-chip class="bg-warning text-dark">
            <span>{{ item.nom }}</span>
          </v-chip>
        </template>
      </v-select>
      <v-alert type="info" v-if="!editedFolder.isPrivate">Vous ne pourrez mettre que des tutos publics à l'interieur de
        ce dossier</v-alert>
      <v-list dense class="rounded-xl transparent">
        <v-subheader>Listes des derniers tutos créer</v-subheader>
        <v-text-field :loading="loader" v-model="search" placeholder="Rechercher un tuto dans votre compte" filled
          rounded></v-text-field>
        <v-select :loading="loader" rounded v-model="editedFolder.arrayTutos" :items="listTutos" filled chips
          item-value="id" item-text="titre" label="Tutos à l'interieur du dossier" multiple> <template
            #selection="{ item }">
            <v-chip class="text-white bg-color-main">{{ item.titre }}</v-chip>
          </template></v-select>
        <div v-show="search.length > 0">
          <v-list-item-group>
            <v-list-item v-for="(item, index) in listSearch" @click="selectTuto(item)" :key="index" :value="item.id">
              <template>
                <v-list-item-content>
                  <v-list-item-title v-text="item.titre"></v-list-item-title>
                </v-list-item-content>
              </template>
            </v-list-item>
          </v-list-item-group>
        </div>

      </v-list>
      <div class="d-flex justify-content-end mt-2 mb-5">
        <v-btn rounded large class="bg-color-main text-white m-2 mb-5" :disabled="!valid" v-if="editedFolder.id"
          @click="updateFolder()">
          <v-icon>mdi-content-save-edit</v-icon>Modifier
        </v-btn>
        <v-btn class="bg-color-main text-white m-2 mb-5" :disabled="!valid" rounded large v-else
          @click="createFolder()">
          <v-icon>mdi-content-save</v-icon>Enregistrer
        </v-btn>
      </div>
    </v-form>
    <dialog-confirm :titre="arrayDialog.titre" :message="arrayDialog.message"
      :functionCalled="arrayDialog.functionCalled" type="formFolder"></dialog-confirm>
  </div>
</template>

<script>
import DialogConfirm from '@/components/modal/DialogConfirm.vue';
import { bus } from "@/main";
export default {
  components: { DialogConfirm },
  props: ["editedFolder", "idFolder"],
  data() {
    return {
      folders: [],
      dialogFolder: false,
      valid: true,
      active: [],
      busy: false,
      borderChoosenPrivate: "",
      borderChoosenPublic: "",
      btnLoadFolders: false,
      items: [
        {
          text: "Ma lists",
          icon: "mdi-plus",
        },
        {
          text: "Ma lists",
          icon: "mdi-plus",
        },
        {
          text: "Ma lists",
          icon: "mdi-plus",
        },
      ],
      selectedItem: [],
      titleModalFolder: "Créer un dossier",
      requiredRules: [(v) => !!v || "Le champ est obligatoire"],
      displayTypeFolder: 1,
      listTutos: [],
      foldersMax: null,
      categories: [],
      loader: true,
      folderFilter: [],
      limitFolders: 10,
      listSearch: [],
      search: "",
      numberRules: [
        (v) => !!v || "Le champ est obligatoire",
        (v) => !isNaN(v) || "Ce n'est pas un nombre",
      ],
      emailRules: [
        (v) => !!v || "Le champ doit être rempli",
        (v) => /.+@.+\..+/.test(v) || "L'adresse email est invalide",
      ],
      passwordConfirm: [
        (v) =>
          this.validatePassword(v) || "Les mots de passes ne concorde pas !",
      ],
      rules: {
        requiredRules: (v) => !!v || "Le champ est obligatoire",
        minRules: (v) =>
          (v && v.length >= 6) ||
          "Le mot de passe doit faire au minimum 6 caractères",
      },
      numberRulesNoObligatory: [(v) => !isNaN(v) || "Ce n'est pas un nombre"],
      valueIsPrivate: "",
      arrayDialog: {
        titre: "",
        message: "",
        functionCalled: "",
      }
    };
  },
   mounted() {
    this.listTutosFolder()
    this.$store.dispatch("categories/setCategories");
    if (this.$store.getters["categories/getCategories"].length == 0) {
      var refreshIntervalId = setInterval(() => {
        if (this.$store.getters["categories/dataLoad"]) {
          this.categories = this.$store.getters["categories/getCategories"];
          clearInterval(refreshIntervalId);
        }
      }, 1000);
    }
    else {
      this.categories = this.$store.getters["categories/getCategories"];
    }
  },
  computed: {
    isPrivate() {
      return this.editedFolder.isPrivate
    },
    motClefs() {
      return this.editedFolder.mot_clefs
    },
  },
  watch: {
    motClefs(val) {
      if (val.length > 8) {
        this.$nextTick(() => this.editedFolder.mot_clefs.pop())
      }
    },
    isPrivate(val) {
      var payload = {
        isPrivate: val
      }
      this.$axios.post("/folder/listTutosFolder", payload).then((response) => {
        this.listTutos = response.data;
        this.listSearch = response.data
        if (val) {
          this.borderChoosenPrivate = "border:3px solid red; "
          this.borderChoosenPublic = ""
        } else {
          this.borderChoosenPublic = "border: 3px solid red;"
          this.borderChoosenPrivate = ""
        }
      });
    },
    search(val) {
      var payload = {
        isPrivate: this.editedFolder.isPrivate
      }
      if (val.length > 2) {
        this.$axios.post('folder/tutoSearch/' + val, payload).then((response) => {
          this.listSearch = response.data

        })
      } else if (val.length == 0) {
        this.listSearch = this.listTutos
      }
    }
  },
  methods: {
    verifForm() {
      if (this.editedFolder.titre == '' || this.editedFolder.arrayTutos.length == 0 || this.editedFolder.mot_clefs == '' || this.editedFolder.categories.length == 0) {
        return false
      } else {
        return true
      }
    },
    openDialogIsPrivate(isPrivate) {
      this.valueIsPrivate = isPrivate
      if (this.valueIsPrivate == this.editedFolder.isPrivate) {
        return false
      }
      this.arrayDialog['message'] = "Voulez-vous changer la visibilité de votre dossier ? Les tutos que vous avez sélectionnés dans votre dossier disparaîtrons."
      this.arrayDialog['titre'] = "Que voulez-vous faire ?"
      this.arrayDialog['functionCalled'] = "chooseIsPrivate"
      this.$store.dispatch('modal/setOpen', 'formFolder')
    },
    chooseIsPrivate() {
      this.editedFolder.isPrivate = this.valueIsPrivate
      if (this.valueIsPrivate) {
        this.borderChoosenPrivate = "border:3px solid red; "
        this.borderChoosenPublic = ""
        this.editedFolder.arrayTutos = []
      } else {
        this.borderChoosenPublic = "border: 3px solid red;"
        this.borderChoosenPrivate = ""
        this.editedFolder.arrayTutos = []
      }
      this.$store.dispatch('modal/setClose', 'formFolder')
    },
    listTutosFolder() {
      var payload = {
        isPrivate: this.editedFolder.isPrivate
      }
      this.$axios.post("/folder/listTutosFolder", payload).then((response) => {
        this.listTutos = response.data;
        this.listSearch = response.data
        if (this.editedFolder.isPrivate) {
          this.borderChoosenPrivate = "border:3px solid red; "
          this.borderChoosenPublic = ""
        } else {
          this.borderChoosenPublic = "border: 3px solid red;"
          this.borderChoosenPrivate = ""
        }
        this.loader = false
      });
    },
    selectTuto(tuto) {
      var i = this.editedFolder.arrayTutos.indexOf(tuto.id);
      if (i === -1)
        this.editedFolder.arrayTutos.push(tuto.id);
      else
        this.editedFolder.arrayTutos.splice(i, 1);
    },
    createFolder() {
      if (this.editedFolder.arrayTutos.length == 0) {
        this.$swal({
          toast: true,
          title: "Votre dossier doit contenir au moins 1 tutos !",
          icon: "warning",
          animation: true,
          position: "center",
          showConfirmButton: false,
          timerProgressBar: true,
          timer: 2000,
        });
        return;
      } else if (this.editedFolder.categories.length == 0) {
        this.$swal({
          toast: true,
          title: "Votre dossier doit contenir au moins 1 catégorie !",
          icon: "warning",
          animation: true,
          position: "center",
          showConfirmButton: false,
          timerProgressBar: true,
          timer: 2000,
        });
        return;
      } else {
        if (this.verifForm()) {
          var payload = {
            titre: this.editedFolder.titre,
            isPrivate: this.editedFolder.isPrivate,
            categories: this.editedFolder.categories,
            description: this.editedFolder.description,
            mot_clefs: this.editedFolder.mot_clefs,
            arrayTutos: this.editedFolder.arrayTutos,
          };
          this.$axios.post("/folder/create", payload).then((response) => {
            if (response.data.messageError) {
              this.$swal("Oups !", response.data.messageError, "error");
              return;
            }
            this.$swal({
              toast: true,
              title: response.data.message,
              icon: "success",
              animation: true,
              position: "top-right",
              showConfirmButton: false,
              timerProgressBar: true,
              timer: 2000,
            });

            bus.$emit("getFolders");
            this.$router.push({ name: "Dossiers" });
          });
        } else {
          this.$swal({
            toast: true,
            title: "Votre dossier doit contenir au moins 1 tutos, 1 catégorie et les champs doivent être remplis !",
            icon: "warning",
            animation: true,
            position: "center",
            showConfirmButton: false,
            timerProgressBar: true,
            timer: 2000,
          });
          return;
        }
      }
    },
    updateFolder() {
      if (this.editedFolder.arrayTutos.length < 0) {
        this.$swal({
          toast: true,
          title: "Votre dossier doit contenir au moins 1 tutos !",
          icon: "warning",
          animation: true,
          position: "center",
          showConfirmButton: false,
          timerProgressBar: true,
          timer: 2000,
        });
      } else {
        if (this.verifForm()) {
          var payload = {
            titre: this.editedFolder.titre,
            isPrivate: this.editedFolder.isPrivate,
            categories: this.editedFolder.categories,
            description: this.editedFolder.description,
            mot_clefs: this.editedFolder.mot_clefs,
            arrayTutos: this.editedFolder.arrayTutos,
          };
          this.$axios
            .put("folder/edit/" + this.editedFolder.id, payload)
            .then((response) => {
              if (response.data.messageError) {
                this.$swal(
                  "Une erreur est survenue !",
                  response.data.messageError,
                  "error"
                );
                return;
              }
              this.$swal({
                toast: true,
                title: response.data.message,
                icon: "success",
                animation: true,
                position: "top-right",
                showConfirmButton: false,
                timerProgressBar: true,
                timer: 2000,
              });
              bus.$emit("getFolders");
              this.$router.push({ name: "Dossiers" });
            });
        } else {
          this.$swal({
            toast: true,
            title: "Votre dossier doit contenir au moins 1 tutos, et les champs doivent être remplis !",
            icon: "warning",
            animation: true,
            position: "center",
            showConfirmButton: false,
            timerProgressBar: true,
            timer: 2000,
          });
          return;
        }

      }
    },
  },
};
</script>

<style>

</style>