<template>
  <div>
    <v-container class="mt-10">
      <v-card-title>Créer un nouveau dossier</v-card-title>
      <FormFolder :editedFolder="editedFolder"></FormFolder>
    </v-container>
  </div>
</template>

<script>
import FormFolder from "@/components/folders/FormFolder.vue";
export default {
  metaInfo() {
    return {
      title: "Créer un nouveau dossier | Pocketuto ",
      meta: [
        { vmid: 'description', name: 'description', content: "Création d'un nouveau dossier" }
      ]
    }
  },
  components: {
    FormFolder,
  },
  data() {
    return {
      editedFolder: {
        id: "",
        titre: "",
        categories: [],
        arrayTutos: [],
        description: "",
        mot_clefs: "",
        isPrivate: 1,
      },
    };
  },
};
</script>

<style>

</style>