<template>
    <div>
        <v-dialog height="500" width="500" v-model="menu" v-if="isNotPageAccueil" class="rounded-xl"
            :close-on-content-click="false" :nudge-width="200" offset-x>
            <template v-slot:activator="{ on, attrs }">
                <v-btn icon dark v-bind="attrs" v-on="on">
                    <div>
                        <v-icon dark>mdi-magnify</v-icon>
                    </div>
                </v-btn>
            </template>

            <v-card class="rounded-xl" height="500" width="500" style="overflow-x: auto">
                <v-toolbar class="bg-color-main" dark>
                    <v-toolbar-title>Recherche</v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-btn @click="menu = false" icon>
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                </v-toolbar>

                <div class="container">
                    <div class="container">
                        <p class="fw-bold">
                            Taper des mots clés afin de trouver le tuto ou le dossier que vous
                            voulez
                        </p>
                        <v-text-field filled clearable v-on:keydown.enter.prevent="recherche()" v-model="search" rounded
                            prepend-inner-icon="mdi-magnify" placeholder="Comment faire .... ?" :label="labelPerso">
                        </v-text-field>
                        <div v-if="overlay" class="container d-flex justify-content-center mt-5">
                            <div class="text-center">
                                <v-progress-circular :size="30" :width="7" color="#2596be" indeterminate>
                                </v-progress-circular>
                                <p class="text-center text-color-main fw-bold fs-5">
                                    Chargement des mots clés
                                </p>
                            </div>
                        </div>
                        <div class="rounded-xl" v-else>
                            <v-card-title v-if="keywords.length > 0" class="text-center lead fs-6">Exemple de mots clés
                            </v-card-title>

                            <v-chip v-for="(keyword, i) in keywords" @click="searchByKeyWords(keyword)" :key="i"
                                class="mr-2 mt-2 bg-color-main">
                                {{ keyword }}
                            </v-chip>
                        </div>
                    </div>
                    <div class="container mb-5">
                        <div class="d-flex justify-content-center">
                            <v-btn class="bg-color-main text-white m-3" block rounded @click="recherche()"
                                :disabled="disableBtnSearch">
                                <v-icon>mdi-magnify</v-icon>Rechercher
                            </v-btn>
                        </div>
                    </div>

                    <div class="mt-5 rounded-xl" v-if="elements.length != 0">
                        <v-card-title class="pb-4 text-center text-break transparent">
                            Résultat de la recherche ..
                        </v-card-title>
                    </div>
                    <div class="pb-5 mt-5">
                        <v-row v-if="loader">
                            <v-col :cols="colsResponsive">
                                <v-skeleton-loader class="mx-auto my-3 rounded-xl"
                                    type="card, card, card, card, card, card, card, card">
                                </v-skeleton-loader>
                            </v-col>
                            <v-col :cols="colsResponsive">
                                <v-skeleton-loader class="mx-auto my-3 rounded-xl"
                                    type="card, card, card, card, card, card, card, card">
                                </v-skeleton-loader>
                            </v-col>
                            <v-col :cols="colsResponsive">
                                <v-skeleton-loader class="mx-auto my-3 rounded-xl"
                                    type="card, card, card, card, card, card, card, card">
                                </v-skeleton-loader>
                            </v-col>
                            <v-col :cols="colsResponsive">
                                <v-skeleton-loader class="mx-auto my-3 rounded-xl"
                                    type="card, card, card, card, card, card, card, card">
                                </v-skeleton-loader>
                            </v-col>
                        </v-row>

                        <div v-else-if="elements.length > 0" style="overflow-x: auto" class="rounded-xl">
                            <v-list three-line>
                                <v-list-item @click="openTuto(element)" v-for="(element, index) in elements"
                                    v-bind:key="index">
                                    <v-list-item-avatar v-if="element.type == 'Tuto'">
                                        <v-img style="width: 30px !important" :src="element.image_couverture">
                                        </v-img>
                                    </v-list-item-avatar>
                                    <v-list-item-avatar v-else class="bg-color-main">
                                        <v-icon dark>mdi-folder</v-icon>
                                    </v-list-item-avatar>
                                    <v-list-item-content>
                                        <v-list-item-title class="text-break fw-bold fs-4">
                                            <strong>{{ element.titre }}</strong>
                                        </v-list-item-title>
                                        <v-list-item-subtitle class="text-break">
                                            <strong>{{ element.description }}</strong>
                                        </v-list-item-subtitle>

                                        <div class="mt-3">
                                            <v-chip style="background-color: #2596be" class="text-white"
                                                v-for="mot_clef in element.mot_clefs" v-bind:key="mot_clef">
                                                {{ mot_clef }}
                                            </v-chip>
                                            <hr />
                                            <v-chip class="text-dark bg-warning" v-for="categorie in element.categories"
                                                v-bind:key="categorie.id">
                                                {{ categorie.nom }}
                                            </v-chip>
                                        </div>
                                        <div v-if="element.type == 'Dossier'">
                                            <p>
                                                Nombre de tutos dans ce dossier :
                                                <strong>{{ element.nbTutos }}</strong>
                                            </p>
                                        </div>
                                    </v-list-item-content>
                                    <hr />
                                </v-list-item>
                            </v-list>
                        </div>
                    </div>
                </div>
            </v-card>
        </v-dialog>
        <div v-else class="rounded-xl">
            <div class="rounded-xl mt-5" :height="!isNotPageAccueil ? 'auto' : 500" style="overflow-x: auto">
                <div class="container">
                    <div class="container">
                        <p class="fw-bold">Rechercher un tuto ou un dossier</p>
                        <v-text-field filled clearable v-on:keydown.enter.prevent="recherche()" v-model="search" rounded
                            prepend-inner-icon="mdi-magnify" placeholder="Comment utiliser Pocketuto ?"
                            :label="labelPerso">
                        </v-text-field>
                        <div v-if="overlay" class="container d-flex justify-content-center mt-5">
                            <div class="text-center">
                                <v-progress-circular :size="30" :width="7" color="#2596be" indeterminate>
                                </v-progress-circular>
                                <p class="text-center text-color-main fw-bold fs-5">
                                    Chargement des mots clés
                                </p>
                            </div>
                        </div>
                        <div class="rounded-xl" v-else>
                            <v-card-title v-if="keywords.length > 0" class="text-center lead fs-6">Exemple de mots clés
                            </v-card-title>

                            <v-chip v-for="(keyword, i) in keywords" @click="searchByKeyWords(keyword)" :key="i"
                                class="mr-2 mt-2 bg-color-main">
                                {{ keyword }}
                            </v-chip>
                        </div>
                    </div>
                    <div class="container mb-5">
                        <div class="d-flex justify-content-center">
                            <v-btn class="bg-color-main text-white m-3" block rounded @click="recherche()"
                                :disabled="disableBtnSearch">
                                <v-icon>mdi-magnify</v-icon>Rechercher
                            </v-btn>
                        </div>
                    </div>

                    <div class="mt-5 rounded-xl" v-if="elements.length != 0">
                        <v-card-title class="pb-4 text-center text-break transparent">
                            Résultat de la recherche ..
                        </v-card-title>
                    </div>
                    <div class="pb-5 mt-5">
                        <v-row v-if="loader">
                            <v-col :cols="colsResponsive">
                                <v-skeleton-loader class="mx-auto my-3 rounded-xl"
                                    type="card, card, card, card, card, card, card, card">
                                </v-skeleton-loader>
                            </v-col>
                            <v-col :cols="colsResponsive">
                                <v-skeleton-loader class="mx-auto my-3 rounded-xl"
                                    type="card, card, card, card, card, card, card, card">
                                </v-skeleton-loader>
                            </v-col>
                            <v-col :cols="colsResponsive">
                                <v-skeleton-loader class="mx-auto my-3 rounded-xl"
                                    type="card, card, card, card, card, card, card, card">
                                </v-skeleton-loader>
                            </v-col>
                            <v-col :cols="colsResponsive">
                                <v-skeleton-loader class="mx-auto my-3 rounded-xl"
                                    type="card, card, card, card, card, card, card, card">
                                </v-skeleton-loader>
                            </v-col>
                        </v-row>

                        <div v-else-if="elements.length > 0" style="overflow-x: auto" class="rounded-xl">
                            <v-list three-line>
                                <v-list-item @click="openTuto(element)" v-for="(element, index) in elements"
                                    v-bind:key="index">
                                    <v-list-item-avatar v-if="element.type == 'Tuto'">
                                        <v-img style="width: 30px !important" :src="element.image_couverture">
                                        </v-img>
                                    </v-list-item-avatar>
                                    <v-list-item-avatar v-else>
                                        <v-icon>mdi-folder</v-icon>
                                    </v-list-item-avatar>
                                    <v-list-item-content>
                                        <v-list-item-title class="text-break fw-bold fs-4">
                                            {{ element.titre }}
                                        </v-list-item-title>
                                        <v-list-item-subtitle class="text-break">
                                            <strong>{{ element.description }}</strong>
                                        </v-list-item-subtitle>
                                        <v-list-item-subtitle v-if="element.type == 'Dossier'"
                                            class="text-break text-dark">
                                            Nombre de tutos dans ce dossier :
                                            <strong>{{ element.nbTutos }}</strong>
                                        </v-list-item-subtitle>
                                        <div class="mt-3">
                                            <v-chip style="background-color: #2596be" class="text-white"
                                                v-for="mot_clef in element.mot_clefs" v-bind:key="mot_clef">
                                                {{ mot_clef }}
                                            </v-chip>
                                            <hr />
                                            <v-chip class="text-dark bg-warning" v-for="categorie in element.categories"
                                                v-bind:key="categorie.id">
                                                {{ categorie.nom }}
                                            </v-chip>
                                        </div>
                                    </v-list-item-content>
                                    <hr />
                                </v-list-item>
                            </v-list>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
  
<script>
export default {
    props: ["isNotPageAccueil"],
    data() {
        return {
            menu: false,
            search: "",
            typeSearch: "",
            disableBtnSearch: false,
            editedItem: [],
            btnLoadPDF: null,
            keywords: [],
            loader: false,
            user: [],
            dialogTuto: false,
            overlay: false,
            elements: [],
            categories: [],
            labelPerso: "Rechercher un dossier ou un tuto ...",
        };
    },
    computed: {
        chipResponsive() {
            if (window.innerWidth < 800) {
                return 3;
            } else {
                return 8;
            }
        },
        colsResponsive() {
            if (window.innerWidth < 800) {
                return 12;
            } else {
                return 3;
            }
        },
    },
    mounted() {
        this.overlay = true;
        this.getKeywords();
        var refreshIntervalId2 = setInterval(() => {
            if (this.$store.getters["auth/dataLoad"]) {
                this.user = this.$store.getters["auth/user"];
                this.overlay = false;
                clearInterval(refreshIntervalId2);
            }
        }, 1000);
    },
    methods: {
        getKeywords() {
            if (this.user) {
                this.$axios.get("/discover/keywords").then((response) => {
                    this.keywords = response.data;
                    this.overlay = false;
                });
            } else {
                this.overlay = false;
            }
        },
        searchByKeyWords(val) {
            this.search = val;
            this.searchTuto();
        },
        recherche() {
            if (
                this.search == "" ||
                this.search == null ||
                !this.search.replace(/\s/g, "").length
            ) {
                this.$swal({
                    toast: true,
                    title: "Veuillez écrire quelque chose avant de rechercher !",
                    icon: "warning",
                    animation: true,
                    position: "center",
                    showConfirmButton: false,
                    timerProgressBar: true,
                    timer: 2000,
                });
                this.search = "";
            } else {
                this.searchTuto();
            }
        },
        searchTuto() {
            this.loader = true;
            this.$axios
                .get("/discover/rechercheAll/" + this.search)
                .then((response) => {
                    if (response.data.messageError) {
                        this.$swal({
                            toast: true,
                            title: response.data.messageError,
                            icon: "error",
                            animation: true,
                            position: "center",
                            showConfirmButton: false,
                            timerProgressBar: true,
                            timer: 2000,
                        });
                        this.loader = false;
                        return;
                    }
                    this.elements = response.data;
                    this.loader = false;
                });
        },
        openTuto(element) {
            if (element.type == "Tuto") {
                this.$router
                    .push({
                        name: "Contenu du tuto",
                        params: {
                            code_tuto: element.code,
                            slug: this.slugify(element.titre, "-"),
                        },
                    })
                    .then(() => {
                        document.location.reload();
                    });
            } else {
                this.$router
                    .push({
                        name: "Contenu du dossier",
                        params: {
                            code_dossier: element.code,
                            slug: this.slugify(element.titre, "-"),
                        },
                    })
                    .then(() => {
                        document.location.reload();
                    });
            }
            this.menu = false;
        },
    },
};
</script>