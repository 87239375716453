<template>
  <div id="content-tuto">
    <v-card style="padding-bottom: 500px">
      <v-toolbar
        :class="isDarkMode ? 'bg-dark-vuetify' : 'bg-white'"
        class="pt-5 sticky elevation-0 mb-5"
      >
        <v-spacer></v-spacer>
        <v-toolbar-items>
          <v-btn
            @click="addComment()"
            v-if="editedItem.commentsActive && user"
            class="bg-color-main"
            large
          >
            <v-badge
              v-if="editedItem.nbComments"
              color="black"
              :content="editedItem.nbComments"
            >
              <v-icon>mdi-comment-multiple</v-icon>
            </v-badge>
            <v-icon v-else>mdi-comment-multiple</v-icon>
          </v-btn>
          <v-btn
            large
            @click="
              copyLink(
                'https://pocketuto.fr/tuto/',
                editedItem.code_tuto,
                slugify(editedItem.titre, '-'),
                'Le lien du tuto a bien été copié !'
              )
            "
            class="bg-color-main"
          >
            <v-icon>mdi-link</v-icon>
          </v-btn>
          <v-btn @click="likeTuto()" v-if="user" class="bg-color-main">
            <v-icon
              v-if="editedItem.isLikedByUser"
              class="text-danger"
              style="color: red"
              >mdi-thumb-up</v-icon
            >
            <v-icon class="text-white" v-else>mdi-thumb-up-outline</v-icon>
            {{ kFormatter(editedItem.likes) }}
          </v-btn>
        </v-toolbar-items>
        <v-toolbar-items v-if="user">
          <v-btn large @click="openActions = true" class="bg-color-main">
            <v-icon>mdi-dots-vertical</v-icon>
          </v-btn>
        </v-toolbar-items>
        <v-spacer></v-spacer>
      </v-toolbar>
      <v-skeleton-loader
        v-if="loader"
        type="table-heading, table-heading, list-item-three-line, list-item-three-line, list-item-three-line,  divider, list-item-three-line, list-item-three-line,   divider, list-item-three-line"
      >
      </v-skeleton-loader>
      <div v-else class="container-xxl">
        <v-list three-line subheader class="pt-3">
          <h3
            v-if="!editedItem.isOwn"
            class="text-center mt-4"
            style="font-size: 15px"
          >
            Vous avez les droits
            <strong>{{
              editedItem.hasRight ? "Admin" : "de lecture seule"
            }}</strong>
            sur ce tuto
          </h3>
          <hr />
          <v-list-item>
            <v-list-item-content>
              <v-card-title>
                <i>Informations</i>
                <v-spacer></v-spacer>
                <v-btn
                  v-if="
                    !editModeTuto &&
                    user &&
                    (editedItem.isOwn ||
                      (!editedItem.isOwn && editedItem.hasRight))
                  "
                  icon
                  fab
                  class="text-color-main"
                  @click="editInfoTuto()"
                >
                  <v-icon large>mdi-pencil</v-icon>
                </v-btn>
              </v-card-title>

              <v-img
                max-height="700"
                max-width="700"
                class="mx-auto rounded-xl elevation-4"
                :src="editedItem.image_couverture"
              >
              </v-img>

              <v-list-item-subtitle class="text-center my-5">
                <span
                  class="badge purple text-right mb-2 m-1 fs-6"
                  v-if="editedItem.isPrivate"
                  >PRIVÉ</span
                >
                <span
                  class="badge teal darken-1 text-right mb-2 m-1 fs-6"
                  v-else
                  >PUBLIC</span
                >
                <span
                  class="badge bg-primary text-right mb-2 m-1 fs-6"
                  v-if="editedItem.isOwn"
                  >CRÉER</span
                >
                <span
                  class="badge bg-warning text-right mb-2 m-1 fs-6"
                  v-else-if="editedItem.isReceive"
                  >REÇU</span
                >
              </v-list-item-subtitle>
              <v-list-item-title class="text-center fs-1 text-h4 text-break"
                ><strong>{{ editedItem.titre }}</strong>
              </v-list-item-title>

              <div class="mt-3 text-center">
                <div>
                  <v-chip
                    color="#2596be"
                    class="text-white m-1"
                    v-for="mot_clef in editedItem.mot_clefs"
                    v-bind:key="mot_clef"
                  >
                    {{ mot_clef }}
                  </v-chip>
                </div>
                <div>
                  <v-chip
                    class="text-dark bg-warning m-1"
                    v-for="categorie in editedItem.categories"
                    v-bind:key="categorie.id"
                  >
                    {{ categorie.nom }}
                  </v-chip>
                </div>
              </div>
            </v-list-item-content>
          </v-list-item>
        </v-list>
        <div class="text-center">
          <v-avatar class="mr-2">
            <v-img width="50" :src="editedItem.createur.photo"></v-img>
          </v-avatar>
          <strong>{{ editedItem.createur.pseudo }}</strong>
        </div>
        <div class="text-center p-1">
          <p class="pt-2">
            <span class="text-muted small">Date de création</span> <br />
            <strong>{{ editedItem.created_at }}</strong>
          </p>
          <p>
            <span class="text-muted small">Date de dernière modification</span>
            <br />
            <strong>{{ editedItem.updated_at }}</strong>
          </p>
        </div>
        <div class="text-center mb-5">
          <v-chip class="bg-danger text-white" v-if="editedItem.hasPassword">
            <v-icon class="text-white">mdi-key</v-icon> Mot de passe requis
          </v-chip>
        </div>
        <hr />
        <v-alert v-if="editModeTuto" border="bottom" color="pink darken-1" dark>
          <v-icon>mdi-information-variant</v-icon>Vous êtes en mode édition,
          n'oubliez pas de sauvegarder en cliquant sur le bouton
          <v-icon>mdi-content-save</v-icon> tous en haut.
        </v-alert>
        <div class="mt-5">
          <div class="container-xxl">
            <v-card-title
              ><i>Contenu</i>
              <v-spacer></v-spacer>
              <v-btn
                v-if="
                  !editModeTuto &&
                  user &&
                  (editedItem.isOwn ||
                    (!editedItem.isOwn && editedItem.hasRight))
                "
                icon
                fab
                class="text-color-main"
                @click="editContenuTuto()"
              >
                <v-icon large>mdi-pencil</v-icon>
              </v-btn>
              <v-btn
                v-else-if="
                  editModeTuto &&
                  user &&
                  (editedItem.isOwn ||
                    (!editedItem.isOwn && editedItem.hasRight))
                "
                fab
                icon
                class="text-color-main"
                @click="saveContentTuto()"
                :loading="loadBtnSave"
              >
                <v-icon large>mdi-content-save</v-icon>
              </v-btn>
            </v-card-title>
          </div>
        </div>
        <v-row>
          <div
            class="d-flex justify-content-center my-5"
            v-if="editedItem.isOwn"
          >
            <v-btn
              fab
              class="bg-white text-color-main"
              @click="openModalTextImage()"
              ><v-icon>mdi-image-text</v-icon></v-btn
            >
            &nbsp;
            <a @click="openModalTextImage()">
              <p
                class="fst-italic bg-color-main rounded-xl p-2 text-white"
                style="font-size: 15px"
              >
                Clique sur moi ! Je récupére le texte d'une image
              </p>
            </a>
          </div>
        </v-row>

        <v-alert
          border="left"
          class="text-break"
          colored-border
          :color="editModeTuto ? 'pink darken-1' : 'transparent'"
        >
          <editor
            ref="editor"
            :class="{ 'codex-editor--dark': isDarkMode }"
            :initialized="onInitialized"
            style="background-color: transparent"
            :config="config"
          />
        </v-alert>
      </div>
      <v-dialog
        v-model="displayFormComments"
        fullscreen
        persistent
        hide-overlay
        transition="scroll-x-reverse-transition"
      >
        <v-card style="padding-bottom: 500px">
          <v-toolbar class="sticky" style="z-index: 10" relative dense>
            <v-btn
              icon
              class="text-color-main sticky"
              @click="displayFormComments = false"
            >
              <v-icon style="font-size: 50px">mdi-arrow-left</v-icon>
            </v-btn>
            <v-spacer></v-spacer>
            <v-toolbar-title>Commentaires</v-toolbar-title>
          </v-toolbar>
          <ListeComments
            v-if="displayFormComments"
            :editedItem="editedItem"
            @refreshComments="refreshComments"
          >
          </ListeComments>
        </v-card>
      </v-dialog>
      <v-dialog
        v-model="displayLogs"
        fullscreen
        persistent
        hide-overlay
        transition="scroll-x-reverse-transition"
      >
        <v-card style="padding-bottom: 500px">
          <v-toolbar class="sticky" style="z-index: 10" dark relative dense>
            <v-btn
              icon
              class="text-color-main sticky"
              @click="displayLogs = false"
            >
              <v-icon style="font-size: 50px">mdi-arrow-left</v-icon>
            </v-btn>
            <v-toolbar-title>Logs</v-toolbar-title>
            <v-spacer></v-spacer>
          </v-toolbar>
          <TutoLogs v-if="displayLogs" :tuto="editedItem"></TutoLogs>
        </v-card>
      </v-dialog>
    </v-card>

    <v-bottom-sheet v-model="openActions">
      <v-list>
        <v-list-item
          class="d-flex justify-content-center"
          v-if="editedItem.isOwn"
        >
          <v-btn dark rounded class="bg-primary" @click="sendTuto()">
            <v-icon>mdi-send</v-icon> Envoyer le tuto
          </v-btn>
        </v-list-item>
        <v-list-item
          class="d-flex justify-content-center"
          v-if="editedItem.isReceive || editedItem.isOwn"
        >
          <v-btn
            rounded
            style="background-color: #f5d300"
            class="text-dark"
            @click="fav()"
          >
            <v-icon>
              {{ editedItem.favoris == 1 ? "mdi-star" : "mdi-star-outline" }}
            </v-icon>
            {{
              editedItem.favoris == 1
                ? "Retirer des favoris"
                : "Ajouter aux favoris"
            }}
          </v-btn>
        </v-list-item>
        <v-list-item class="d-flex justify-content-center">
          <v-btn
            style="background-color: #d8716e"
            :loading="btnLoadPDF"
            @click="generatePDF()"
            dark
            rounded
          >
            <v-icon>mdi-file-pdf-box</v-icon> Générer le PDF
          </v-btn>
        </v-list-item>
        <v-list-item
          v-if="editedItem.isOwn"
          class="d-flex justify-content-center"
        >
          <v-btn
            class="bg-danger"
            @click="deleteTuto()"
            dark
            v-if="editedItem.isOwn"
            rounded
          >
            <v-icon>mdi-delete</v-icon> Supprimer le tuto
          </v-btn>
        </v-list-item>
        <v-list-item
          class="d-flex justify-content-center"
          v-if="editedItem.isOwn"
        >
          <v-btn
            dark
            rounded
            style="background-color: #8742f5"
            v-if="editedItem.commentsActive"
            @click="toggleComments()"
          >
            <v-icon>mdi-comment-off</v-icon> Désactiver les commentaires
          </v-btn>
          <v-btn
            dark
            rounded
            style="background-color: #8742f5"
            v-else
            @click="toggleComments()"
          >
            <v-icon>mdi-comment</v-icon> Activer les commentaires
          </v-btn>
        </v-list-item>
        <v-list-item
          class="d-flex justify-content-center"
          v-if="editedItem.isOwn"
        >
          <v-btn
            rounded
            class="indigo darken-1 text-white"
            @click="displayLogs = true"
          >
            <v-icon>mdi-math-log </v-icon> Voir les logs
          </v-btn>
        </v-list-item>
        <v-list-item
          class="d-flex justify-content-center"
          v-if="editedItem.isOwn"
        >
          <v-btn
            dark
            rounded
            class="bg-light text-dark"
            v-if="editedItem.isOwn"
            @click="changeRightTuto()"
          >
            <v-icon>mdi-account-wrench</v-icon> Accès et droits
          </v-btn>
        </v-list-item>
        <v-list-item
          class="d-flex justify-content-center"
          v-if="!editedItem.isReceive && !editedItem.isOwn"
        >
          <v-btn rounded dark class="bg-primary" @click="saveMyTuto()">
            <v-icon>mdi-content-save</v-icon> Enregistrer le tuto dans mon
            compte
          </v-btn>
        </v-list-item>
        <v-list-item
          class="d-flex justify-content-center"
          v-if="editedItem.isReceive"
        >
          <v-btn rounded dark @click="shareTuto()">
            <v-icon>mdi-share-variant </v-icon> Partager le tuto
          </v-btn>
        </v-list-item>
        <v-list-item
          class="d-flex justify-content-center"
          v-if="editedItem.isReceive && !editedItem.isOwn"
        >
          <v-btn class="bg-danger" @click="deleteTuto()" dark rounded>
            <v-icon>mdi-delete</v-icon> Supprimer le tuto de mon compte
          </v-btn>
        </v-list-item>
      </v-list>
    </v-bottom-sheet>
    <v-dialog v-model="dialogRightsTuto" scrollable width="800">
      <v-card class="rounded-xl" width="800">
        <v-toolbar class="bg-color-main" dark>
          <v-btn @click="dialogRightsTuto = false" icon>
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-title class="mb-5 text-break">
          Droits et accès au tuto
        </v-card-title>
        <v-card-actions class="d-flex justify-content-center">
          <div>
            <v-btn
              class="bg-color-main m-1"
              rounded
              @click="showPasswordSection = !showPasswordSection"
              large
            >
              Définir/Redéfinir un <br />
              mot de passe pour le tuto</v-btn
            >
            <v-btn
              class="bg-danger m-1"
              @click="dialogDeletePassword()"
              rounded
              dark
              v-if="editedItem.hasPassword"
            >
              <v-icon>mdi-delete</v-icon>Supprimer le mot de passe
            </v-btn>
          </div>
        </v-card-actions>
        <v-card-text>
          <div v-if="showPasswordSection">
            <h3 class="text-break text-h5">Mot de passe</h3>
            <label class="m-1 text-primary"
              >Vous pouvez définir un mot de passe pour restreindre l'accès au
              tuto</label
            >
            <v-form v-model="valid">
              <v-text-field
                filled
                @click:append="show1 = !show1"
                :type="show1 ? 'text' : 'password'"
                :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                v-model="editedItem.password"
                :rules="requiredRules"
                rounded
                label="Choisissez un mot de passe"
              >
              </v-text-field>
              <v-text-field
                filled
                @click:append="show1 = !show1"
                :rules="[passwordMatch]"
                v-model="verify"
                :type="show1 ? 'text' : 'password'"
                :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                rounded
                label="Confimer le mot de passe"
              ></v-text-field>
              <div class="d-flex justify-content-end">
                <v-btn
                  class="bg-color-main"
                  @click="savePasswordTuto()"
                  :disabled="!valid"
                  rounded
                  dark
                >
                  <v-icon>mdi-content-save</v-icon>Enregistrer
                </v-btn>
              </div>
            </v-form>
          </div>
          <v-list v-if="editedItem.isOwn && editedItem.users.length > 0">
            <v-list-group v-for="user in editedItem.users" :key="user.id">
              <template v-slot:activator>
                <v-list-item-avatar>
                  <v-img :src="user.photo"></v-img>
                </v-list-item-avatar>
                <v-list-item-content>
                  <v-list-item-title v-text="user.pseudo"></v-list-item-title>
                </v-list-item-content>
              </template>

              <v-list-item>
                <v-list-item-content>
                  <v-select
                    solo
                    rounded
                    :items="rights"
                    item-text="text"
                    @change="saveRightsTutoUser(editedItem.users)"
                    item-value="value"
                    v-model="user.hasRight"
                  ></v-select>
                </v-list-item-content>

                <v-list-item-action>
                  <v-btn
                    rounded
                    class="mb-2 bg-danger"
                    style="font-size: 10px"
                    @click="dialogTransferOwnership(user.id)"
                  >
                    <v-icon>mdi-transit-transfer</v-icon>
                  </v-btn>
                  <v-btn
                    rounded
                    class="mb-5"
                    @click="dialogRemoveUser(user.id)"
                  >
                    <v-icon class="text-danger">mdi-delete</v-icon>
                  </v-btn>
                </v-list-item-action>
              </v-list-item>
            </v-list-group>
          </v-list>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialogPassword" persistent max-width="290">
      <v-card class="rounded-xl">
        <v-card-title class="text-h5"> Mot de passe requis </v-card-title>
        <v-card-text class="mt-3">
          <v-text-field
            prepend-inner-icon="mdi-account"
            rounded
            filled
            name="Mot de passe"
            v-model="editedItem.passwordRequire"
            label="Mot de passe"
            type="password"
            v-on:keydown.enter.prevent="getDetailsTuto()"
            :rules="requiredRules"
          ></v-text-field>
        </v-card-text>
        <v-card-actions>
          <v-btn
            class="text-color-main bg-white"
            rounded
            @click="$router.go(-1)"
          >
            <v-icon>mdi-arrow-left</v-icon> Retour
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            class="bg-color-main text-white"
            rounded
            @click="getDetailsTuto()"
          >
            <v-icon>mdi-send</v-icon> Valider
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialogTextImage" persistent width="500">
      <v-card class="rounded-xl">
        <v-toolbar>
          <v-icon @click="closeModalTextImage()">mdi-close</v-icon>
        </v-toolbar>
        <v-card-title class="text-h5"
          >Choisissez une image avec du texte</v-card-title
        >
        <v-card-text class="mt-3">
          Générer le texte à partir d'une image :
          <v-file-input
            label="Image vers le texte"
            rounded
            v-model="fileTextImage"
            accept="image/*"
            filled
            prepend-icon="mdi-camera"
          ></v-file-input>
        </v-card-text>
        <v-card-actions>
          <v-btn
            class="text-color-main bg-white"
            rounded
            @click="closeModalTextImage()"
          >
            <v-icon>mdi-arrow-left</v-icon> Retour
          </v-btn>

          <v-spacer></v-spacer>
          <v-btn
            class="bg-color-main text-white"
            :loading="loaderTextImage"
            rounded
            @click="uploadImageToText()"
          >
            <v-icon>mdi-arrow-collapse-down</v-icon> Générer le texte
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialogKai">
      <v-overlay :value="thinkLoaderKai">
        <div align="center" class="text-center">
          <v-card class="rounded-xl p-3" max-width="300">
            <v-progress-circular
              :size="50"
              color="#2569be"
              indeterminate
            ></v-progress-circular>
            <p class="mt-5 fw-bold fst-italic">
              Patientez, je rédige votre tuto ! <br />
              Cela peut prendre jusqu'a 1 minute !
            </p>
            <v-btn
              rounded
              class="bg-danger text-white"
              small
              @click="stopMakeTutoKai()"
            >
              <v-icon>mdi-stop</v-icon> Interrompre la rédaction
            </v-btn>
          </v-card>
        </div>
      </v-overlay>
      <v-card class="rounded-xl" rounded>
        <v-toolbar
          ><v-icon @click="closeDialogKai()">mdi-close</v-icon>Quel tuto
          voudrais-tu que j'écrive pour toi ?</v-toolbar
        >
        <v-container>
          <v-card class="rounded-xl py-3">
            <div>
              <h2 class="text-center lead lead fw-bold p-2">
                Attention cela réécrira l'entièreté de ton tuto ! <br />
                Veille bien à m'utiliser sur un TUTO VIDE !
              </h2>
            </div>
            <div class="container">
              <h3 class="mb-5 text-center">
                Quelques exemples d'utilisation :
              </h3>
              <div class="fst-italic mt-5">
                <p>"Écrit moi un tuto sur . . . ."</p>
                <p>
                  "Rédige un tuto à partir de cette page : [lien de la page]"
                </p>
                <p>"Explique-moi étapes par étapes comment . . . ."</p>
              </div>
            </div>
          </v-card>
          <div class="mt-2">
            <v-form v-model="validKai">
              <v-textarea
                filled
                label="Écrit moi un tuto sur . . ."
                :rules="requiredRules"
                class="mt-3"
                :disabled="thinkLoaderKai"
                rounded
                v-model="prompt"
              >
              </v-textarea>
              <div class="d-flex justify-content-end btn-group-vertical mb-5">
                <v-btn
                  @click="makeTutoKai()"
                  rounded
                  :loading="thinkLoaderKai"
                  :disabled="!validKai"
                  class="text-color-main fw-bold m-1"
                  ><v-icon>mdi-pencil</v-icon>Ecrit moi ce tuto !</v-btn
                >
              </div>
            </v-form>

            <p class="text-center" style="font-size: 15px">
              Ce module est en bêta, vous pourrez donc rencontrer quelques bugs
              lors de la rédaction de votre tuto.
            </p>
          </div>
        </v-container>
      </v-card>
    </v-dialog>

    <dialog-confirm
      :titre="arrayDialog.titre"
      :message="arrayDialog.message"
      :functionCalled="arrayDialog.functionCalled"
      type="contentTuto"
    ></dialog-confirm>
  </div>
</template>
<script>
/* eslint-disable */
import edjsHTML from "editorjs-html";
import ColorPlugin from "editorjs-text-color-plugin";
import TutoLogs from "@/components/logs/TutoLogs.vue";
import axios from "axios";
import Header from "@editorjs/header";
import List from "@editorjs/list";
import CodeTool from "@editorjs/code";
import Paragraph from "@editorjs/paragraph";
import Embed from "@editorjs/embed";
import Table from "@editorjs/table";
import ImageTool from "@editorjs/image";
import Marker from "@editorjs/marker";
import Warning from "@editorjs/warning";
import RawTool from "@editorjs/raw";
import Quote from "@editorjs/quote";
import InlineCode from "@editorjs/inline-code";
import Delimiter from "@editorjs/delimiter";
import SimpleImage from "@editorjs/simple-image";
import BreakLine from "editorjs-break-line";
import $ from "jquery";
import ListeComments from "@/components/comments/ListeComments.vue";
import DialogConfirm from "@/components/modal/DialogConfirm.vue";
export default {
  components: { ListeComments, TutoLogs, DialogConfirm },
  props: ["readOnly", "code_tuto", "slug"],
  metaInfo() {
    return {
      title: this.editedItem.titre + " | Pocketuto ",
      meta: [
        {
          vmid: "description",
          name: "description",
          content: this.editedItem.titre,
        },
      ],
    };
  },
  beforeRouteLeave(to, from, next) {
    if (this.editModeTuto) {
      this.$swal({
        title: "Attention",
        icon: "info",
        text: "Veuillez sauvegarder vos données avant de quitter cette page !",
        showConfirmButton: true,
        showCancelButton: true,
        confirmButtonText: "Je sauvegarde !",
        cancelButtonText: "Je quitte sans sauvegarder !",
        allowOutsideClick: () => {
          const popup = Swal.getPopup();
          popup.classList.remove("swal2-show");
          setTimeout(() => {
            popup.classList.add("animate__animated", "animate__headShake");
          });
          setTimeout(() => {
            popup.classList.remove("animate__animated", "animate__headShake");
          }, 500);
          return false;
        },
      }).then((result) => {
        if (result.isConfirmed) {
          this.saveContentTuto();
        } else {
          next();
        }
      });
    } else {
      next();
    }
  },
  data() {
    return {
      editor: [],
      idTuto: null,
      prompt: "",
      validKai: false,
      dialogTextImage: false,
      fileTextImage: [],
      loaderTextImage: false,
      displayLogs: false,
      config: {
        readOnly: true,
        logLevel: "ERROR",
        tools: {
          header: {
            class: Header,
            config: {
              placeholder: "Entrer un titre",
              levels: [2, 3, 4],
              defaultLevel: 3,
            },
          },
          Color: {
            class: ColorPlugin, // if load from CDN, please try: window.ColorPlugin
            config: {
              colorCollections: [
                "#470017",
                "#032385",
                "#00bd78",
                "#3d9eff",
                "#bfaf00",
                "#ed64c6",
                "#0004ff",
                "#00ff59",
                "#fffb00",
                "#009c36",
                "#FF1300",
                "#ff9900",
                "#66ff00",
                "#00d5ff",
                "#EC7878",
                "#9C27B0",
                "#ff00d0",
                "#4f00e0",
                "#673AB7",
                "#3F51B5",
                "#0070FF",
                "#03A9F4",
                "#00BCD4",
                "#4CAF50",
                "#8BC34A",
                "#CDDC39",
                "#FFF",
                "#000",
              ],
              defaultColor: "#FF1300",
              type: "text",
            },
          },
          Marker: {
            class: ColorPlugin, // if load from CDN, please try: window.ColorPlugin
            config: {
              defaultColor: "#FFBF00",
              type: "marker",
            },
          },
          image: {
            class: ImageTool,
            config: {
              uploader: {
                /**
                 * Upload file to the server and return an uploaded image data
                 * @param {File} file - file selected from the device or pasted by drag-n-drop
                 * @return {Promise.<{success, file: {url}}>}
                 */
                uploadByFile(file) {
                  // your own uploading logic here
                  var formData = new FormData();
                  formData.append("image", file);
                  if (window.location.href.includes("http://localhost")) {
                    return axios
                      .post(
                        "http://tutoriallapi/api/$2y$10$3qq00R0Pea65j8uW0ASGEuZ3L85F7R.rQsAbSrLQjdQ.EL0wZliI/B80800313C2479AB097C2EAD7515D280/tutos/editorUploadByFile",
                        formData
                      )
                      .then((response) => {
                        return response.data;
                      });
                  } else if (
                    window.location.href.includes("https://pocketuto.fr")
                  ) {
                    return axios
                      .post(
                        "https://my.pocketuto.fr/api/$2y$10$3qq00R0Pea65j8uW0ASGEuZ3L85F7R.rQsAbSrLQjdQ.EL0wZliI/B80800313C2479AB097C2EAD7515D280/tutos/editorUploadByFile",
                        formData
                      )
                      .then((response) => {
                        return response.data;
                      });
                  } else if (
                    window.location.href.includes("http://192.168.0.11")
                  ) {
                    return axios
                      .post(
                        "https://my.pocketuto.fr/api/$2y$10$3qq00R0Pea65j8uW0ASGEuZ3L85F7R.rQsAbSrLQjdQ.EL0wZliI/B80800313C2479AB097C2EAD7515D280/tutos/editorUploadByFile",
                        formData
                      )
                      .then((response) => {
                        return response.data;
                      });
                  }
                },
                //http://tutoriallapi/api/$2y$10$3qq00R0Pea65j8uW0ASGEuZ3L85F7R.rQsAbSrLQjdQ.EL0wZliI/B80800313C2479AB097C2EAD7515D280/tutos/
                //https://my.pocketuto.fr/api/$2y$10$3qq00R0Pea65j8uW0ASGEuZ3L85F7R.rQsAbSrLQjdQ.EL0wZliI/B80800313C2479AB097C2EAD7515D280/
              },
              field: "image",
              types: "images/",
            },
          },
          list: {
            class: List,
            inlineToolbar: true,
          },
          code: {
            class: CodeTool,
          },
          paragraph: {
            class: Paragraph,
          },
          embed: {
            class: Embed,
            config: {
              services: {
                youtube: true,
                coub: true,
                imgur: true,
              },
            },
          },
          table: {
            class: Table,
            inlineToolbar: true,
            config: {
              rows: 2,
              cols: 3,
            },
          },
          Marker: {
            class: Marker,
            shortcut: "CMD+SHIFT+M",
          },
          warning: {
            class: Warning,
            inlineToolbar: true,
            shortcut: "CMD+SHIFT+W",
            config: {
              titlePlaceholder: "Titre",
              messagePlaceholder: "Message",
            },
          },
          raw: RawTool,
          quote: {
            class: Quote,
            inlineToolbar: true,
            shortcut: "CMD+SHIFT+O",
            config: {
              quotePlaceholder: "Entrer une citation",
              captionPlaceholder: "Auteur de la citation",
            },
          },
          inlineCode: {
            class: InlineCode,
            shortcut: "CMD+SHIFT+M",
          },
          breakLine: {
            class: BreakLine,
            inlineToolbar: true,
            shortcut: "CTRL+SPACE",
          },
          delimiter: Delimiter,
        },
        i18n: {
          /**
           * @type {I18nDictionary}
           */
          messages: {
            /**
             * Other below: translation of different UI components of the editor.js core
             */
            ui: {
              blockTunes: {
                toggler: {
                  "Click to tune": "Cliquez pour régler",
                  "or drag to move": "ou faites glisser pour déplacer",
                },
              },
              inlineToolbar: {
                converter: {
                  "Convert to": "Convertir vers",
                },
              },
              toolbar: {
                toolbox: {
                  Add: "Ajouter",
                },
              },
            },

            /**
             * Section for translation Tool Names: both block and inline tools
             */
            toolNames: {
              Text: "Paragraphe",
              Heading: "Titre",
              List: "Liste",
              Warning: "Avertissement",
              Checklist: "Check liste",
              Quote: "Citation",
              Code: "Code",
              Delimiter: "Delimiteur",
              "Raw HTML": "HTML",
              Table: "Tableau",
              Link: "Lien",
              Marker: "Marqueur",
              Bold: "Gras",
              Italic: "Italic",
              InlineCode: "Code en ligne",
              BreakLine: 'Saut de ligne',
            },

            /**
             * Section for passing translations to the external tools classes
             */
            tools: {
              /**
               * Each subsection is the i18n dictionary that will be passed to the corresponded plugin
               * The name of a plugin should be equal the name you specify in the 'tool' section for that plugin
               */
              warning: {
                // <-- 'Warning' tool will accept this dictionary section
                Title: "Titre",
                Message: "Message",
              },

              /**
               * Link is the internal Inline Tool
               */
              link: {
                "Add a link": "Ajouter un lien",
              },
              /**
               * The "stub" is an internal block tool, used to fit blocks that does not have the corresponded plugin
               */
              stub: {
                "The block can not be displayed correctly.":
                  "L'élément ne s'affiche pas correcteent",
              },
            },

            /**
             * Section allows to translate Block Tunes
             */
            blockTunes: {
              /**
               * Each subsection is the i18n dictionary that will be passed to the corresponded Block Tune plugin
               * The name of a plugin should be equal the name you specify in the 'tunes' section for that plugin
               *
               * Also, there are few internal block tunes: "delete", "moveUp" and "moveDown"
               */
              delete: {
                Delete: "Supprimer",
              },
              moveUp: {
                "Move up": "Deplacer vers le haut",
              },
              moveDown: {
                "Move down": "Deplacer vers le bas",
              },
            },
          },
        },
        onReady: () => {},
        onChange: (args) => {
          this.editor.save().then((savedData) => {
            this.editedItem.content = savedData.blocks;
            localStorage.setItem("saveTuto", JSON.stringify(this.editedItem));
            this.editedItem.content = savedData.blocks;
          });
        },
        data: {
          time: 1591362820044,
          version: "2.18.0",
          blocks: [],
        },
      },
      searchText: "",
      dialogConfirmDeleteReceive: false,
      dialogConfirmDeleteSave: false,
      typeTuto: null,
      btnLoadPDF: null,
      idUser: "",
      selectedMenu: null,
      dialogKai: false,
      thinkLoaderKai: false,
      editedItem: {
        id: "",
        titre: "",
        mot_clefs: "",
        categories: "",
        password: "",
        users: [],
      },
      imageBigger: null,
      menus: [],
      displaySpeakerOff: false,
      loader: true,
      show1: false,
      displayFormComments: false,
      commentaire: "",
      openActions: false,
      dialogRightsTuto: false,
      rights: [
        { value: 1, text: "Admin" },
        { value: 0, text: "Lecture" },
      ],
      toggleSpeaker: false,
      loadBtnSave: false,
      editorLoad: false,
      editModeTuto: false,
      valid: false,
      dialogPassword: false,
      verify: "",
      showPasswordSection: false,
      requiredRules: [(v) => !!v || "Le champ est obligatoire"],
      passwordRules: [
        (value) => !!value || "Taper un mot de passe s'il vous plait",
        (value) => !!value || "Le champ mot de passe est obligatoire",
        (value) =>
          (value && /\d/.test(value)) ||
          "Le mot de passe doit contenir au moins un nombre",
        (value) =>
          (value && /[A-Z]{1}/.test(value)) ||
          "Le mot de passe doit contenir au moins une majuscule",
        (value) =>
          (value && /[^A-Za-z0-9]/.test(value)) ||
          "Le mot de passe doit contenir au moins un caractere spécial",
        (value) =>
          (value && value.length > 6) ||
          "Le mot de passe doit faire au moins 6 caractères minimum",
      ],
      arrayDialog: {
        titre: "",
        message: "",
        functionCalled: "",
      },
      cancelFunctionMakeTutoKai: null,
    };
  },
  activated() {
    if (this.code_tuto) {
      this.getDetailsTuto();
    }
    window.scrollTo({ top: 0, behavior: "smooth" });
  },
  computed: {
    user() {
      return this.$store.getters["auth/user"];
    },
    passwordMatch() {
      return () =>
        this.editedItem.password === this.verify ||
        "Les mot de passes ne sont pas les mêmes";
    },
  },
  watch: {
    editorLoad(val) {
      if (this.readOnly == false && val) {
        setTimeout(() => {
          this.editModeTuto = true;
          this.editor.readOnly.toggle();
        }, 1000);
      }
    },
  },
  methods: {
    onInitialized(editor) {
      this.editor = editor;
      this.editorLoad = true;
    },
    makeTutoKai() {
      var payload = {
        prompt: this.prompt + ", ecrit moi ce tuto au format HTML",
      };
      if (this.editModeTuto == true) {
        this.editContenuTuto();
      }
      this.thinkLoaderKai = true;

      const CancelToken = axios.CancelToken;
      this.editContenuTuto();
      this.$axios
        .post("tutos/makeTutoKai", payload, {
          cancelToken: new CancelToken((c) => {
            // Stocker la fonction d'annulation pour l'utiliser plus tard
            this.cancelFunctionMakeTutoKai = c;
          }),
        })
        .then((response) => {
          this.editor.blocks.renderFromHTML(response.data);
          this.editor.save().then((savedData) => {
            this.editedItem.content = savedData.blocks;
            localStorage.setItem("saveTuto", JSON.stringify(this.editedItem));
            this.editedItem.content = savedData.blocks;
            this.saveContentTuto();
            this.closeDialogKai();
          });
        })
        .catch((e) => {
          if (axios.isCancel(e)) {
            this.$swal(
              "Annulation !",
              "La rédaction du tuto a été annulé",
              "info"
            );
          } else {
            this.$swal(
              "Désolé !",
              "Je n'ai pas pu rédiger votre tuto, veuillez réessayer s'il vous plaît !",
              "error"
            );
          }
          this.closeDialogKai();
        });
    },
    stopMakeTutoKai() {
      if (this.cancelFunctionMakeTutoKai) {
        this.cancelFunctionMakeTutoKai();
      }
    },
    uploadImageToText() {
      this.loaderTextImage = true;
      let formData = new FormData();
      formData.append("image", this.fileTextImage);

      if (this.editModeTuto == false) {
        this.editContenuTuto();
      }
      this.$axios
        .post("/tutos/uploadImageToText", formData)
        .then((response) => {
          if (response.data.message) {
            this.$swal({
              toast: true,
              icon: "error",
              timer: 3000,
              title: response.data.message,
              position: "top-right",
              showConfirmButton: false,
            });
            return;
          }
          const newBlock = {
            type: "paragraph",
            data: {
              text: response.data,
            },
          };
          this.editor.blocks.insert(newBlock.type, newBlock.data);
          if (this.isJsonString(this.editedItem.content)) {
            var arrayContentTuto = JSON.parse(this.editedItem.content);
          } else {
            var arrayContentTuto = this.editedItem.content;
          }
          arrayContentTuto.push(newBlock);
          this.editedItem.content = arrayContentTuto;
          this.fileTextImage = {};
          localStorage.setItem("saveTuto", JSON.stringify(this.editedItem));
          this.closeModalTextImage();
          this.saveContentTuto();
        });
    },
    isJsonString(str) {
      try {
        JSON.parse(str);
      } catch (e) {
        return false;
      }
      return true;
    },
    openModalKai() {
      this.dialogKai = true;
    },
    openModalTextImage() {
      this.dialogTextImage = true;
      this.loaderTextImage = false;
    },
    closeModalTextImage() {
      this.dialogTextImage = false;
    },
    closeDialogKai() {
      this.dialogKai = false;
      this.prompt = "";
      this.thinkLoaderKai = false;
    },
    getDetailsTuto() {
      this.loader = true;
      var payload = {
        passwordRequire: this.editedItem.passwordRequire
          ? this.editedItem.passwordRequire
          : "",
      };
      this.$axios
        .post("tutos/getDetailsTuto/" + this.code_tuto, payload)
        .then((response) => {
          if (response.data.messageError) {
            this.$swal(
              "Oups, une erreur est survenu !",
              response.data.messageError,
              "error"
            );
            this.loader = false;
            this.editedItem.passwordRequire = "";
            return;
          }
          if (response.data.needPassword) {
            this.dialogPassword = true;
            return;
          }
          this.dialogPassword = false;
          this.editedItem = response.data;
          this.idTuto = this.editedItem.id;

          var arrayEditedItemLocalStorage = JSON.parse(
            localStorage.getItem("saveTuto")
          )
            ? JSON.parse(localStorage.getItem("saveTuto"))
            : [];
          var arrayDatabase = JSON.parse(this.editedItem.content);

          this.config.data.blocks = JSON.parse(this.editedItem.content);

          if (this.user) {
            if (this.editedItem.lastUserWhoEdit == this.user.id) {
              if (arrayEditedItemLocalStorage.id == this.editedItem.id) {
                if (
                  arrayEditedItemLocalStorage.content.length !=
                  arrayDatabase.length
                ) {
                  this.$swal({
                    title: "Contenu non sauvegardé",
                    text: "Nous avons détécter du contenu non enregistré, veuillez sauvegarder votre contenu avant de partir sinon il ne sera pas visible pour les viewers.",
                    icon: "warning",
                    showConfirmButton: true,
                    showCancelButton: true,
                    confirmButtonText: "Je sauvegarde !",
                    cancelButtonText: "Je ne sauvegarde pas l'ancien contenu !",
                    allowOutsideClick: () => {
                      const popup = Swal.getPopup();
                      popup.classList.remove("swal2-show");
                      setTimeout(() => {
                        popup.classList.add(
                          "animate__animated",
                          "animate__headShake"
                        );
                      });
                      setTimeout(() => {
                        popup.classList.remove(
                          "animate__animated",
                          "animate__headShake"
                        );
                      }, 500);
                      return false;
                    },
                  }).then((result) => {
                    if (result.isConfirmed) {
                      this.editContenuTuto();
                      var arrayContentSave = JSON.parse(
                        localStorage.getItem("saveTuto")
                      );
                      this.config.data.blocks = arrayContentSave.content;
                    } else {
                      this.config.data.blocks = JSON.parse(
                        this.editedItem.content
                      );
                      localStorage.setItem("saveTuto", this.editedItem.content);
                    }
                  });
                }
              }
            }
          }
          this.loader = false;
        });
    },
    goToContent(titre, index) {
      document.getElementById(titre + "-" + index).scrollIntoView({
        behavior: "smooth",
      });
    },
    toggleComments() {
      this.$axios
        .get("/tutos/toggleComments/" + this.editedItem.id)
        .then((response) => {
          this.$swal({
            toast: true,
            icon: "info",
            timer: 3000,
            title: response.data.message,
            position: "top-right",
            showConfirmButton: false,
          });
          this.editedItem = response.data.tuto;
        });
    },
    sendTuto() {
      this.$router.push({
        name: "Envoyer",
        params: {
          idElement: this.idTuto,
          nomElement: "tutos",
          element: this.editedItem,
        },
      });
    },
    fav() {
      this.$store
        .dispatch("tutos/toggleFavTuto", this.idTuto)
        .then((response) => {
          if (response.data.messageError) {
            this.$swal(
              "Oups, une erreur est survenu !",
              response.data.messageError,
              "error"
            );
            return;
          }

          this.editedItem = response.data.tuto;
          this.$swal({
            toast: true,
            title: response.data.message,
            icon: "success",
            animation: true,
            position: "top-right",
            showConfirmButton: false,
            timerProgressBar: true,
            timer: 2000,
          });
        });
    },
    kFormatter(num) {
      if (num) {
        return Math.abs(num) > 999
          ? Math.sign(num) * (Math.abs(num) / 1000).toFixed(1) + "k"
          : Math.sign(num) * Math.abs(num);
      } else {
        return 0;
      }
    },

    likeTuto() {
      this.$axios.get("/tutos/likeTuto/" + this.idTuto).then(() => {
        this.getDetailsTuto();
      });
    },
    shareTuto() {
      this.$router.push({
        name: "Envoyer",
        params: {
          idElement: this.idTuto,
          nomElement: "tutos",
          element: this.editedItem,
        },
      });
    },
    deleteTuto() {
      if (this.editedItem.isOwn) {
        this.arrayDialog["message"] =
          "Voulez-vous vraiment supprimer le tuto ? Toutes les personnes qui bénéficient de ce tuto le perdront !";
        this.arrayDialog["titre"] = "Que voulez-vous faire ?";
      } else {
        this.arrayDialog["message"] =
          "Voulez-vous vraiment supprimer ce tuto de votre compte ?";
        this.arrayDialog["titre"] = "Que voulez-vous faire ?";
      }
      this.arrayDialog["functionCalled"] = "confirmDeleteTuto";
      this.$store.dispatch("modal/setOpen", "contentTuto");
    },
    confirmDeleteTuto() {
      if (this.editedItem.isOwn) {
        this.$store
          .dispatch("tutos/deleteTutoSave", this.idTuto)
          .then((response) => {
            if (response.data.messageError) {
              this.$swal(
                "Oups, une erreur est survenu !",
                response.data.messageError,
                "error"
              ).then(() => {
                this.openActions = false;
                this.$store.dispatch("modal/setClose", "contentTuto");
              });
              return;
            }
            this.$swal({
              toast: true,
              title: response.data.message,
              icon: "success",
              animation: true,
              position: "top-right",
              showConfirmButton: false,
              timerProgressBar: true,
              timer: 2000,
            });
            this.$router.push({
              name: "Tutos",
            });
            this.$store.dispatch("modal/setClose", "contentTuto");
          });
      } else {
        this.$store
          .dispatch("tutos/deleteTutoReceive", this.idTuto)
          .then((response) => {
            if (response.data.messageError) {
              this.$swal(
                "Oups, une erreur est survenu !",
                response.data.messageError,
                "error"
              ).then(() => {
                this.openActions = false;
                this.$store.dispatch("modal/setClose", "contentTuto");
              });
              return;
            }
            this.$swal({
              toast: true,
              title: response.data.message,
              icon: "success",
              animation: true,
              position: "top-right",
              showConfirmButton: false,
              timerProgressBar: true,
              timer: 2000,
            });
            this.$store.dispatch("modal/setClose", "contentTuto");
            this.$router.push({
              name: "Tutos",
            });
          });
      }
    },
    editContenuTuto() {
      this.editor.readOnly.toggle();
      this.editModeTuto = true;
      this.openActions = false;
    },
    editInfoTuto() {
      this.$router.push({
        name: "Editer le tuto",
        params: { tutoId: this.idTuto },
      });
    },
    dialogRemoveUser(idUser) {
      this.arrayDialog["message"] =
        "Voulez-vous retirer cet utilisateur du tuto ?";
      this.arrayDialog["titre"] = "Que voulez-vous faire ?";
      this.arrayDialog["functionCalled"] = "removeUserFromMyTuto";
      this.$store.dispatch("modal/setOpen", "contentTuto");
      this.idUser = idUser;
    },
    removeUserFromMyTuto() {
      this.$axios
        .get(
          "/tutos/removeUserFromMyTuto/" +
            this.idUser +
            "/" +
            this.editedItem.id
        )
        .then((response) => {
          if (response.data.messageError) {
            this.$swal(
              "Oups, une erreur est survenu !",
              response.data.messageError,
              "error"
            );
            return;
          }
          this.$swal({
            toast: true,
            title: response.data.message,
            icon: "success",
            animation: true,
            position: "top-right",
            showConfirmButton: false,
            timerProgressBar: true,
            timer: 2000,
          });
          this.editedItem.users = response.data.users;
          this.$store.dispatch("modal/setClose", "contentTuto");
        });
    },
    saveMyTuto() {
      this.$axios
        .get("/tutos/saveTutoForMe/" + this.editedItem.id)
        .then((response) => {
          if (response.data.messageError) {
            this.$swal(
              "Une erreur est survenu !",
              response.data.messageError,
              "error"
            );
            return;
          }
          this.$swal({
            toast: true,
            title: response.data.message,
            icon: "success",
            animation: true,
            position: "top-right",
            showConfirmButton: false,
            timerProgressBar: true,
            timer: 2000,
          });
          this.openActions = false;
        });
    },
    changeRightTuto() {
      this.dialogRightsTuto = true;
    },
    saveRightsTutoUser(users) {
      this.$axios
        .post("/tutos/changeRightsTutoUser", { users: users })
        .then((response) => {
          this.$swal({
            toast: true,
            title: response.data.message,
            icon: "success",
            animation: true,
            position: "top-right",
            showConfirmButton: false,
            timerProgressBar: true,
            timer: 2000,
          });
        });
    },
    saveContentTuto() {
      var resp = this.editor.save();
      resp.then((response) => {
        this.loadBtnSave = true;
        let formData = new FormData();
        formData.append("content", JSON.stringify(response.blocks));
        formData.append("editContent", true);
        var payload = { idTuto: this.idTuto, editedItem: formData };
        this.$store.dispatch("tutos/editTuto", payload).then((response) => {
          if (response.data.messageError) {
            this.loadBtnSave = false;
            this.editModeTuto = false;
            this.$swal("Oups !", response.data.messageError[0], "error");
            return;
          }
          this.$swal({
            toast: true,
            title: response.data.message,
            icon: "success",
            animation: true,
            position: "top-right",
            showConfirmButton: false,
            timerProgressBar: true,
            timer: 2000,
          });
          this.editor.readOnly.toggle();
          this.editModeTuto = false;
          this.loadBtnSave = false;
        });
      });
    },
    addComment() {
      this.displayFormComments = true;
    },
    refreshComments(comments) {
      this.editedItem.comments = comments;
    },
    savePasswordTuto() {
      this.$axios
        .post("tutos/savePassword/" + this.editedItem.id, {
          password: this.editedItem.password,
        })
        .then((response) => {
          this.$swal({
            toast: true,
            title: response.data.message,
            icon: "success",
            animation: true,
            position: "top-right",
            showConfirmButton: false,
            timerProgressBar: true,
            timer: 2000,
          });
          this.dialogRightsTuto = false;
          this.showPasswordSection = false;
          this.getDetailsTuto();
        });
    },
    ownerTransfer() {
      var payload = {
        idUserTransfer: this.idUser,
      };
      this.$axios
        .post("tutos/ownerTransfer/" + this.editedItem.id, payload)
        .then((response) => {
          if (response.data.messageError) {
            this.$swal({
              toast: true,
              title: response.data.messageError,
              icon: "success",
              animation: true,
              position: "top-right",
              showConfirmButton: false,
              timerProgressBar: true,
              timer: 2000,
            });
            return;
          }
          this.$swal({
            toast: true,
            title: response.data.message,
            icon: "success",
            animation: true,
            position: "top-right",
            showConfirmButton: false,
            timerProgressBar: true,
            timer: 2000,
          }).then(() => {
            document.location.realod();
          });
        });
    },
    generatePDF() {
      var edjsParser = new edjsHTML({ table: this.customParser });
      this.btnLoadPDF = true;
      this.$axios({
        url: "tutos/generateTutoPDF",
        method: "POST",
        responseType: "blob",
        data: {
          content: edjsParser.parse({
            blocks: JSON.parse(this.editedItem.content),
          }),
        },
      }).then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", this.editedItem.titre + ".pdf");
        document.body.appendChild(link);
        link.click();
        link.remove();
        this.btnLoadPDF = false;
      });
    },
    customParser(block) {
      if (this.isDarkMode) {
        var tableOpen = "<table class='table table-bordered text-white'>";
      } else {
        var tableOpen = "<table class='table table-bordered text-dark'>";
      }
      var trOpen = "<tr>";
      var trClose = "</tr>";
      var id = "";
      block.data.content.forEach((element, index) => {
        element.forEach((elem) => {
          if (id != index) {
            trOpen += trClose;
          }
          trOpen += `<td scope='row'> ${elem} </td>`;
          id = index;
        });
      });
      var tableClose = "</table>";
      return tableOpen + trOpen + tableClose;
    },
    dialogTransferOwnership(idUser) {
      this.idUser = idUser;
      this.arrayDialog["message"] =
        "Voulez-vous transférer la propriété de ce tuto à cet utilisateur ?";
      this.arrayDialog["titre"] = "Que voulez-vous faire ?";
      this.arrayDialog["functionCalled"] = "ownerTransfer";
      this.$store.dispatch("modal/setOpen", "contentTuto");
    },
    dialogDeletePassword() {
      this.arrayDialog["message"] =
        "Voulez-vous supprimer le mot de passe du tuto ?";
      this.arrayDialog["titre"] = "Que voulez-vous faire ?";
      this.arrayDialog["functionCalled"] = "deletePasswordTuto";
      this.$store.dispatch("modal/setOpen", "contentTuto");
    },
    deletePasswordTuto() {
      this.$axios
        .get("tutos/deletePasswordTuto/" + this.editedItem.id, {
          password: this.editedItem.password,
        })
        .then((response) => {
          this.$swal({
            toast: true,
            title: response.data.message,
            icon: "success",
            animation: true,
            position: "top-right",
            showConfirmButton: false,
            timerProgressBar: true,
            timer: 2000,
          });
          this.dialogRightsTuto = false;
          this.showPasswordSection = false;
          this.$store.dispatch("modal/setClose", "contentTuto");
          this.getDetailsTuto();
        });
    },
  },
};
</script>

<style>
.sticky {
  position: -webkit-sticky;
  /* Safari */
  position: sticky;
  top: 0;
}

.codex-editor--dark .ce-block--selected .ce-block__content,
.codex-editor--dark .ce-inline-toolbar,
.codex-editor--dark .codex-editor--narrow .ce-toolbox,
.codex-editor--dark .ce-conversion-toolbar,
.codex-editor--dark .ce-settings,
.codex-editor--dark .ce-settings__button,
.codex-editor--dark .ce-toolbar__settings-btn,
.codex-editor--dark .cdx-button,
.codex-editor--dark .ce-popover,
.codex-editor--dark .tc-row--selected,
.codex-editor--dark .tc-popover--opened,
.codex-editor--dark .ce-popover__item-icon,
.codex-editor--dark .ce-toolbar__plus,
.codex-editor--dark .tc-popover__item,
.codex-editor--dark .tc-toolbox__toggler,
.codex-editor--dark .tc-toolbox--showed,
.codex-editor--dark .ce-toolbar__plus:hover {
  background: #2596be !important;
  color: inherit;
}

.codex-editor--dark .ce-inline-tool,
.codex-editor--dark .ce-conversion-toolbar__label,
.codex-editor--dark .ce-toolbox__button,
.codex-editor--dark .cdx-settings-button,
.codex-editor--dark .ce-toolbar__plus {
  color: inherit;
}

.codex-editor--dark ::selection {
  background: #2596be;
}

.codex-editor--dark .cdx-settings-button:hover,
.codex-editor--dark .ce-settings__button:hover,
.codex-editor--dark .ce-toolbox__button--active,
.codex-editor--dark .ce-toolbox__button:hover,
.codex-editor--dark .cdx-button:hover,
.codex-editor--dark .ce-inline-toolbar__dropdown:hover,
.codex-editor--dark .ce-inline-tool:hover,
.codex-editor--dark .ce-popover__item:hover,
.codex-editor--dark .ce-toolbar__settings-btn:hover {
  background-color: #2596be !important;
  color: inherit;
}

.codex-editor--dark .cdx-notify--error {
  background: #fb5d5d !important;
}

.codex-editor--dark .cdx-notify__cross::after,
.codex-editor--dark .cdx-notify__cross::before {
  background: white;
}
</style>
