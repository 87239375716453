<template>
  <div>
    <v-container>
      <div v-if="logs.length == 0">
        <h1 class="text-h5 text-center mt-5">
          Pas de log enregistré pour le moment pour ce tuto !
        </h1>
      </div>
      <v-timeline style="z-index: 0" v-else align-top dense>
        <v-timeline-item color="blue" v-for="log in logs" :key="log.id" small>
          <v-row class="pt-1">
            <v-col>
              <v-card class="p-3 rounded-xl">
                <div>
                  <strong>{{ log.action }}</strong> par
                  <span class="text-h5">{{ log.modified_by }}</span>
                </div>
                <div class="mb-3">
                  le <strong>{{ log.created_at }}</strong>
                </div>
                <div>
                  <v-btn
                    class="bg-color-main"
                    rounded
                    @click="watchContentChange(log)"
                  >
                    Voir les modifications
                  </v-btn>
                </div>
              </v-card>
            </v-col>
          </v-row>
        </v-timeline-item>
      </v-timeline>
    </v-container>
    <v-dialog v-model="dialogContentChange">
      <v-card class="rounded-xl">
        <v-toolbar>
          <v-toolbar-title>Changements</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-icon @click="dialogContentChange = false">mdi-close</v-icon>
        </v-toolbar>
        <v-container class="pb-5">
          <v-row v-if="editedItem.action == 'Modification des informations'">
            <v-col cols="5">
              <v-card-title class="text-break text-muted">
                Ancien contenu
              </v-card-title>
              <hr />
              <div class="text-break img-fluid" id="content-new">
                <h1 class="text-break">{{ oldContent["titre"] }}</h1>
                <p class="text-break">
                  Description: {{ oldContent["description"] }}
                </p>
                <p class="text-break">
                  Mots-clés: {{ newContent["mot_clefs"] }}
                </p>
                <p class="text-break">
                  Visibilité: {{ oldContent["isPrivate"] ? "Privé" : "Public" }}
                </p>
              </div>
            </v-col>
            <v-col cols="2" class="text-center">
              <v-divider vertical></v-divider>
            </v-col>
            <v-col cols="5">
              <v-card-title class="text-break text-muted">
                Nouveau contenu
              </v-card-title>
              <hr />
              <div class="text-break">
                <div class="text-break img-fluid" id="content-new">
                  <h1>{{ newContent["titre"] }}</h1>
                  <p class="text-break">
                    Description: {{ newContent["description"] }}
                  </p>
                  <p class="text-break">
                    Mots-clés: {{ newContent["mot_clefs"] }}
                  </p>
                  <p class="text-break">
                    Visibilité:
                    {{ newContent["isPrivate"] ? "Privé" : "Public" }}
                  </p>
                </div>
              </div>
            </v-col>
          </v-row>
          <v-row class="pb-5" v-else>
            <v-col cols="5">
              <v-card-title class="text-break text-muted">
                Ancien contenu
              </v-card-title>
              <hr />
              <div
                class="text-break"
                v-for="(content, index) in oldContent"
                :key="index"
              >
                <div
                  class="text-break img-fluid"
                  id="content-old"
                  v-html="content"
                ></div>
              </div>
            </v-col>
            <v-col cols="2" class="text-center">
              <v-divider vertical></v-divider>
            </v-col>
            <v-col cols="5">
              <v-card-title class="text-break text-muted">
                Nouveau contenu
              </v-card-title>
              <hr />
              <div
                class="text-break"
                v-for="(content, index) in newContent"
                :key="index"
              >
                <div
                  class="text-break img-fluid"
                  id="content-new"
                  :style="isDarkMode ? 'color :white !important' : ''"
                  v-html="content"
                ></div>
              </div>
            </v-col>
          </v-row>
        </v-container>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
/* eslint-disable */
import edjsHTML from "editorjs-html";
export default {
  props: ["tuto"],
  data() {
    return {
      logs: [],
      editedItem: [],
      dialogContentChange: false,
      oldContent: [],
      newContent: [],
    };
  },
  async mounted() {
    this.getLogs();
  },
  methods: {
    async getLogs() {
      await this.$axios
        .get("/tutos/logs/" + this.tuto.id)
        .then(async (response) => {
          this.logs = response.data;
        });
    },
    watchContentChange(log) {
      this.dialogContentChange = true;
      this.editedItem = log;
      var oldContent = JSON.parse(this.editedItem.old_content);
      var newContent = JSON.parse(this.editedItem.new_content);

      this.oldContent = oldContent;
      this.newContent = newContent;

      if (this.editedItem.action == "Modification du contenu") {
        var edjsParser = new edjsHTML({ table: this.customParserTable, breakLine: this.customParserBreaklines });
        this.oldContent = edjsParser.parse({ blocks: oldContent });
        this.newContent = edjsParser.parse({ blocks: newContent });
      }
    },
    customParserTable(block) {
      if (this.isDarkMode) {
        var tableOpen = "<table class='table table-bordered text-white'>";
      } else {
        var tableOpen = "<table class='table table-bordered text-dark'>";
      }
      var trOpen = "<tr>";
      var trClose = "</tr>";
      var id = "";
      block.data.content.forEach((element, index) => {
        element.forEach((elem) => {
          if (id != index) {
            trOpen += trClose;
          }
          trOpen += `<td scope='row'> ${elem} </td>`;
          id = index;
        });
      });
      var tableClose = "</table>";
      return tableOpen + trOpen + tableClose;
    },
    customParserBreaklines() {
      var brTag = "<br>";
      return brTag;
    },
  },
};
</script>

<style>
#content-new img {
  width: 350px;
}

#content-old img {
  width: 350px;
}
</style>