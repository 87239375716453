<template>
  <div>
    <div class="container mt-10" style="margin-bottom:100px">
      <form-tuto :typeFormulaire="'creation'"></form-tuto>
    </div>
  </div>
</template>

<script>
import FormTuto from "@/components/tutos/FormTuto.vue";
export default {
  metaInfo() {
    return {
      title: "Créer un nouveau tuto | Pocketuto ",
      meta: [
        { vmid: 'description', name: 'description', content: "Création d'un nouveau tuto" }
      ]
    }
  },
  components: {
    FormTuto,
  },
  data() {
    return {};
  },

  methods: {},
};
</script>

<style>
</style>