/* eslint-disable */
import axios from 'axios'
import router from "/src/router";
export default {
    namespaced: true,
    state: {
        tickets: [],
        dataLoad: false,
    },
    mutations: {
        SET_TICKETS(state, datas) {
            state.tickets = datas
        },
        SET_DATALOAD(state, data) {
            state.dataLoad = data
        },
    },
    getters: {
        getTickets(state) {
            return state.tickets
        },
        dataLoad(state) {
            return state.dataLoad
        },
    },
    actions: {
        setTickets({ commit }, page) {
            return new Promise((resolve, reject) => {
                axios
                    .get("/tickets/index?page=" + page)
                    .then((response) => {
                        if (response.data.messageError) {
                            resolve(response)
                        }
                        commit('SET_TICKETS', response.data.tickets)
                        commit('SET_DATALOAD', true)
                        resolve(response)
                    })
                    .catch(function(error) {
                        if (error.response.status === 401) {
                            setTimeout(() => {
                                router.push({ name: "Authentification" });
                            }, 1200);
                        }
                    });
            })

        },
        addTicket({ commit, dispatch }, ticket) {
            return new Promise((resolve, reject) => {
                axios.post("/tickets/store", ticket).then((response) => {
                    if (response.data.messageError) {
                        resolve(response)
                    }
                    resolve(response)
                    dispatch("setTickets")
                })
            })
        },
        storeMessage({ commit, dispatch }, payload) {
            return new Promise((resolve, reject) => {
                axios.post("/tickets/storeMessage", payload).then((response) => {
                    if (response.data.messageError) {
                        resolve(response)
                    }
                    resolve(response)
                    dispatch("setTickets")
                })
            })
        },
        getContenuTicket({ commit }, id) {
            return new Promise((resolve, reject) => {
                axios.get("/tickets/getTicket/" + id).then((response) => {
                    if (response.data.messageError) {
                        resolve(response)
                    }
                    resolve(response)
                })
            })
        }
    }
}