<template>
  <div class="mt-10">
    <div class="pt-2 container">
      <div style="margin-bottom: 150px" class="container">
        <v-tabs
          background-color="#2596be"
          color="dark"
          v-model="tab"
          grow
          :class="isDarkMode ? 'text-dark': 'text-white' "
          class="rounded-xl"
          centered
          icons-and-text
        >
          <v-tabs-slider></v-tabs-slider>
          <v-tab
            href="#tabbb-1"
            id="tab-tuto"
          >
            Tutos
            <v-icon>mdi-format-list-bulleted-triangle</v-icon>
          </v-tab>

          <v-tab id="tab-friend" href="#tabbb-2">
            Amies
            <v-icon>mdi-account-group-outline</v-icon>
          </v-tab>
        </v-tabs>

        <v-tabs-items touchless v-model="tab">
          <v-tab-item id="tabbb-1" :class="isDarkMode ? 'bg-dark': 'bg-main' " class="elevation-0">
            <corbeille-tuto
              :loadData="loadData"
            ></corbeille-tuto>
          </v-tab-item>
          <v-tab-item id="tabbb-2" :class="isDarkMode ? 'bg-dark': 'bg-main' ">
            <amie-bloque :loadData="loadData"></amie-bloque>
          </v-tab-item>
        </v-tabs-items>
      </div>
    </div>
  </div>
</template>

<script>
import CorbeilleTuto from "@/components/corbeille/CorbeilleTuto.vue";
import AmieBloque from "@/components/corbeille/AmieBloque.vue";

export default {
  metaInfo() {
    return {
      title:"Corbeille | Pocketuto ",
      meta: [
        { vmid: 'description', name: 'description', content: "Corbeille des tutos que vous avez supprimés et des ami(e)s que vous avez bloqué(e)s" }
      ]
    }
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.loadDataPage();
    });
  },
  components: {
    CorbeilleTuto,
    AmieBloque,
  },
  data() {
    return {
      tab: null,
      loadData: null,
      role: [],
    };
  },
   mounted() {
    var refreshIntervalId = setInterval(() => {
      if (this.$store.getters["auth/dataLoad"]) {
        this.role = this.$store.getters["auth/role"];
        clearInterval(refreshIntervalId);
      }
    }, 1000);
  },
  methods: {
    loadDataPage() {
      this.loadData = Math.floor(Math.random() * 105111415641);
    },
  },
};
</script>

<style>
</style>