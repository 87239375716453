<template>
  <div class="bg-main">
    <div style="padding-bottom: 100px">
      <div class="container mt-10">
        <v-card class="rounded-xl">
          <h1 class="text-center text-dark p-3 bg-color-main">Achat réussi</h1>
        </v-card>
      </div>
      <v-card class="container rounded-xl">
        <v-card-title class="text-primary"
          >MERCI POUR VOTRE ACHAT !</v-card-title
        >
        <v-card-text class="text-break">
          Nous avons bien traité votre paiement. <br />
          Votre extension de stockage a bien été appliqué ! <br /><br />
          Pour le vérifier rendez-vous dans votre profil !
          <strong
            ><a
              class="text-decoration-none text-dark"
              href="mail:contact.pocketuto@gmail.com@gmail.com"
              >contact.pocketuto@gmail.com</a
            ></strong
          >
        </v-card-text>
        <v-card-actions class="d-flex justify-content-center">
          <v-btn @click="redirectToAccount()" rounded class="bg-dark text-white"
            ><strong>MON COMPTE<v-icon>mdi-account</v-icon></strong></v-btn
          >
        </v-card-actions>
      </v-card>
    </div>
  </div>
</template>

<script>
export default {
  metaInfo() {
    return {
      title: "Paiement réussi ! | Pocketuto ",
      meta: [
        { vmid: 'description', name: 'description', content: "Votre paiement sur Pocketuto a réussi" }
      ]
    }
  },
  methods: {
    redirectToAccount() {
      this.$router
        .push({ name: "Mon compte" })
        .then(() => document.location.reload());
    },
  },
};
</script>

<style>
</style>