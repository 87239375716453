<template>
  <div class="mt-10">
    <h3 class="text-center lead fs-3 mt-5">Expliquer de manière clair, précise et concise votre problème.</h3>
    <form-ticket></form-ticket>
  </div>
</template>

<script>
import FormTicket from '@/components/support/FormTicket.vue'
export default {
  metaInfo() {
    return {
      title:"Créer un nouveau ticket | Pocketuto ",
      meta: [
        { vmid: 'description', name: 'description', content: "Création d'un ticket" }
      ]
    }
  },
  components: { FormTicket },

}
</script>

<style>

</style>