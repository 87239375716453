<template>
  <div class="bg-main">
    <div class="container mt-10">
      <v-card class="rounded-xl">
        <h1 class="text-center text-dark p-3 bg-color-main">
          Cette page n'existe pas !
        </h1>
      </v-card>
    </div>
    <v-container>
      <center>
        <v-img src="/img/giphy.gif" width="600"></v-img>

        <v-btn class="mt-5 bg-color-main" rounded x-large @click="$router.push({ name: 'Tutos' })">Retour sur terre !</v-btn>
      </center>
    </v-container>
  </div>
</template>

<script>
export default {
  metaInfo() {
    return {
      title:"Page non trouvée | Pocketuto ",
      meta: [
        { vmid: 'description', name: 'description', content: "Page non trouvée" }
      ]
    }
  },
};
</script>

<style>

</style>