/* eslint-disable */
import axios from 'axios'
import router from "/src/router";
export default {
    namespaced: true,
    state: {
        folders: [],
        maxFolders: 0,
        displayTypeFolder: null,
        dataLoad: false,
    },
    mutations: {
        SET_FOLDERS(state, folders) {
            state.folders = folders
        },
        SET_MAX_FOLDERS(state, data) {
            state.maxFolders = data
        },
        SET_DISPLAY_TYPE_FOLDER(state, data) {
            state.displayTypeFolder = data
        },
        SET_DATALOAD(state, data) {
            state.dataLoad = data
        },
    },
    getters: {
        getFolders(state) {
            return state.folders
        },
        getMaxFolders(state) {
            return state.maxFolders
        },
        dataLoad(state) {
            return state.dataLoad
        },
        displayTypeFolder(state) {
            return state.displayTypeFolder
        },
    },
    actions: {
        setFolders({ commit }, payload) {
            return new Promise((resolve, reject) => {
                axios.post("/folder/getFolders", payload).then((response) => {
                    if (response.data.messageError) {
                        resolve(response)
                    }
                    commit('SET_FOLDERS', response.data.folders)
                    commit('SET_MAX_FOLDERS', response.data.foldersMax)
                    commit('SET_DISPLAY_TYPE_FOLDER', response.data.displayTypeFolder)
                    commit('SET_DATALOAD', true)
                    resolve(response)
                }).catch(function(error) {
                    if (error.response.status == 401) {
                        setTimeout(() => {
                            router.push({ name: "Je m'authentifie" });
                        }, 1000);
                    }
                });
            })
        },
    }
}