<template>
  <div>
    <v-container class="mt-10">
      <v-card-title class="text-break"> Editer le dossier </v-card-title>
      <FormFolder :editedFolder="editedFolder" :idFolder="idFolder"></FormFolder>
    </v-container>
  </div>
</template>

<script>
import FormFolder from "@/components/folders/FormFolder.vue";
export default {
  metaInfo() {
    return {
      title: "Modifier le dossier " + this.editedFolder.titre  + " | Pocketuto ",
      meta: [
        { vmid: 'description', name: 'description', content: "Modifier le dossier : " + this.editedFolder.titre }
      ]
    }
  },
  props: ["idFolder"],
  components: {
    FormFolder,
  },
  data() {
    return {
      editedFolder: {
        id: "",
        titre: "",
        categories: [],
        arrayTutos: [],
        description: "",
        mot_clefs: "",
        isPrivate: "",
      },
    };
  },
   mounted() {
    this.getFolderById();
  },
  methods: {
    getFolderById() {
      this.$axios
        .get("folder/getFolderById/" + this.idFolder)
        .then((response) => {
          this.editedFolder = response.data;
        });
    },
  },
};
</script>

<style>
</style>