<template>
  <div>
    <friends :loadData="loadData"></friends>
  </div>
</template>

<script>
import Friends from "@/components/friends/Friends.vue";
export default {
  metaInfo() {
    return {
      title:"Mes ami(e)s | Pocketuto ",
      meta: [
        { vmid: 'description', name: 'description', content: "Liste de mes ami(e)s" }
      ]
    }
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.loadDataFriends();
    });
  },
  data() {
    return {
      loadData: null,
    };
  },
  components: {
    Friends,
  },
  methods: {
    loadDataFriends() {
      this.loadData = Math.floor(Math.random() * 105111415641);
    },
  },
};
</script>

<style>
</style>