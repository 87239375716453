<template>
  <div class="container mt-5 rounded-xl">
    <h2 class="text-center p-3 bg-color-main rounded-xl">Les catégories</h2>
    <div class="d-flex justify-content-start my-5">
      <v-btn rounded class="bg-color-main" @click="openModalCreate()"
        ><v-icon>mdi-plus</v-icon>Créer une catégorie</v-btn
      >
    </div>
    <v-text-field rounded  filled placeholder="Rechercher une catégorie . . ." v-model="search"></v-text-field>
    <v-data-table
      :headers="headers"
      :items="categoriesFilter"
      dense
      :mobile-breakpoint="0"
      :search="search"
      @click:row="editItem($event)"
      item-key="name"
    >
    </v-data-table>
    <v-dialog
      transition="dialog-top-transition"
      max-width="600"
      v-model="dialog"
    >
      <v-card class="rounded-xl">
        <v-toolbar>
          <v-icon @click="dialog = false"> mdi-close </v-icon
          ><v-spacer></v-spacer
          ><v-btn
            v-if="editedItem.id"
            icon
            class="text-danger"
            @click="deleteCategorie()"
            ><v-icon>mdi-delete</v-icon></v-btn
          >
        </v-toolbar>
        <v-card-title v-if="editedItem.id"
          >Modification d'une catégorie</v-card-title
        >
        <v-card-title v-else>Création d'une catégorie</v-card-title>
        <v-container>
          <v-form v-model="valid" lazy-validation>
            <v-text-field
              filled
              placeholder="Nom de la catégorie"
              :rules="requiredRules"
              rounded
              v-model="editedItem.nom"
            ></v-text-field>
            <v-card-actions class="d-flex justify-content-end">
              <v-btn @click="saveCategorie()" rounded class="bg-color-main"
                >Enregistrer</v-btn
              >
            </v-card-actions>
          </v-form>
        </v-container>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      categoriesFilter: [],
      headers: [
        { text: "#", value: "id" },
        { text: "Nom", value: "nom" },
      ],
      search: "",
      requiredRules: [(v) => !!v || "Le champ est obligatoire"],
      dialog: false,
      editedItem: {
        id: "",
        nom: "",
      },
      valid: true,
    };
  },
   mounted() {
    if (this.$store.getters["categories/getCategories"].length == 0) {
      this.$store.dispatch("categories/setCategories");
      var refreshIntervalId = setInterval(() => {
        if (this.$store.getters["categories/dataLoad"]) {
          this.categories = this.$store.getters["categories/getCategories"];
          this.categoriesFilter = this.categories;
          clearInterval(refreshIntervalId);
        }
      }, 1000);
    } else {
      this.categories = this.$store.getters["categories/getCategories"];
      this.categoriesFilter = this.categories;
    }
  },
  methods: {
    editItem(event) {
      this.editedItem = event;
      this.dialog = true;
    },
    openModalCreate() {
      this.editedItem = {
        id: "",
        nom: "",
      };
      this.dialog = true;
    },
    saveCategorie() {
      if (this.editedItem.id) {
        this.$axios
          .put(
            "/admin/categories/update/" + this.editedItem.id,
            this.editedItem
          )
          .then((response) => {
            this.$swal({
              toast: true,
              title: response.data.message,
              icon: "success",
              animation: true,
              position: "top-right",
              showConfirmButton: false,
              timerProgressBar: true,
              timer: 2000,
            });
            this.dialog = false;
            this.$store.dispatch("categories/setCategories");
          });
      } else {
        this.$axios
          .post("/admin/categories/create", this.editedItem)
          .then((response) => {
            this.$swal({
              toast: true,
              title: response.data.message,
              icon: "success",
              animation: true,
              position: "top-right",
              showConfirmButton: false,
              timerProgressBar: true,
              timer: 2000,
            });
            this.dialog = false;
            this.$store.dispatch("categories/setCategories");
          });
      }
    },
    deleteCategorie() {
      this.$axios
        .delete("admin/categories/delete/" + this.editedItem.id)
        .then((response) => {
          this.$swal({
            toast: true,
            title: response.data.message,
            icon: "success",
            animation: true,
            position: "top-right",
            showConfirmButton: false,
            timerProgressBar: true,
            timer: 2000,
          });
          this.dialog = false;
          this.$store.dispatch("categories/setCategories");
        });
    },
  },
};
</script>

<style>
</style>