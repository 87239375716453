<template>
  <div>
    <v-app :class="isDarkMode ? 'bg-dark' : 'bg-main'">
      <nav-bar :showNavigationDrawer="showNavigationDrawer"></nav-bar>
      <v-container v-if="displayComputer">
        <div class="container-xxl">
          <keep-alive>
            <router-view />
          </keep-alive>
        </div>
      </v-container>
      <div v-else>
        <keep-alive>
          <router-view />
        </keep-alive>
      </div>

      <v-footer dark padless v-if="currentRouteName == 'POCKETUTO'">
        <v-card flat tile width="100%" class="bg-color-main">
          <v-card-text class="d-flex justify-content-center">
            <v-btn
              v-for="icon in icons"
              :key="icon"
              class="mx-4 white--text text-center"
              icon
            >
              <v-icon size="24px">
                {{ icon }}
              </v-icon>
            </v-btn>
          </v-card-text>

          <v-card-text class="white--text pt-0 text-center">
            © Tous droits réservés a Pocketuto
          </v-card-text>

          <v-divider></v-divider>

          <v-card-text class="white--text text-center">
            {{ new Date().getFullYear() }} — <strong>Pocketuto</strong>
          </v-card-text>
        </v-card>
      </v-footer>
    </v-app>
  </div>
</template>
<script>
import { mapActions } from "vuex";
import NavBar from "@/components/navigation/NavBar.vue";
export default {
  components: {
    NavBar,
  },
  data() {
    return {
      firstLoad: null,
      isValidated: false,
      showNavigationDrawer: null,
    };
  },
  computed: {
    isAuth() {
      return this.$store.getters["auth/authenticated"];
    },
    currentRouteName() {
      return this.$route.name;
    },
    displayComputer() {
      if (window.innerWidth < 800) {
        return false;
      } else {
        return true;
      }
    },
  },

  mounted() {
    var refreshIntervalId2 = setInterval(() => {
      if (this.$store.getters["auth/dataLoad"]) {
        if (this.$store.getters["auth/isValidated"]) {
          this.isValidated = this.$store.getters["auth/isValidated"];
          if (!this.isAuth) {
            this.$router.push({ name: "Je m'authentifie" });
          }
        }
        clearInterval(refreshIntervalId2);
      }
    }, 100);
    if (
      this.currentRouteName == "Je m'authentifie" ||
      this.currentRouteName == "Changer le mot de passe" ||
      this.currentRouteName == "Mot de passe oublié"
    ) {
      //
    } else {
      if (this.isAuth && this.isValidated) {
        this.checkNotifications();
      }
    }
  },

  watch: {
    $route(to, from) {
      this.firstLoad = from.name == undefined ? true : false;
    },
  },
  methods: {
    ...mapActions({
      checkIfiAmNotification: "notifications/checkIfiAmNotification",
    }),
    openNavigationDrawer() {
      if (this.currentRouteName != "Panel Admin") {
        this.showNavigationDrawer = Math.floor(Math.random() * 105111415641);
      }
    },
    checkNotifications() {
      this.checkIfiAmNotification();
    },
  },
};
</script>

<style>
.v-messages__message {
  color: red !important;
  font-size: 16px !important;
}

@font-face {
  font-family: "Antara";
  src: local("Antara"),
    url("./assets/fonts/aAntaraDistance.ttf") format("truetype");
}

@import "./assets/styles/styles.css";
</style>