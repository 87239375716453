<template>
  <div>
    <div class="pb-5">
      <div class="container mt-10">
        <p class="text-center  mb-4 text-break p-3">
          Demandez à vos proches de scanner ce <br />
          <strong>QR Code pour leur partager l'application</strong>
          directement sur leur téléphone ! <br /><br />
          Vous pouvez également <br />
          <strong>copier le lien de l'application</strong> avec le bouton en
          dessous du QR code.
        </p>
      </div>
      <div class="pb-5 container">
        <div class="rounded-xl">
          <v-card-title class="justify-center">Scanner le QR Code</v-card-title>
          <center>
            <v-img
              class="img-fluid rounded-xl"
              width="200"
              @click="showQrCode()"
              src="img/QRCode_actualiser.png"
            ></v-img>

            <v-btn
              text
              x-large
              @click="copyLinkApp()"
              class="text-center mt-4 mb-5 "
              >Copier le lien de l'application</v-btn
            >
          </center>
        </div>
      </div>
      <v-dialog v-model="dialog" width="500px">
        <div class="d-flex justify-content-center">
          <v-img
            class="img-fluid"
            width="500px"
            @click="showQrCode()"
            src="img/QRCode_actualiser.png"
          ></v-img>
        </div>
      </v-dialog>
    </div>
  </div>
</template>

<script>
export default {
  metaInfo() {
    return {
      title:"Partager | Pocketuto ",
      meta: [
        { vmid: 'description', name: 'description', content: "Partager l'application Pocketuto" }
      ]
    }
  },
  data() {
    return {
      dialog: false,
    };
  },
  methods: {
    showQrCode() {
      this.dialog = true;
    },
    closeQrCode() {
      this.dialog = false;
    },
    copyLinkApp() {
      this.$swal({
        toast: true,
        timer: 1000,
        title: "Texte copier !",
        position: "center",
        showConfirmButton: false,
      });
      navigator.clipboard.writeText("https://pocketuto.fr");
    },
  },
};
</script>

<style>
</style>