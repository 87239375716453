<template>
  <div>
    <div class="container">
      <v-container fill-height fluid>
        <v-row align="center" justify="center">
          <v-col>
            <v-card class="elevation-12 rounded-xl" style="margin-top: 200px">
              <v-card-title class="p-4">Mot de passe oublié</v-card-title>
              <v-card-text>
                <v-form v-model="valid">
                  <v-text-field
                    prepend-inner-icon="mdi-email"
                    name="login"
                    rounded filled
                    v-on:keydown.enter.prevent="submit()"
                    :rules="emailRules"
                    v-model="form.email"
                    label="Tapez votre adresse e-mail"
                    type="text"
                    :error-messages="msgError"
                  ></v-text-field>
                </v-form>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  class="bg-color-main text-white"
                  :disabled="!valid"
                  :loading="loaderBtn"
                  @click="submit()"
                  rounded
                  >Envoyer <v-icon>mdi-send</v-icon></v-btn
                >
              </v-card-actions>
            </v-card></v-col
          >
        </v-row>
      </v-container>
    </div>
  </div>
</template>

<script>
export default {
  metaInfo() {
    return {
      title: "Mot de passe oublié | Pocketuto ",
      meta: [
        { vmid: 'description', name: 'description', content: "Vous avez oublié le mot de passe de votre compte Pocketuto" }
      ]
    }
  },
  data() {
    return {
      valid: false,
      msgError: "",
      loaderBtn: false,
      form: {
        email: "",
      },
      emailRules: [
        (v) => !!v || "Le champ doit être rempli",
        (v) => /.+@.+\..+/.test(v) || "L'adresse email est invalide",
      ],
    };
  },
   mounted() {},
  methods: {
    submit() {
      this.loaderBtn = true;
      this.$axios.post("/password/verify-email", this.form).then((response) => {
        if (response.data.status) {
          this.$axios
            .post("/password/send-email", this.form)
            .then((response) => {
              if (response.data.messageError) {
                this.$swal(
                  "Une erreur est survenu !",
                  response.data.messageError.email[0],
                  "error"
                ).then(() => {
                  this.loaderBtn = false;
                });
                return;
              } else if (response.data.tooManyPasswordReset) {
                this.$swal(
                  "Une erreur est survenu !",
                  response.data.tooManyPasswordReset,
                  "error"
                ).then(() => {
                  this.loaderBtn = false;
                });

                return;
              } else {
                if (response.data == "passwords.sent") {
                  this.loaderBtn = false;
                  this.$swal(
                    "Envoie effectuer !",
                    "Nous avons envoyer un email pour que réinitialiser votre mot de passe !",
                    "success"
                  ).then(() => {
                    this.loaderBtn = false;
                    this.$router.push({ name: "Je m'authentifie" });
                  });
                } else {
                  this.$swal(
                    "Une erreur est survenu !",
                    "Nous n'avons pas pu envoyer le mail de réinitialisation, veuillez réessayer ultérieurement.",
                    "error"
                  ).then(() => {
                    this.loaderBtn = false;
                  });
                }
              }
            });
        } else {
          this.$swal(
            "Oups !",
            "L'adresse e-mail que vous avez saisie n'existe pas !",
            "error"
          );
        }
      });
    },
    returnBack() {
      this.$router.go(-1);
    },
  },
};
</script>

<style>
</style>