<template>
    <div>
        <v-container fluid>
            <v-row dense>
                <div v-if="folders.length == 0 && !loader && displayMessageNoFolder == true">
                    <p class="text-center fs-5 mt-5 text-break lead">
                        Vous n'avez pas encore de dossier !
                    </p>
                </div>
                <div v-else-if="folderFilter.length == 0 && !loader && displayMessageNoFolder == true">
                    <p class="text-center fs-5 mt-5 text-break lead">
                        Aucun dossier n'a été trouvé !
                    </p>
                </div>
                <v-col v-else v-for="folder in folderFilter" v-bind:key="folder.id" :cols="colsResponsive">
                    <v-card v-if="folders.length > 0" :elevation="11"
                        class="shadow-sm cardVuetify folder-hover mb-3 rounded-xl"
                        :id="'card-folder-' + folder.id" @click="openContentFolder(folder)">
                        <v-card-title class="bg-color-main">
                            <span class="badge bg-white rounded-circle m-1">
                                <v-icon class="text-color-main">mdi-folder</v-icon>
                            </span> Dossier
                            <v-spacer></v-spacer>
                            <v-icon class="text-danger" v-if="folder.hasPassword">mdi-key</v-icon>
                        </v-card-title>
                        <v-img class="bd-placeholder-img card-img-top" height="200" :src="folder.image_couverture">
                            <div class="card-img-overlay">
                                <span class="badge bg-primary text-right mb-2 m-1" v-if="folder.isOwn">CRÉER</span>
                                <span class="badge text-dark bg-warning text-right mb-2 m-1" v-else>REÇU</span>
                                <span class="badge bg-info text-right mb-2 m-1" v-if="folder.is_new">NOUVEAU</span>
                                <span class="badge purple text-right mb-2 m-1" v-if="folder.isPrivate">PRIVÉ</span>
                                <span class="badge teal darken-1 text-right mb-2 m-1" v-else>PUBLIC</span>
                                <v-icon dark v-if="folder.favoris" class="text-warning rounded-circle fs-1 p-1">
                                    mdi-star
                                </v-icon>
                            </div>
                        </v-img>


                        <v-card-subtitle class="text-start">
                            <v-avatar>
                                <v-img :src="folder.createur.photo"></v-img>
                            </v-avatar>&nbsp; Créer par <strong>{{ folder.createur.pseudo }}</strong>
                        </v-card-subtitle>
                        <v-card-title class="align-end text-break"><strong>{{ folder.titre }}</strong>
                        </v-card-title>
                        <v-card-text>
                            <div>
                                <v-chip style="background-color: #2596be" class="text-white m-1"
                                    v-for="mot_clef in folder.mot_clefs" v-bind:key="mot_clef">
                                    {{ mot_clef }}
                                </v-chip>
                            </div>
                            <hr />
                            <div>
                                <v-chip v-for="categorie in folder.categories" v-bind:key="categorie.id"
                                    class="text-dark m-1 bg-warning">
                                    {{ categorie.nom }}
                                </v-chip>
                            </div>
                        </v-card-text>
                    </v-card>
                </v-col>
            </v-row>
        </v-container>
    </div>
</template>

<script>
export default {
    props: ['folderFilter', 'folders', "displayMessageNoFolder", 'loader'],
    computed: {
        colsResponsive() {
            if (window.innerWidth < 800) {
                return 12;
            } else {
                return 3;
            }
        },
    },
    methods: {
        openContentFolder(folder) {
            this.$router.push({ name: "Contenu du dossier", params: { code_dossier: folder.code_dossier, slug: this.slugify(folder.titre, '-') } })
            this.idFolder = folder.id;
        },
    }
}
</script>

<style>

</style>