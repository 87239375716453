<template>
  <div>
    <div v-if="tutos.length > 0" class="container padding-bottom-tutos">
      <v-card-title>Mes tutos supprimées</v-card-title>

      <v-text-field v-model="search" clearable filled rounded prepend-inner-icon="mdi-magnify" class="mt-2 text-white"
        label="Rechercher un tuto supprimé"></v-text-field>

      <div class="d-flex justify-content-center p-3 mt-3">
        <v-btn class="text-white bg-danger" rounded block @click="modalDelete()">
          <v-icon>mdi-delete</v-icon>
          Vider la <br />
          corbeille
        </v-btn>
      </div>

      <div class="container">
        <v-btn outlined v-if="tutos.length < tutosMax" block class="mb-3" @click="loadMoreTutos()" color="primary"
          rounded>
          Charger plus
        </v-btn>
      </div>
      <div class="mt-5 mb-5" v-if="tutosFilter.length == 0 && !loader">
        <p class="text-center fs-5 text-break lead">
          Aucun tuto n'a été trouvé !
        </p>
      </div>
      <v-list rounded v-else v-for="tuto in tutosFilter" :key="tuto.id">
        <v-list-item-group color="primary">
          <v-menu absolute offset-y class="bg-transparent" transition="slide-y-transition" style="max-width: 600px">
            <template v-slot:activator="{ on, attrs }">
              <v-list-item v-bind="attrs" v-on="on">
                <v-list-item-content>
                  <v-list-item-title class="fw-bold fs-4" v-text="tuto.titre"></v-list-item-title>
                  <v-list-item-subtitle>Description : {{ tuto.description }}</v-list-item-subtitle>
                  <v-list-item-subtitle>Supprimé le
                    <strong>{{
                        formatDate(tuto.dateSuppression)
                    }}</strong>
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </template>
            <div dense class="bg-transparent">
              <v-list-item dark class="bg-success text-white" @click="restoreTuto(tuto)">
                <v-icon>mdi-file-restore-outline</v-icon> Restaurer le tuto
                ?
              </v-list-item>
              <v-list-item dark class="bg-danger text-white" @click="confirmDeleteTuto(tuto)">
                <v-icon>mdi-delete</v-icon> Supprimer définitivement ?
              </v-list-item>
            </div>
          </v-menu>
        </v-list-item-group>
      </v-list>
      <div v-if="loader">
        <v-skeleton-loader
          type="list-item-avatar, list-item-avatar, list-item-avatar, list-item-avatar, list-item-avatar, list-item-avatar">
        </v-skeleton-loader>
      </div>
    </div>
    <div v-else>
      <p class="text-center m-5">Votre corbeille de tuto est vide</p>
    </div>
    <dialog-confirm :titre="arrayDialog.titre" :message="arrayDialog.message"
      :functionCalled="arrayDialog.functionCalled" type="corbeille"></dialog-confirm>
  </div>
</template>

<script>
import DialogConfirm from '@/components/modal/DialogConfirm.vue';
import moment from "moment";
moment.locale("fr");
export default {
  components: {
    DialogConfirm
  },
  data() {
    return {
      valid: true,
      tutos: [],
      tutosFilter: [],
      nbTutosTotal: null,
      nbPartage: null,
      loader: true,
      tutosMax: 0,
      nbUsers: null,
      dialogRestore: false,
      dialogDelete: false,
      tutoSelect: [],
      items: [
        { id: 1, val: "Oui" },
        { id: 0, val: "Non" },
      ],
      limitTimeLine: 5,
      search: null,
      roles: [],
      arrayDialog: {
        titre: "",
        message: "",
        functionCalled: "",
      }
    };
  },
  props: ["loadData"],
  watch: {
    loadData() {
      this.getTutos();
    },
    search(val) {
      if (val) {
        if (val.length > 2) {
          this.$axios
            .get("tutos/tutoDeleted/search/" + val)
            .then((response) => {
              this.tutosFilter = response.data;
            });
        }
        if (val.length == 0) {
          this.tutosFilter = this.tutos;
        }
      } else {
        this.tutosFilter = this.tutos;
      }
    },
  },
  created() { },
   mounted() {
    this.getTutos();
  },
  methods: {
    getTutos() {
      this.$axios
        .get("/tutos/tutosDeleted/" + this.limitTimeLine)
        .then((response) => {
          this.tutos = response.data.tutos;
          this.tutosFilter = this.tutos;
          this.tutosMax = response.data.tutosMax;
          this.loader = false;
        });
    },
    modalDelete() {
      this.arrayDialog['message'] = "Voulez-vous vraiment vider la corbeille ?"
      this.arrayDialog['titre'] = "Que voulez-vous faire ?"
      this.arrayDialog['functionCalled'] = "clearTutos"
      this.$store.dispatch('modal/setOpen', 'corbeille')
    },
    clearTutos() {
      this.$axios.get("tutos/forceDeleteAllTutos").then((response) => {
        if (response.data.messageError) {
          this.$swal(
            "Une erreur est survenu !",
            response.data.messageError,
            "error"
          );
          this.$store.dispatch('modal/setClose', 'corbeille')
          return;
        }
        this.$swal({
          toast: true,
          title: response.data.message,
          icon: "success",
          animation: true,
          position: "top-right",
          showConfirmButton: false,
          timerProgressBar: true,
          timer: 2000,
        });
        this.$store.dispatch('modal/setClose', 'corbeille')
        this.getTutos();

      })

    },
    loadMoreTutos() {
      this.loader = true;
      setTimeout(() => {
        this.limitTimeLine = this.limitTimeLine + 10;
        this.getTutos();
      }, 1000);
    },
    onPageChange() {
      this.getTutos();
    },
    openModal(tuto) {
      this.dialogRestore = true;
      this.tutoSelect = tuto;
    },
    closeModal() {
      this.dialogRestore = false;
      this.tutoSelect = [];
    },
    restoreTuto(tuto) {
      this.$axios.get("tutos/restore/" + tuto.id).then((response) => {
        if (response.data.messageError) {
          this.$swal(
            "Une erreur est survenu !",
            response.data.messageError,
            "error"
          );
          return;
        }
        if (response.data.messagePremium) {
          this.$swal(
            "Limite de tuto atteinte !",
            response.data.messagePremium,
            "error"
          );
          return;
        }
        this.$swal({
          toast: true,
          title: response.data.message,
          icon: "success",
          animation: true,
          position: "top-right",
          showConfirmButton: false,
          timerProgressBar: true,
          timer: 2000,
        });

        this.dialogRestore = false;
        this.getTutos();
      });
    },
    confirmDeleteTuto(tuto) {
      this.$axios.get("tutos/forceDelete/" + tuto.id).then((response) => {
        if (response.data.messageError) {
          this.$swal(
            "Une erreur est survenu !",
            response.data.messageError,
            "error"
          );
          return;
        }
        this.$swal({
          toast: true,
          title: response.data.message,
          icon: "success",
          animation: true,
          position: "top-right",
          showConfirmButton: false,
          timerProgressBar: true,
          timer: 2000,
        });
        this.dialogDelete = false;
        this.dialogRestore = false;
        this.getTutos();
      });
    },
  },
};
</script>

<style>

</style>