/* eslint-disable */
export default {
    computed: {
        currentRouteName() {
            return this.$route.name;
        },
        isDarkMode() {
            return this.$vuetify.theme.dark;
        },
        isMobile() {
            return this.$vuetify.breakpoint.mobile;
        },
    },
};