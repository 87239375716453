<template>
  <div>
    <div class="container flex-center position-ref full-height">
      <v-card class="rounded-xl" width="700">
        <v-card-title>Changer votre mot de passe</v-card-title>
        <v-form v-model="validPassword">
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="12">
                  <v-text-field v-model="password" rounded filled :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                    :rules="passwordRules" :type="show1 ? 'text' : 'password'" name="input-10-1"
                    label="Tapez un nouveau mot de passe" hint="Le mot de passe doit faire au minimum 6 caractères"
                    counter @click:append="show1 = !show1"></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-text-field block v-model="verify" rounded filled :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                    :rules="[rules.requiredRules, passwordMatch]" :type="show1 ? 'text' : 'password'" name="input-10-1"
                    label="Confirmer le nouveau mot de passe" counter @click:append="show1 = !show1"></v-text-field>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn class="bg-color-main text-white m-1" rounded :disabled="!validPassword" @click="resetPassword()">
              <v-icon>mdi-content-save</v-icon> Sauvegarder mon nouveau mot de passe
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </div>
  </div>
</template>
<script>
export default {
  metaInfo() {
    return {
      title: "Changer mon mot de passe | Pocketuto ",
      meta: [
        { vmid: 'description', name: 'description', content: "Changer le mot de passe de votre compte Pocketuto" }
      ]
    }
  },
  data() {
    return {
      token: null,
      email: null,
      password: null,
      has_error: false,
      validPassword: true,
      verify: "",
      typeTuto: null,
      show1: false,
      requiredRules: [(v) => !!v || "Le champ est obligatoire"],
      numberRules: [
        (v) => !!v || "Le champ est obligatoire",
        (v) => !isNaN(v) || "Ce n'est pas un nombre",
      ],
      minRules: [
        (v) =>
          (v && v.length >= 6) ||
          "Le mot de passe doit faire au minimum 6 caractères",
      ],
      passwordRules: [
        value => !!value || 'Taper un mot de passe s\'il vous plait',
        (value) => !!value || 'Le champ mot de passe est obligatoire',
        (value) => (value && /\d/.test(value)) || 'Le mot de passe doit contenir au moins un nombre',
        (value) => (value && /[A-Z]{1}/.test(value)) || 'Le mot de passe doit contenir au moins une majuscule',
        (value) => (value && /[^A-Za-z0-9]/.test(value)) || 'Le mot de passe doit contenir au moins un caractere spécial',
        (value) => (value && value.length > 6) || 'Le mot de passe doit faire au moins 6 caractères minimum',
      ],
      emailRules: [
        (v) => !!v || "Le champ doit être remplis",
        (v) => /.+@.+\..+/.test(v) || "L'adresse email est invalide",
      ],
      passwordConfirm: [
        (v) =>
          this.validatePassword(v) || "Les mots de passes ne concorde pas !",
      ],
      rules: {
        requiredRules: (v) => !!v || "Le champ est obligatoire",
        minRules: (v) =>
          (v && v.length >= 6) ||
          "Le mot de passe doit faire au minimum 6 caractères",
      },
      numberRulesNoObligatory: [(v) => !isNaN(v) || "Ce n'est pas un nombre"],
    };
  },
  computed: {
    passwordMatch() {
      return () =>
        this.password === this.verify ||
        "Les mot de passes ne sont pas les mêmes";
    },
  },
  methods: {
    resetPassword() {
      this.$axios
        .post("password/form/reset/password", {
          token: this.$route.query.token,
          email: this.$route.query.email,
          password: this.password,
          password_confirmation: this.password_confirmation,
        })
        .then(
          (response) => {
            if (response.data.apiCode) {
              this.$swal(
                "Oups !",
                "Une erreur est survenue avec la modification du mot de passe, veuillez réessayer !",
                "error"
              );
              return;
            }
            if (response.data.status) {
              this.$swal({
                toast: true,
                title: "Votre mot de passe a bien été modifié !",
                icon: "success",
                animation: true,
                position: "top-right",
                showConfirmButton: false,
                timerProgressBar: true,
                timer: 2000,
              });

              this.$router.push({ name: "Je m'authentifie" });

              return;
            } else {
              this.$swal(
                "Erreur !",
                "Une erreur est survenue, veuillez réessayer ultérieurement !",
                "error"
              );
            }
          },
          (error) => {
            console.error(error);
          }
        );
    },
  },
};
</script>
<style>
.flex-center {
  align-items: center;
  display: flex;
  justify-content: center;
}

.full-height {
  height: 80vh;
}
</style>