<template>
  <div :class="(isDarkMode) ? 'bg-dark' : 'bg-white'" class="p-3">
    <div>

      <div align="center">
        <v-img width="500" v-if="!isDarkMode" src="@/assets/logo-pocketuto/logo-text-bleu-blanc.png"></v-img>
        <v-img width="500" v-else src="@/assets/logo-pocketuto/logo-text-blanc-dark.png"></v-img>
      </div>

      <div class="p-5">
        <vue-typed-js :loop="false" class="d-flex justify-content-center" :typeSpeed="50"
          :strings="['Quel meilleur moyen d\'expliquer  qu\'avec un tuto ?', 'J\'écris un tuto, je le partage, je répète.', 'Des centaines de notes pour mes process perso.', 'Mes cours écrits et sauvegardés dans Pocketuto !', 'Des tutos pour ma communauté !']">
          <h1 style="font-size: 30px" class="text-center typing"></h1>
        </vue-typed-js>
      </div>

    </div>
    <v-container>
      <div class="row featurette p-4 mt-5">
        <div class="col-md-6">
          <h2 class="featurette-heading fw-bold mt-5 text-break">
            Voici un exemple de tuto !
          </h2>
          <p class="lead text-break">
            En cliquant sur le tuto ci-contre, ayez un aperçu de l'apparence de nos tutos ! <br>
            Grâce à notre structure en block, vous pouvez créer des tutoriels de façon simple et organisée ! <br>
          </p>
        </div>
        <div class="col-md-6">
          <v-card :elevation="11" width="500" class="shadow-sm cardVuetify card-hover mb-3 rounded-xl"
            :id="'card-tuto-' + tuto.id" @click="openContentTuto(tuto)">
            <v-card-title>
              <span class="badge bg-color-main rounded-circle m-1">
                <v-icon class="text-white">mdi-file-document</v-icon>
              </span>
              Tuto
              <v-spacer></v-spacer>
            </v-card-title>
            <v-img class="bd-placeholder-img card-img-top" height="200" :src="tuto.image_couverture">
              <div class="card-img-overlay">
                <span class="badge teal darken-1 text-right mb-2 m-1">PUBLIC</span>
              </div>
            </v-img>
            <v-card-title class="align-end text-break">
              <strong>{{ tuto.titre }}</strong>
            </v-card-title>
            <v-card-text>
              <div>
                <v-chip style="background-color: #2596be" class="text-white m-1" v-for="mot_clef in tuto.mot_clefs"
                  v-bind:key="mot_clef">
                  {{ mot_clef }}
                </v-chip>
              </div>
              <hr />
              <div>
                <v-chip v-for="categorie in tuto.categories" v-bind:key="categorie.id" class="text-dark m-1 bg-warning">
                  {{ categorie.nom }}
                </v-chip>
              </div>
            </v-card-text>
          </v-card>
        </div>
      </div>
      <Recherche :isNotPageAccueil="false"></Recherche>

    </v-container>
    <!--  <Adsense data-ad-client="ca-pub-1161098494078530" data-ad-format="auto" data-ad-slot="3922399102">
    </Adsense> -->
    <v-container class="mt-5">
      <div class="row featurette p-5">
        <div class="col-md-7">
          <h2 class="featurette-heading fw-bold">
            Créer des tutos de toutes sortes pour vos proches ou une
            communauté !
          </h2>
          <p class="lead">
            L'avantage de Pocketuto est la rapidité de création des tutos et
            de partage de ceux-ci avec vos ami(e)s. <br />
            Vous pouvez créer des dossiers contenant autant de tutos que vous
            voulez afin de les partager également !
          </p>
        </div>
        <div class="col-md-5">
          <v-img src="/img/accueil/3.jpg"></v-img>
        </div>
      </div>


      <div class="row featurette p-5">
        <div class="col-md-7 order-md-2">
          <h2 class="featurette-heading fw-bold">
            De façon simple et sécurisée ! <br />
            <span>En quelques secondes !</span>
          </h2>
          <p class="lead">
            Vous pouvez définir la visibilité d'un tuto en privée ou publique
            et changer à tout moment !
          </p>
          <p class="lead">
            Gérer les droits d'accès à vos tutos et dossiers après les avoir
            envoyés.
          </p>
          <p class="lead">
            Définissez dans vos paramètres de confidentialité !
          </p>
        </div>
        <div class="col-md-5 order-md-1">
          <v-img src="/img/accueil/2.jpg"></v-img>
        </div>
      </div>



      <div class="row featurette p-5">
        <div class="col-md-7">
          <h2 class="featurette-heading fw-bold">Sur tous vos appareils !</h2>
          <p class="lead">
            Consulter vos tutos et ceux que vous avez reçus aussi bien depuis
            votre téléphone, tablette et ordinateur !
          </p>
          <p class="lead">
            L'application peut être ajoutée à l'écran d'accueil de votre
            téléphone et s'ouvrir en plein écran et non sur votre navigateur.
          </p>
          <p class="lead">
            Accepter simplement la proposition de votre navigateur vous disant
            d'ajouter le site à l'écran d'accueil.
          </p>
        </div>
        <div class="col-md-5">
          <v-img src="/img/accueil/4.jpg"></v-img>
        </div>
      </div>



      <div class="row featurette p-5">
        <div class="col-md-7 order-md-2">
          <h2 class="featurette-heading fw-bold">
            Commencez dès maintenant et créer et partager un max de tutos !<br />
          </h2>
          <p class="lead">
            Qu'est-ce que vous attendez pour venir tester Pocketuto ? On vous
            attend ! :=)
          </p>
          <div class="text-center" style="margin-top: 80px">
            <v-btn rounded x-large :to="{ name: 'Je m\'authentifie' }" class="bg-color-main text-white">JE VEUX TESTER
              POCKETUTO !</v-btn>
          </div>
        </div>
        <div class="col-md-5 order-md-1">
          <v-img src="/img/accueil/1.jpg"></v-img>
        </div>
      </div>

    </v-container>
  </div>
</template>

<script>
import { VueTypedJs } from "vue-typed-js";
import Recherche from "@/components/navigation/Recherche.vue";
export default {
  metaInfo() {
    return {
      title: "Accueil | Pocketuto ",
      meta: [
        {
          vmid: "description",
          name: "description",
          content:
            "Pocketuto est une application permettant de créer et de regrouper en un seul endroit toutes vos connaissances grâce à la création de tuto",
        },
      ],
    };
  },
  components: {
    VueTypedJs,
    Recherche,
  },
  data() {
    return {
      icons: ["mdi-facebook", "mdi-linkedin", "mdi-instagram"],
      search: "",
      typeSearch: "",
      disableBtnSearch: false,
      editedItem: [],
      btnLoadPDF: null,
      keywords: [],
      loader: false,
      idTuto: null,
      items: ["tuto", "créateur"],
      user: [],
      dialogTuto: false,
      overlay: false,
      tutos: [],
      tuto: [],
      categories: [],
      labelPerso: "Rechercher un tuto ...",
    };
  },
  mounted() {
    this.overlay = true;
    this.exempleTuto();
  },
  computed: {
    chipResponsive() {
      if (window.innerWidth < 800) {
        return 3;
      } else {
        return 8;
      }
    },
    colsResponsive() {
      if (window.innerWidth < 800) {
        return 12;
      } else {
        return 3;
      }
    },
  },
  methods: {
    searchByKeyWords(val) {
      this.search = val;
      this.searchTuto();
    },
    openTuto(tuto) {
      this.editedItem = tuto;
      this.idTuto = tuto.id;
      this.dialogTuto = true;
    },
    exempleTuto() {
      this.loader = true;
      this.$axios.get("/discover/exempleTuto").then((response) => {
        if (response.data.messageError) {
          this.$swal({
            toast: true,
            title: response.data.messageError,
            icon: "error",
            animation: true,
            position: "center",
            showConfirmButton: false,
            timerProgressBar: true,
            timer: 2000,
          });
          this.loader = false;
          return;
        }
        this.tuto = response.data;
        this.loader = false;
      });
    },
    recherche() {
      if (
        this.search == "" ||
        this.search == null ||
        !this.search.replace(/\s/g, "").length
      ) {
        this.$swal({
          toast: true,
          title: "Veuillez écrire quelque chose avant de rechercher !",
          icon: "warning",
          animation: true,
          position: "center",
          showConfirmButton: false,
          timerProgressBar: true,
          timer: 2000,
        });
        this.search = "";
      } else {
        this.searchTuto();
      }
    },
    openContentTuto(tuto) {
      this.$router.push({
        name: "Contenu du tuto",
        params: {
          code_tuto: tuto.code_tuto,
          slug: this.slugify(tuto.titre, "-"),
        },
      });
      this.idTuto = tuto.id;
    },
    searchTuto() {
      this.loader = true;
      this.$axios
        .get("/discover/rechercheTuto/" + this.search)
        .then((response) => {
          if (response.data.messageError) {
            this.$swal({
              toast: true,
              title: response.data.messageError,
              icon: "error",
              animation: true,
              position: "center",
              showConfirmButton: false,
              timerProgressBar: true,
              timer: 2000,
            });
            this.loader = false;
            return;
          }
          this.tutos = response.data;
          this.loader = false;
        });
    },
  },
};
</script>

<style></style>