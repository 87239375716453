<template>
  <div>
    <v-container>
      <v-card class="rounded-xl pb-5">
        <div class="d-flex justify-content-end">
          <v-btn class="bg-color-main m-3" rounded @click="redirectTo('Créer un ticket')" v-if="notDisplayBtn">
            <v-icon>mdi-ticket-outline</v-icon> Créer un ticket
          </v-btn>
        </div>
        <v-data-table no-data-text="Aucun ticket n'a été créé" mobile-breakpoint="0" class="rounded-xl"
          style="cursor:pointer" @click:row="watchTicket($event)" :headers="headers" :items="tickets"
          :items-per-page="10" hide-default-footer>
          <template v-slot:item.status="{ item }">
            <v-chip :color="colorStatus(item.status['id'])">{{ item.status['libelle'] }}</v-chip>
          </template>
        </v-data-table>
        <v-pagination v-if="tickets.length > 0" @input="onPageChange()" class="mt-5" v-model="pagination.current"
          :length="pagination.total" circle></v-pagination>
      </v-card>
    </v-container>
  </div>
</template>

<script>
export default {
  data() {
    return {
      headers: [
        { text: 'Titre', value: 'titre' },
        { text: 'Type de problème', value: 'type_probleme' },
        { text: 'Status', value: 'status' },
        { text: 'Date', value: 'dateCreation' },
      ],
      pagination: {
        current: 1,
        total: 1,
      },
    }
  },
  computed: {
    tickets: {
      get() {
        return this.$store.getters['tickets/getTickets']
      },
      set(val) {
        return val
      }
    },
    notDisplayBtn() {
      var find = this.tickets.find(t => t.status['id'] == 0 || t.status['id'] == 1)
      if (find) {
        return false
      } else {
        return true
      }
    }
  },
  activated() {
    this.getTickets()
  },
  methods: {
    getTickets() {
      this.$store.dispatch('tickets/setTickets', this.pagination.current).then((response) => {
        this.tickets = response.data.tickets
        this.pagination.total = response.data.total
        this.pagination.current = response.data.current
      })
    },
    onPageChange() {
      this.getTickets()
    },
    colorStatus(status) {
      switch (status) {
        case 0:
          return 'grey'

        case 1:
          return 'green'

        case 2:
          return 'red'


        default:
          break;
      }
    },
    watchTicket(ticket) {
      this.$router.push({ name: "Contenu du ticket", params: { idTicket: ticket.id } })
    },
    redirectTo(path) {
      this.$router.push({ name: path })
    }
  }
}
</script>

<style>

</style>