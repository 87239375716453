<template>
    <div>
        <v-container class="mt-5">
            <v-form lazy-validation v-model="valid">
                <v-text-field rounded filled v-model="editedItem.titre" :counter="50" :maxlength="50" :rules="requiredRules" label="Titre du ticket"
                    required>
                </v-text-field>
                <v-select rounded filled v-model="editedItem.type_probleme"
                    :items="['Problème lié à un tuto', 'Problème lié à un dossier', 'Achat en boutique', 'Gestion des ami(e)s', 'Problème dans les paramètres', 'Problème avec la corbeille', 'Onglet recherche', 'Problème avec les notifications', 'Erreur rencontrée dans le profil', 'Problème avec les abonnements', 'Autres']"
                    :rules="requiredRules" label="Type de problème rencontré" required>
                </v-select>
                <v-textarea :counter="2000" :maxlength="2000" auto-grow rounded filled v-model="editedItem.explications"
                    :rules="[rules.requiredRules, rules.minRules]" label="Explication de votre problème" required>
                </v-textarea>
                <div class="d-flex justify-content-end">
                    <v-btn @click="storeTicket()" class="bg-color-main" rounded :loading="loadingBtn"
                        :disabled="!valid">
                        <v-icon>mdi-send</v-icon>Envoyer le ticket
                    </v-btn>
                </div>

            </v-form>
        </v-container>

    </div>
</template>
  
<script>
export default {
    data() {
        return {
            valid: true,
            loadingBtn: false,
            editedItem: {
                titre: "",
                type_probleme: "",
                explications: "",
            },
            requiredRules: [(v) => !!v || "Le champ est obligatoire"],
            numberRules: [
                (v) => !!v || "Le champ est obligatoire",
                (v) => !isNaN(v) || "Ce n'est pas un nombre",
            ],
            emailRules: [
                (v) => !!v || "Le champ doit être rempli",
                (v) => /.+@.+\..+/.test(v) || "L'adresse email est invalide",
            ],
            passwordConfirm: [
                (v) =>
                    this.validatePassword(v) || "Les mots de passes ne concorde pas !",
            ],
            rules: {
                requiredRules: (v) => !!v || "Le champ est obligatoire",
                minRules: (v) =>
                    (v && v.length >= 10) ||
                    "Le champ doit faire au minimum 10 caractères",
            },
            numberRulesNoObligatory: [(v) => !isNaN(v) || "Ce n'est pas un nombre"],
        }
    },
    methods: {
        verifForm() {
            if(this.editedItem.titre != '' && this.editedItem.type_probleme != '' && this.editedItem.explications != '') {
                return true
            } else {
                return false
            }
        },
        storeTicket() {
            if(this.verifForm()) {
                this.loadingBtn = true;
            this.$store.dispatch('tickets/addTicket', this.editedItem).then((response) => {
                if (response.data.messageError) {
                    this.$swal({
                        toast: true,
                        icon: "error",
                        timer: 3000,
                        title: response.data.messageError,
                        position: "top-right",
                        showConfirmButton: false,
                    });
                    this.loadingBtn = false
                    return;
                }
                this.$swal({
                    toast: true,
                    icon: "info",
                    timer: 3000,
                    title: response.data.message,
                    position: "top-right",
                    showConfirmButton: false,
                });
                this.loadingBtn = false
                this.$router.push({ name: 'Support' })
            })
            } else {
                this.$swal({
                    toast: true,
                    icon: "error",
                    timer: 3000,
                    title: "Vous devez remplir tous les champs avant d'envoyer votre ticket !",
                    position: "top-right",
                    showConfirmButton: false,
                });
            }
        }
    }
}
</script>
  
<style>

</style>