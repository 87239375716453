<template>
  <div class="mt-10">
    <h1 class="text-center lead fs-3">Liste de vos tickets</h1>
    <liste-tickets></liste-tickets>
  </div>
</template>

<script>
import ListeTickets from '@/components/support/ListeTickets.vue'
export default {
  metaInfo() {
    return {
      title:"Support | Pocketuto ",
      meta: [
        { vmid: 'description', name: 'description', content: "Support afin de créer des tickets" }
      ]
    }
  },
  components: { ListeTickets },

}
</script>

<style>

</style>