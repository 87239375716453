<template>
  <div class="padding-bottom-tutos">
    <v-container fluid>
      <v-row dense>
        <div v-if="
          tutos.length == 0 && !loaderPage && displayMessageNoTuto == true
        ">
          <p class="text-center fs-5 mt-5 text-break lead">
            Vous n'avez pas encore de tutos !
          </p>
        </div>
        <div v-else-if="
          tutosFilter.length == 0 &&
          !loaderPage &&
          displayMessageNoTuto == true
        ">
          <p class="text-center fs-5 mt-5 text-break lead">
            Aucun tuto n'a été trouvé !
          </p>
        </div>

        <v-col v-else v-for="tuto in tutosFilter" v-bind:key="tuto.id" :cols="colsResponsive">
          <v-card v-if="tutos.length > 0" :elevation="11" class="shadow-sm cardVuetify card-hover mb-3 rounded-xl"
            :id="'card-tuto-' + tuto.id" @click="openContentTuto(tuto)">
            <v-card-title>
              <span class="badge bg-color-main rounded-circle m-1">
                <v-icon class="text-white">mdi-file-document</v-icon>
              </span>
              Tuto
              <v-spacer></v-spacer>
              <v-icon class="text-danger" v-if="tuto.hasPassword">mdi-key</v-icon>
            </v-card-title>
            <v-img class="bd-placeholder-img card-img-top" height="200" :src="tuto.image_couverture">
              <div class="card-img-overlay">
                <span class="badge bg-primary text-right mb-2 m-1" v-if="tuto.isOwn">CRÉER</span>
                <span class="badge text-dark bg-warning text-right mb-2 m-1" v-else>REÇU</span>
                <span class="badge bg-info text-right mb-2 m-1" v-if="tuto.is_new">NOUVEAU</span>
                <span class="badge purple text-right mb-2 m-1" v-if="tuto.isPrivate">PRIVÉ</span>
                <span class="badge teal darken-1 text-right mb-2 m-1" v-else>PUBLIC</span>
                <v-icon dark v-if="tuto.favoris" class="text-warning rounded-circle fs-1 p-1">
                  mdi-star
                </v-icon>
              </div>
            </v-img>
            <v-card-subtitle class="text-start">
              <v-avatar>
                <v-img :src="tuto.createur.photo"></v-img> </v-avatar>&nbsp;Créer par <strong>{{
                  tuto.createur.pseudo
                }}</strong>

            </v-card-subtitle>
            <v-card-title class="align-end text-break">

              <strong>{{ tuto.titre }}</strong>
            </v-card-title>
            <v-card-text>
              <div>
                <v-chip style="background-color: #2596be" class="text-white m-1" v-for="mot_clef in tuto.mot_clefs"
                  v-bind:key="mot_clef">
                  {{ mot_clef }}
                </v-chip>
              </div>
              <hr />
              <div>
                <v-chip v-for="categorie in tuto.categories" v-bind:key="categorie.id" class="text-dark m-1 bg-warning">
                  {{ categorie.nom }}
                </v-chip>
              </div>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>
<script>
import infiniteScroll from "vue-infinite-scroll";
import moment from "moment";
moment.locale("fr");
export default {
  directives: {
    infiniteScroll,
  },
  props: [
    "tutosFilter",
    "tutos",
    "categories",
    "loaderPage",
    "displayMessageNoTuto",
  ],
  computed: {
    colsResponsive() {
      if (window.innerWidth < 800) {
        return 12;
      } else {
        return 3;
      }
    },
  },
  methods: {
    openContentTuto(tuto) {
      this.$router.push({
        name: "Contenu du tuto",
        params: {
          code_tuto: tuto.code_tuto,
          slug: this.slugify(tuto.titre, "-"),
        },
      });
      this.idTuto = tuto.id;
    },
  },
};
</script>

<style >
.padding-bottom-tutos {
  padding-bottom: 100px;
}

.card-hover {
  cursor: pointer;
}

.bd-placeholder-img {
  font-size: 1.125rem;
  text-anchor: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
}

.cardVuetify {
  box-shadow: 0 6px 10px rgba(0, 0, 0, 0.08), 0 0 6px rgba(0, 0, 0, 0.05);
  transition: 0.3s transform cubic-bezier(0.155, 1.105, 0.295, 1.12),
    0.3s box-shadow,
    0.3s -webkit-transform cubic-bezier(0.155, 1.105, 0.295, 1.12);
  cursor: pointer;
}

.cardVuetify:hover {
  z-index: 3;
  transform: scale(1.02);
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.12), 0 4px 8px rgba(0, 0, 0, 0.06);
}

@media (max-width: 900px) {
  .cardVuetify {
    box-shadow: 0 6px 10px rgba(0, 0, 0, 0.08), 0 0 6px rgba(0, 0, 0, 0.05);
    transition: 0.3s transform cubic-bezier(0.155, 1.105, 0.295, 1.12),
      0.3s box-shadow,
      0.3s -webkit-transform cubic-bezier(0.155, 1.105, 0.295, 1.12);
    cursor: pointer;
    min-height: auto;
  }
}
</style>