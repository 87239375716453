/* eslint-disable */
import axios from 'axios'
export default {
    namespaced: true,
    state: {
        dialog: [],
    },
    mutations: {
        setDialog(state, type) {
            var newForm = {}
            newForm['type'] = type
            newForm['show'] = false
            if (state.dialog.length == 0) {
                state.dialog.push(newForm);
            } else {
                if (state.dialog.find(d => d.type === type)) {
                    state.dialog.find(d => d.type === type)['show'] = false
                } else {
                    state.dialog.push(newForm);
                }
            }
        },
        setOpen(state, type) {
            state.dialog.find(d => d.type == type)['show'] = true
        },
        setClose(state, type) {
            state.dialog.find(d => d.type == type)['show'] = false
        }
    },
    getters: {
        dialog: (state) => (type) => {
            return state.dialog.find(d => d.type == type)
        }
    },
    actions: {
        setDialog({ commit }, type) {
            return new Promise((resolve, reject) => {
                commit('setDialog', type)
                resolve(true)
            })
        },
        setOpen({ commit }, type) {
            commit('setOpen', type)
        },
        setClose({ commit }, type) {
            commit('setClose', type)
        }
    }
}