/* eslint-disable */
import axios from 'axios'
import router from "/src/router";
export default {
    namespaced: true,
    state: {
        tutos: [],
        tutosCreateurs: [],
        maxTutosCreateurs: 0,
        dataLoadTutosCreateurs: false,
        dataLoad: false,
        displayTypeTutos: null,
        maxTutos: 0,
    },
    mutations: {
        SET_TUTOS(state, datas) {
            state.tutos = datas
        },
        SET_DATALOAD(state, data) {
            state.dataLoad = data
        },
        SET_DISPLAY_TYPE_TUTOS(state, data) {
            state.displayTypeTutos = data
        },
        SET_MAX_TUTOS(state, data) {
            state.maxTutos = data
        },
        SET_TUTOS_CREATEURS(state, datas) {
            state.tutosCreateurs = datas
        },
        SET_MAX_TUTOS_CREATEURS(state, data) {
            state.maxTutosCreateurs = data
        },
        SET_DATALOAD_TUTOS_CREATEURS(state, data) {
            state.dataLoadTutosCreateurs = data
        },
        ADD_TUTO(state, datas) {
            state.tutos.push = datas
        },
        EDIT_TUTO(state, data) {
            state.tutos = state.tutos.map(tuto => {
                if (tuto.id === data.id) {
                    return Object.assign({}, tuto, data)
                }
            })
        },
        DELETE_TUTO(state, id) {
            var index = state.tutos.findIndex(tuto => tuto.id == id)
            state.tutos.splice(index, 1)
        },
    },
    getters: {
        getTutos(state) {
            return state.tutos
        },
        getMaxTutos(state) {
            return state.maxTutos
        },
        getTutosCreateurs(state) {
            return state.tutosCreateurs
        },
        getMaxTutosCreateurs(state) {
            return state.maxTutosCreateurs
        },
        dataLoad(state) {
            return state.dataLoad
        },
        dataLoadTutosCreateur(state) {
            return state.dataLoadTutosCreateurs
        },
        displayTypeTutos(state) {
            return state.displayTypeTutos
        },
    },
    actions: {
        setTutos({ commit }, payload) {
            return new Promise((resolve, reject) => {
                axios
                    .post("/tutos/index", payload)
                    .then((response) => {
                        if (response.data.messageError) {
                            resolve(response)
                        }
                        commit('SET_TUTOS', response.data.tutos)
                        commit('SET_MAX_TUTOS', response.data.maxTutos)
                        commit('SET_DISPLAY_TYPE_TUTOS', response.data.displayTypeTutos)
                        commit('SET_DATALOAD', true)
                        resolve(response)
                    })
                    .catch(function(error) {
                        if (error.response.status === 401) {
                            setTimeout(() => {
                                router.push({ name: "Authentification" });
                            }, 1200);
                        }
                    });
            })

        },
        addTuto({ commit, dispatch }, tutos) {
            return new Promise((resolve, reject) => {
                axios.post("/tutos/store", tutos).then((response) => {
                    if (response.data.messageError) {
                        resolve(response)
                    }
                    commit("ADD_TUTO", response.data.tuto)
                    resolve(response)
                    var payload = {
                        limitTimeLine: 20,
                        displayTypeTutos: 1
                    }
                    dispatch("setTutos", payload)
                })
            })
        },
        editTuto({ commit, dispatch }, payload) {
            return new Promise((resolve, reject) => {
                axios({
                        method: 'post',
                        url: '/tutos/update/' + payload['idTuto'],
                        data: payload['editedItem'],
                        headers: { 'Content-Type': 'multipart/form-data' }
                    })
                    .then((response) => {
                        if (response.data.messageError) {
                            resolve(response)
                        }
                        commit("EDIT_TUTO", response.data.tuto)
                        resolve(response)
                        var payload = {
                            limitTimeLine: 20,
                            displayTypeTutos: 1
                        }
                        dispatch("setTutos", payload)
                    });
            })
        },
        toggleFavTuto({ commit }, idTuto) {
            return new Promise((resolve, reject) => {
                axios.get("/tutos/fav/" + idTuto).then((response) => {
                    if (response.data.messageError) {
                        resolve(response)
                    }
                    resolve(response)
                });
            })
        },
        deleteTutoSave({ commit }, idTuto) {
            return new Promise((resolve, reject) => {
                axios.delete("/tutos/delete/" + idTuto).then((response) => {
                    if (response.data.messageError) {
                        resolve(response)
                    }
                    commit('DELETE_TUTO', idTuto)
                    resolve(response)
                });
            })
        },
        deleteTutoReceive({ commit }, idTuto) {
            return new Promise((resolve, reject) => {
                axios.delete("/tutos/deleteForMe/" + idTuto).then((response) => {
                    if (response.data.messageError) {
                        resolve(response)
                    }
                    commit('DELETE_TUTO', idTuto)
                    resolve(response)
                });
            })
        },
    }
}