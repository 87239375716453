<template>
    <div v-if="load">
        <v-dialog v-model="dialog.show" width="600" :retain-focus="false" persistent >
            <v-card class="rounded-xl">
                <v-toolbar class="mb-5 bg-color-main" dark>
                    <v-toolbar-title>
                        {{ titre }}
                    </v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-icon @click="$store.dispatch('modal/setClose')">mdi-close</v-icon>
                </v-toolbar>
                <v-card-text class="container lead fs-5 text-h5">
                    {{ message }}
                </v-card-text>
                <v-card-actions class="d-flex justify-content-end">
                    <v-btn rounded class="bg-main text-dark" @click="emitAction(1)">Oui</v-btn>
                    <v-btn rounded class="bg-color-main text-white" @click="emitAction(0)">Non</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
export default {
    props: ['titre', 'message', 'functionCalled', 'type'],
    data() {
        return {
            load: false
        }
    },
     mounted() {
        this.$store.dispatch('modal/setDialog', this.type).then((resp) => {
            this.load = resp
        })
    },
    computed: {
        dialog() {
            return this.$store.getters['modal/dialog'](this.type)
        }
    },
    methods: {
        emitAction(value) {
            if(value) {
                this.$parent[this.functionCalled]()
            } else {
                this.$store.dispatch('modal/setClose', this.type)
            }
            this.$store.dispatch('modal/setClose', this.type)
        }
    }
}
</script>

<style>

</style>