<template>
  <v-card class="elevation-12">
    <v-card-text>
      <v-form v-model="valid">
        <v-text-field prepend-inner-icon="mdi-account" class="mt-3" name="login" autocomplete="on" filled
          v-model="form.email" label="Adresse e-mail" type="text" :rules="emailRules" rounded
          :error-messages="msgError"></v-text-field>
        <v-text-field prepend-inner-icon="mdi-lock" v-model="form.password" rounded autocomplete="on" name="password"
          v-on:keydown.enter.prevent="submit()" :rules="requiredRules" label="Mot de passe" filled type="password"
          :error-messages="msgError"></v-text-field>
      </v-form>
      <v-checkbox v-model="rememberMe" label="Se souvenir de moi ?"></v-checkbox>
    </v-card-text>
    <v-card-actions>
      <button class="btn-link" @click="sendPasswordLink()">
        Mot de passe oublié ?
      </button>
      <v-spacer></v-spacer>
      <v-btn class="bg-color-main text-white" rounded :loading="loader" @click="submit()">
        Connexion<v-icon>mdi-login-variant
        </v-icon>
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import { mapActions } from "vuex";
export default {
  data() {
    return {
      loader: false,
      form: {
        email: "",
        password: "",
        userIdOneSignal: "",
      },
      emailRules: [
        (v) => !!v || "Le champ doit être remplis",
        (v) => /.+@.+\..+/.test(v) || "L'adresse email est invalide",
      ],
      requiredRules: [(v) => !!v || "Le champ est obligatoire"],
      error: false,
      msgError: "",
      validation: "",
      valid: true,
      rememberMe: false,
    };
  },
   mounted() {
    this.form.email = localStorage.getItem("email");
    this.rememberMe = localStorage.getItem("rememberMe");
    window.OneSignal.push(() => {
      window.OneSignal.getUserId((userId) => {
        this.form.userIdOneSignal = userId;
      });
    });
  },
  computed: {
    email() {
      return this.form.email;
    },
    password() {
      return this.form.password;
    },
  },
  watch: {
    rememberMe(val) {
      if (val) {
        localStorage.setItem("email", this.form.email);
        localStorage.setItem("rememberMe", this.rememberMe);
      } else {
        localStorage.removeItem("email", this.form.email);
        localStorage.removeItem("rememberMe", this.rememberMe);
      }
    },
  },
  methods: {
    ...mapActions({
      login: "auth/login",
    }),
    submit() {
      this.loader = true;
      this.login(this.form).then((response) => {
        if (response) {
          if (response.data.messageError) {
            this.$swal("Oups !", response.data.messageError, "error").then(
              () => {
                this.error = true;
                this.msgError = response.data.messageError;
                this.validation = "is-invalid";
                this.loader = false;
              }
            );
          } else if (response.data.code == 498) {
            this.error = true;
            this.loader = false;
            this.msgError = response.data.messageError;
            this.validation = "is-invalid";
          }
        }
        this.loader = false;
        if (response.data.user.email_verified_at) {
          this.$router.push({ name: "Tutos" }).then(() => {
            document.location.reload();
          });
        } else {
          this.$router.push({ name: "Compte en attente de validation" }).then(() => {
            document.location.reload();
          });
        }

      });
    },
    sendPasswordLink() {
      this.$router.push({ name: "Mot de passe oublié" });
    },
  },
};
</script>

<style scoped>
@media screen and (max-height: 575px) {

  #rc-imageselect,
  .g-recaptcha {
    transform: scale(0.8);
    transform-origin: 0;
    transform: scale(0.8);
    transform-origin: 0 0;
    -webkit-transform: scale(0.8);
    transform: scale(0.8);
    -webkit-transform-origin: 0 0;
    transform-origin: 0 0;
  }
}
</style>
