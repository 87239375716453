<template>
  <div>
    <v-form ref="registerForm" v-model="valid" lazy-validation>
      <v-window v-model="step">
        <v-window-item :value="1">
          <p class="text-center mt-3 text-break"><i>Temps d'inscription: environ 60 secondes</i></p>
          <v-card-title> Mes informations </v-card-title>
          <v-card-text>
            <v-text-field v-model="editedItem.pseudo" :rules="requiredRules" rounded autocomplete="on"
              prepend-inner-icon="mdi-account-cowboy-hat" filled label="Pseudo" required></v-text-field>

            <v-text-field v-model="editedItem.email" autocomplete="on" filled prepend-inner-icon="mdi-at"
              :rules="emailRules" label="Adresse e-mail" rounded required></v-text-field>

            <v-text-field filled prepend-inner-icon="mdi-form-textbox-password" v-model="editedItem.password"
              :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'" :rules="passwordRules"
              :type="show1 ? 'text' : 'password'" rounded name="input-10-1" label="Mot de passe" counter
              @click:append="show1 = !show1">
            </v-text-field>

            <v-text-field block filled prepend-inner-icon="mdi-form-textbox-password" v-model="verify" rounded
              :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'" :rules="[rules.requiredRules, passwordMatch]"
              :type="show1 ? 'text' : 'password'" name="input-10-1" label="Confirmer le mot de passe" counter
              @click:append="show1 = !show1"></v-text-field>
          </v-card-text>
        </v-window-item>

        <v-window-item :value="2">
          <v-card-title> Les catégories qui m'intéressent </v-card-title>
          <v-card-text>
            <p class="fs-6 lead">Minimum <strong>1 catégorie</strong> !</p>
          </v-card-text>
          <div class="container">
            <v-select v-model="editedItem.categories" :items="categories" item-text="nom" item-value="id"
              label="Catégories" rounded multiple filled>
              <template v-slot:selection="{ item }">
                <v-chip class="bg-warning text-dark">
                  <span>{{ item.nom }}</span>
                </v-chip>
              </template>
            </v-select>
          </div>
        </v-window-item>
        <v-window-item :value="3">
          <v-card-title> Confirmations </v-card-title>
          <v-card-text>
            <div class="d-flex justify-content-center">
              <v-checkbox v-model="editedItem.validation_policy"><template v-slot:label>
                  <div>
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on }">
                        <router-link :to="{ name: 'Politique du site' }" @click.stop v-on="on">
                          J'accepte la politique de <br />
                          confidentialité de Pocketuto
                        </router-link>
                      </template>
                    </v-tooltip>
                  </div>
                </template></v-checkbox>
            </div>
            <div class="d-flex justify-content-center">
              <vue-recaptcha class="pt-2" sitekey="6LfaUtQcAAAAAFntjWllvmusiyv1VloDuRCS5gvr"
                @verify="markRecaptchaAsVerified" :loadRecaptchaScript="true"></vue-recaptcha>
            </div>
            <div>
              <strong style="color: red">{{
                  pleaseTickRecaptchaMessage
              }}</strong>
            </div>
            <v-btn x-large rounded block :disabled="!valid || verifStepper" class="bg-color-main text-white mt-5"
              :loading="loader" @click="validate()">S'inscrire</v-btn>
          </v-card-text>
        </v-window-item>
      </v-window>
      <v-card-actions>
        <v-btn :disabled="step === 1" text rounded @click="step--" class="text-white">
          <v-icon>mdi-arrow-left</v-icon> Retour
        </v-btn>
        <v-spacer></v-spacer>
        <v-btn v-if="step != 3" rounded :disabled="step === 3 || verifStepper || !valid" class="bg-color-main" depressed
          @click="step++">
          Suivant <v-icon>mdi-arrow-right</v-icon>
        </v-btn>
      </v-card-actions>
    </v-form>
  </div>
</template>

<script>
import { mapActions } from "vuex";
export default {
  computed: {
    passwordMatch() {
      return () =>
        this.editedItem.password === this.verify ||
        "Les mot de passes ne sont pas les mêmes";
    },
    verifStepper() {
      switch (this.step) {
        case 1:
          if (
            this.editedItem.pseudo == null ||
            this.editedItem.email == null ||
            this.editedItem.password == null ||
            this.verify == null
          ) {
            return true;
          } else {
            return false;
          }
        case 2:
          if (this.editedItem.categories.length == 0) {
            return true;
          } else {
            return false;
          }
        case 3:
          if (this.editedItem.validation_policy == null || !this.recaptchaVerified) {
            return true;
          } else {
            return false;
          }
        default:
          return true;
      }
    },
  },
  data() {
    return {
      categories: [],
      loader: false,
      dialog: true,
      valid: true,
      dialogCaptcha: false,
      recaptchaVerified: false,
      pleaseTickRecaptchaMessage: "",
      editedItem: {
        pseudo: null,
        email: null,
        categories: [],
        validation_policy: null,
      },
      role: "",
      step: 1,
      userIdOneSignal: null,
      cssCardCreateur: "",
      verify: "",
      requiredRules: [(v) => !!v || "Le champ est obligatoire"],
      numberRules: [
        (v) => !!v || "Le champ est obligatoire",
        (v) => !isNaN(v) || "Ce n'est pas un nombre",
      ],
      emailRules: [
        (v) => !!v || "Le champ doit être rempli",
        (v) => /.+@.+\..+/.test(v) || "L'adresse email est invalide",
      ],
      minRules: [
        (v) =>
          (v && v.length >= 6) ||
          "Le mot de passe doit faire au minimum 6 caractères",
      ],
      passwordRules: [
        value => !!value || 'Taper un mot de passe s\'il vous plait',
        (value) => !!value || 'Le champ mot de passe est obligatoire',
        (value) => (value && /\d/.test(value)) || 'Le mot de passe doit contenir au moins un nombre',
        (value) => (value && /[A-Z]{1}/.test(value)) || 'Le mot de passe doit contenir au moins une majuscule',
        (value) => (value && /[^A-Za-z0-9]/.test(value)) || 'Le mot de passe doit contenir au moins un caractere spécial',
        (value) => (value && value.length > 6) || 'Le mot de passe doit faire au moins 6 caractères minimum',
      ],
      numberRulesNoObligatory: [(v) => !isNaN(v) || "Ce n'est pas un nombre"],
      rules: {
        requiredRules: (v) => !!v || "Le champ est obligatoire",
        minRules: (v) =>
          (v && v.length >= 6) ||
          "Le mot de passe doit faire au minimum 6 caractères",
      },
      show1: false,
    };
  },
   mounted() {
    this.getCategories();
    window.OneSignal.push(() => {
      window.OneSignal.getUserId((userId) => {
        this.userIdOneSignal = userId;
      });
    });
  },
  methods: {
    ...mapActions({
      register: "auth/register",
    }),
    getCategories() {
      this.$axios.get("/categories/index").then((response) => {
        this.categories = response.data;
      });
    },
    checkAllInputsFill() {
      if (
        this.editedItem.pseudo != null &&
        this.editedItem.email != null &&
        this.editedItem.password != null &&
        this.editedItem.validation_policy != null &&
        this.editedItem.categories.length > 0
      ) {
        return true;
      } else {
        return false;
      }
    },
    markRecaptchaAsVerified() {
      this.forleaseTickRecaptchaMessage = "";
      this.recaptchaVerified = true;
    },
    validate() {
      this.loader = true;
      if (!this.recaptchaVerified) {
        this.pleaseTickRecaptchaMessage =
          "Vous devez valider le captcha pour continuer.";
        this.loader = false;
        return true; // prevent form from submitting
      }
      this.editedItem.role = this.role;
      if (this.checkAllInputsFill()) {
        let formData = new FormData();

        formData.append("pseudo", this.editedItem.pseudo);
        formData.append("email", this.editedItem.email);
        formData.append("validation_policy", this.editedItem.validation_policy);
        formData.append("password", this.editedItem.password);
        formData.append(
          "categories",
          JSON.stringify(this.editedItem.categories)
        );
        formData.append("userIdOneSignal", this.userIdOneSignal);

        this.register(formData).then((response) => {
          if (response) {
            this.$swal("Oups !!", response, "error");
            this.loader = false;
          } else {
            var arrayInfo = [];
            arrayInfo = [
              "Bienvenue sur Pocketuto ! Nous sommes ravies de vous rencontrer, veuillez valider votre compte s'il vous plait !",
            ];
            var index = Math.floor(Math.random() * arrayInfo.length);
            this.$swal("ASTUCES", arrayInfo[index], "info").then(() => {
              this.loader = false;
              this.$router.push({ name: "Tutos" }).then(() => {
                document.location.reload();
              });
            });
          }
        });
      } else {
        this.$swal({
          toast: true,
          title:
            "Vous devez remplir et cocher tous les champs et cases pour vous enregistrer !",
          icon: "warning",
          animation: true,
          position: "center",
          showConfirmButton: false,
          timerProgressBar: true,
          timer: 2000,
        });
      }
      this.loader = false;
    },
  },
};
</script>

<style scoped>
.card-clickable {
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
}

.card-clickable:hover {
  cursor: pointer;
}
</style>