<template>
  <div class="bg-main">
    <div style="padding-bottom: 100px">
    <div class="container mt-10">
      <v-card class="rounded-xl">
        <h1 class="text-center text-dark p-3 bg-color-main">Achat échoué</h1>
      </v-card>
    </div>
      <v-card class="container rounded-xl">
        <v-card-title class="text-danger"
          >UNE ERREUR EST SURVENUE !</v-card-title
        >
        <v-card-text class="text-break">
          Un problème a eu lieu au moment du paiement. <br /><br />
          Si vous avez été débiter de la somme et que vous n'avez pas reçu votre
          extension de stockage. <br /><br />
          Veuillez contacter le support a l'adresse suivante :
          <strong
            ><a
              class="text-decoration-none text-dark"
              href="mail:contact.pocketuto@gmail.com"
              >contact.pocketuto@gmail.com</a
            ></strong
          >
        </v-card-text>
        <v-card-actions class="d-flex justify-content-center">
          <v-btn @click="redirectToAccount()" rounded class="bg-dark text-white"
            ><strong>Revenir à la page précédente</strong></v-btn
          >
        </v-card-actions>
      </v-card>
    </div>
  </div>
</template>

<script>
export default {
  metaInfo() {
    return {
      title: "Paiement échoué | Pocketuto ",
      meta: [
        { vmid: 'description', name: 'description', content: "Votre paiement sur Pocketuto a échoué" }
      ]
    }
  },
  methods: {
    redirectToAccount() {
      this.$router
        .push({ name: "ExtensionStockage" })
        .then(() => document.location.reload());
    },
  },
};
</script>

<style>
</style>