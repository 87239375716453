<template>
  <div class="mt-5">
    <div v-infinite-scroll="loadTimeLine" infinite-scroll-disabled="busy" :infinite-scroll-limit="foldersMax"
      infinite-scroll-distance="5">
      <div style="padding-bottom: 150px">
        <div class="container">
          <div class="mb-3 d-flex justify-content-end">
            <v-btn @click="createFolder()" rounded large class="bg-color-main text-white">
              <v-icon>mdi-plus</v-icon> Créer
            </v-btn>
          </div>

          <search-filter :categories="categories" class="mb-3" filterCategorieFunction="filterCategoriesFolder"
            @reloadItems="getFolders" @newItems="folderFilter = $event" @resetItem="folderFilter = folders"
            table="folder" type="dossiers"></search-filter>

          <v-text-field v-model="searchFolder" rounded clearable filled prepend-inner-icon="mdi-magnify"
            label="Rechercher un dossier"></v-text-field>
        </div>
        <div class="container">
          <div style="border: none;" class="rounded-xl">
            <div v-show="loader" class="mt-5 rounded-xl mb-5">
              <div class="container d-flex justify-content-center mt-5">
                <div class="text-center">
                  <v-progress-circular :size="70" :width="7" color="#2596be" indeterminate></v-progress-circular>
                  <p class="text-center text-color-main fw-bold fs-4">
                    Chargement de vos dossiers
                  </p>
                </div>
              </div>
              <v-row dense>
                <v-col v-for="i in colsResponsive + 1" :key="i" :cols="colsResponsive">
                  <v-skeleton-loader class="mx-auto my-3 rounded-xl"
                    type="card, card, card, card, card, card, card, card">
                  </v-skeleton-loader>
                </v-col>
              </v-row>
            </div>
            <liste-folder :folderFilter="folderFilter" :loader="loader" :displayMessageNoFolder="true"
              :folders="folders"></liste-folder>
          </div>
        </div>
        <div v-show="loaderOtherFolder" class="mt-5 rounded-xl">
          <div class="container d-flex justify-content-center mt-5">
            <div class="text-center">
              <v-progress-circular :size="70" :width="7" color="#2596be" indeterminate></v-progress-circular>
              <p class="text-center text-color-main fw-bold fs-4">
                Chargement de vos dossiers
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script type="text/javascript">
/* eslint-disable */
import ListeTuto from '@/components/tutos/ListeTuto.vue';
import infiniteScroll from "vue-infinite-scroll";
import SearchFilter from '@/components/modules/SearchFilter.vue';
import ListeFolder from '@/components/folders/ListeFolder.vue';
export default {
  metaInfo() {
    return {
      title: "Mes dossiers | Pocketuto ",
      meta: [
        { vmid: 'description', name: 'description', content: "Liste de mes dossiers" }
      ]
    }
  },
  components: {
    ListeTuto,
    SearchFilter,
    ListeFolder
  },
  directives: {
    infiniteScroll,
  },
  props: ["folderId"],
  data() {
    return {
      folders: [],
      dialogFolder: false,
      valid: true,
      rights: [
        { value: 1, text: "Admin" },
        { value: 0, text: "Lecture" },
      ],
      active: [],
      busy: false,
      dialogFolderAcces: false,
      loader: true,
      loaderOtherFolder: false,
      searchFolder: null,
      tutos: [],
      loadTuto: true,
      idTuto: null,
      show1: false,
      tutosFilter: [],
      categories: [],
      editedItem: [],
      favoris: false,
      categorieFilter: [],
      user: [],
      search: "",
      isSearch: false,
      menu: false,
      btnLoadFolders: false,
      items: [
        {
          text: "Ma lists",
          icon: "mdi-plus",
        },
        {
          text: "Ma lists",
          icon: "mdi-plus",
        },
        {
          text: "Ma lists",
          icon: "mdi-plus",
        },
      ],
      selectedItem: [],
      titleModalFolder: "Créer un dossier",
      requiredRules: [(v) => !!v || "Le champ est obligatoire"],
      editedFolder: {
        id: "",
        titre: "",
        arrayTutos: [],
      },

      displayTypeFolder: 1,
      displayTypeTutos: 1,
      displayTutosVisibility: 3,
      displayFolderVisibility: 3,

      listTutos: [],
      foldersMax: 0,
      limitTimeLine: 20,
      folderFilter: [],
      passwordRequire: "",
      validPassword: false,
      dialogPassword: false,
      verify: "",
      showPasswordSection: false,
      passwordRules: [
        value => !!value || 'Taper un mot de passe s\'il vous plait',
        (value) => !!value || 'Le champ mot de passe est obligatoire',
        (value) => (value && /\d/.test(value)) || 'Le mot de passe doit contenir au moins un nombre',
        (value) => (value && /[A-Z]{1}/.test(value)) || 'Le mot de passe doit contenir au moins une majuscule',
        (value) => (value && /[^A-Za-z0-9]/.test(value)) || 'Le mot de passe doit contenir au moins un caractere spécial',
        (value) => (value && value.length > 6) || 'Le mot de passe doit faire au moins 6 caractères minimum',
      ],
    };
  },
  mounted() {
    this.initPage();
    this.$store.dispatch("categories/setCategories");
    if (this.$store.getters["categories/getCategories"].length == 0) {
      var refreshIntervalId3 = setInterval(() => {
        if (this.$store.getters["categories/dataLoad"]) {
          this.categories = this.$store.getters["categories/getCategories"];
          clearInterval(refreshIntervalId3);
        }
      }, 1000);
    } else {
      this.categories = this.$store.getters["categories/getCategories"];
    }
  },
  activated() {
    if (this.loader == false) {
      this.loaderOtherFolder = true
      this.getFolders();
    }

  },
  watch: {
    searchFolder(val) {
      if (val) {
        if (val.length > 2) {
          this.$axios
            .get("/folder/searchFolder/" + val + "/" + this.displayTypeFolder)
            .then((response) => {
              this.folderFilter = response.data.folders;
              this.displayTypeFolder = response.data.displayTypeFolder;
            });
        }
        if (val.length == 0) {
          this.folderFilter = this.folders;
        }
      } else {
        this.folderFilter = this.folders;
      }
    },
    search(val) {
      if (val) {
        if (val.length > 2) {
          this.$axios
            .get("/folder/searchTutoFolder/" + val + '/' + this.folderSelect.id)
            .then((response) => {
              this.tutosFilter = response.data.tutos;
              this.isSearch = true;
            });
        }
        if (val.length == 0) {
          this.tutosFilter = this.tutos;
          this.isSearch = false;
        }
      } else {
        this.tutosFilter = this.tutos;
        this.isSearch = false;
      }
    },

    folderSelect(val) {
      if (val) {
        if (val.id) {
          this.getContentFolder()
        }
      }
    }
  },
  computed: {
    folderSelect() {
      if (!this.active.length) return undefined;

      const id = this.active[0];

      return this.folderFilter.find((folder) => folder.id === id);

    },
    passwordMatch() {
      return () =>
        this.folderSelect.password === this.verify ||
        "Les mot de passes ne sont pas les mêmes";
    },
    colsResponsive() {
      if (window.innerWidth < 800) {
        return 12;
      } else {
        return 3;
      }
    },
  },
  methods: {
    initPage() {
      this.loader = true
      this.getFolders();
      var refreshIntervalId = setInterval(() => {
        if (this.$store.getters["folder/dataLoad"]) {
          this.folders = this.$store.getters["folder/getFolders"];
          this.foldersMax = this.$store.getters["folder/getMaxFolders"];
          this.folderFilter = this.folders;
          this.displayTypeFolder =
            this.$store.getters["folder/displayTypeFolder"];
          this.loader = false;
          clearInterval(refreshIntervalId);
        }
      }, 1000);
    },
    loadTimeLine() {
      this.loaderOtherFolder = false;
      if (this.foldersMax > this.folders.length && !this.loaderOtherFolder && !this.isSearch) {
        this.loaderOtherFolder = true;
        setTimeout(() => {
          this.limitTimeLine = this.limitTimeLine + 20;
          this.getFolders();
        }, 1000);
      }
    },
    getFolders(payload = []) {
      var arrayPayload = []
      if (payload.length == 0) {
        arrayPayload = {
          limitTimeLine: this.limitTimeLine,
          displayTypeFolder: this.displayTypeFolder,
          displayFolderVisibility: this.displayFolderVisibility
        }
      } else {
        arrayPayload = {
          limitTimeLine: this.limitTimeLine,
          displayTypeFolder: payload['displayType'],
          displayFolderVisibility: payload['displayVisibility'],
        }
      }
      this.$store.dispatch("folder/setFolders", arrayPayload).then(() => {
        var refreshIntervalId = setInterval(() => {
          if (this.$store.getters["folder/dataLoad"]) {
            this.folders = this.$store.getters["folder/getFolders"];
            this.foldersMax = this.$store.getters["folder/getMaxFolders"];
            this.folderFilter = this.folders;
            this.displayTypeFolder =
              this.$store.getters["folder/displayTypeFolder"];
            if (this.loader != false) {
              this.loader = false;
            } else {
              this.loaderOtherFolder = false
            }

            this.btnLoadFolders = false;
            clearInterval(refreshIntervalId);
          }
        }, 1000);
      });
    },
    closeContentFolder() {
      this.dialogPassword = false
      this.active = []
    },
    getContentFolder(payload = []) {
      this.loadTuto = true;
      var arrayPayload = []
      if (payload.length == 0) {
        arrayPayload = {
          displayTypeTutos: this.displayTypeTutos,
          displayTutosVisibility: this.displayTutosVisibility,
          passwordRequire: this.passwordRequire
        }
      } else {
        arrayPayload = {
          displayTypeTutos: payload['displayType'],
          displayTutosVisibility: payload['displayVisibility'],
          passwordRequire: this.passwordRequire
        }
      }
      this.$axios
        .post("/folder/getContentFolder/" + this.folderSelect.id, arrayPayload)
        .then((response) => {
          if (response.data.messageError) {
            this.$swal(
              "Oups, une erreur est survenu !",
              response.data.messageError,
              "error"
            );
            this.loader = false;
            return;
          }
          if (response.data.needPassword) {
            this.dialogPassword = true
            this.passwordRequire = ""
            return;
          }
          this.dialogPassword = false
          this.tutos = response.data.tutos;
          this.tutosFilter = this.tutos
          this.loadTuto = false;
        })
        .catch(function (error) {
          if (error.response.status === 401) {
            setTimeout(() => {
              this.$router.push({ name: "Je m'authentifie" });
            }, 1200);
          }
        });
    },
    createFolder() {
      this.$router.push({ name: "Créer un dossier" });
    },
    confirmDeleteFolder(folder) {
      if (folder.isOwn) {
        this.$axios
          .delete("folder/deleteFolderCreate/" + folder.id)
          .then((response) => {
            if (response.data.messageError) {
              this.$swal(
                "Oups, un problème est survenu !",
                response.data.messageError,
                "error"
              );
              return;
            }
            this.$swal({
              toast: true,
              title: response.data.message,
              icon: "success",
              animation: true,
              position: "top-right",
              showConfirmButton: false,
              timerProgressBar: true,
              timer: 2000,
            });
            this.dialogConfirmDeleteCreate = false;
            this.getFolders();
          });
      } else {
        this.$axios
          .delete("folder/deleteFolderReceive/" + folder.id)
          .then((response) => {
            if (response.data.messageError) {
              this.$swal(
                "Oups, un problème est survenu !",
                response.data.messageError,
                "error"
              );
              this.dialogConfirmDeleteFolder = false;
              return;
            }
            this.$swal({
              toast: true,
              title: response.data.message,
              icon: "success",
              animation: true,
              position: "top-right",
              showConfirmButton: false,
              timerProgressBar: true,
              timer: 2000,
            });

            this.getFolders();
            this.dialogConfirmDeleteFolder = false;
          });
      }
    },
    shareFolder() {
      this.$router.push({
        name: "Envoyer",
        params: {
          idElement: this.folderSelect.id,
          nomElement: "dossier",
          element: this.folderSelect,
        },
      });
    },
    savePasswordFolder() {
      this.$axios.post('folder/savePassword/' + this.folderSelect.id, { password: this.folderSelect.password }).then((response) => {
        this.$swal({
          toast: true,
          title: response.data.message,
          icon: "success",
          animation: true,
          position: "top-right",
          showConfirmButton: false,
          timerProgressBar: true,
          timer: 2000,
        });
        this.dialogFolderAcces = false
        this.passwordRequire = ""
        this.getFolders()
      })
    },
  }
};
</script>

<style scoped>
.folder-hover {
  box-shadow: 0 6px 10px rgba(0, 0, 0, 0.08), 0 0 6px rgba(0, 0, 0, 0.05);
  transition: 0.3s transform cubic-bezier(0.155, 1.105, 0.295, 1.12),
    0.3s box-shadow,
    0.3s -webkit-transform cubic-bezier(0.155, 1.105, 0.295, 1.12);
  cursor: pointer;
}

.folder-hover:hover {
  z-index: 2;
  transform: scale(1.02);
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.12), 0 4px 8px rgba(0, 0, 0, 0.06);
}
</style>