<template>
  <div>
    <v-dialog
      v-model="dialog"
      persistent
      hide-overlay
      max-width="600px"
      min-width="360px"
    >
      <div
        class="text-center bg-color-main text-dark pb-5 pt-5"
      >
        <v-img
          src="/img/logo-pocketuto/logo-text-blanc-bleu.png"
        ></v-img>
        <span class="text-center text-white fs-4 text-break"
          >Ensemble aidons nos proches !</span
        >
      </div>

      <div>
        <v-tabs
          v-model="tab"
          show-arrows
          icons-and-text
          grow
        >
          <v-tabs-slider color="darken-4"></v-tabs-slider>
          <v-tab v-for="i in tabs" :key="i.name">
            <v-icon large>{{ i.icon }}</v-icon>
            <div class="caption py-1">{{ i.name }}</div>
          </v-tab>
          <v-tab-item>
            <login></login>
          </v-tab-item>
          <v-tab-item>
            <register></register>
          </v-tab-item>
        </v-tabs>
      </div>
    </v-dialog>
  </div>
</template>

<script>
import Login from "@/components/auth/Login.vue";
import Register from "@/components/auth/Register.vue";
export default {
  metaInfo() {
    return {
      title: "Connexion/Inscription | Pocketuto ",
      meta: [
        { vmid: 'description', name: 'description', content: "Je m'authentifie sur Pocketuto" }
      ]
    }
  },
  components: { Register, Login },
  methods: {},
   mounted() {
    this.dialog = true;
  },
  activated() {
    this.dialog = true;
  },
  data: () => ({
    dialog: true,
    tab: 0,
    tabs: [
      { name: "Connexion", icon: "mdi-account" },
      { name: "Inscription", icon: "mdi-account-outline" },
    ],
  }),
};
</script>
