<template>
  <div>
    <v-card class="pb-5">
      <v-data-table no-data-text="Aucun ticket n'a été créé" mobile-breakpoint="0" class="rounded-xl"
        style="cursor:pointer" :headers="headers" :items="tickets" :items-per-page="10" hide-default-footer>
        <template v-slot:item.status="{ item }">
          <v-chip :color="colorStatus(item.status['id'])">{{ item.status['libelle'] }}</v-chip>
        </template>
        <template v-slot:item.priority="{ item }">
          <v-chip :color="colorPriority(item.priority)">{{ item.priority }}</v-chip>
        </template>
        <template v-slot:item.actions="{ item }">
          <div>
            <v-btn @click="watchTicket(item)" text class="text-primary">
              <v-icon>mdi-eye-settings</v-icon> Voir
            </v-btn>
            <v-btn @click="clotureTicket(item)" v-if="item.status['id'] != 2" text class="orange--text text--darken-4">
              <v-icon>mdi-lock</v-icon> Clôture
            </v-btn>
            <v-btn @click="clotureTicket(item)" v-else text class="text-success">
              <v-icon>mdi-lock-open-variant</v-icon> Ouvrir
            </v-btn>
            <v-btn @click="deleteTicket(item)" text class="text-danger">
              <v-icon>mdi-delete</v-icon> Supprimer
            </v-btn>
          </div>
        </template>
      </v-data-table>
      <v-pagination class="mt-5" @input="onPageChange()" v-model="pagination.current" :length="pagination.total" circle>
      </v-pagination>
      <v-dialog v-model="dialogDeleteTicket" width="500">
        <v-card class="rounded-xl">
          <v-toolbar class="mb-5 bg-color-main" dark>
            <v-toolbar-title>
              Que voulez-vous faire ?
            </v-toolbar-title>
            <v-spacer></v-spacer>
            <v-icon @click="dialogDeleteTicket = false">mdi-close</v-icon>
          </v-toolbar>
          <v-card-text class="container lead fs-5">
            Êtes-vous sûr de vouloir supprimer ce ticket ?
          </v-card-text>
          <v-card-actions class="d-flex justify-content-end">
            <v-btn rounded class="bg-main text-dark" @click="confirmDeleteTicket()">Oui</v-btn>
            <v-btn rounded class="bg-color-main text-white" @click="dialogDeleteTicket = false">Non</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-card>
  </div>
</template>

<script>
export default {
  data() {
    return {
      tickets: [],
      editedItem: [],
      dialogDeleteTicket: false,
      headers: [
        { text: 'Actions', value: 'actions' },
        { text: 'Date', value: 'dateCreation' },
        { text: 'Titre', value: 'titre' },
        { text: 'Type de problème', value: 'type_probleme' },
        { text: 'Utilisateur', value: 'user' },
        { text: 'Email', value: 'emailUser' },
        { text: 'Status', value: 'status' },
        { text: 'Priorité', value: 'priority' },

      ],
      pagination: {
        current: 1,
        total: 0,
      },
    }
  },
  computed: {
    notDisplayBtn() {
      var find = this.tickets.find(t => t.status['id'] == 0 || t.status['id'] == 1)
      if (find) {
        return false
      } else {
        return true
      }
    }
  },
  mounted() {
    this.getTickets()
  },
  methods: {
    getTickets() {
      this.$axios.get('admin/tickets').then((response) => {
        this.tickets = response.data.tickets
        this.pagination.total = response.data.total
        this.pagination.current = response.data.current
      })
    },
    onPageChange() {
      this.getTickets()
    },
    colorStatus(status) {
      switch (status) {
        case 0:
          return 'grey'

        case 1:
          return 'green'

        case 2:
          return 'red'


        default:
          break;
      }
    },
    colorPriority(priority) {
      switch (priority) {
        case 'High':
          return 'red'

        case 'Low':
          return 'blue'

        default:
          break;
      }
    },
    clotureTicket(ticket) {
      this.$axios.get('/admin/clotureTicket/' + ticket.id).then((response) => {
        this.$swal({
          toast: true,
          icon: "success",
          timer: 3000,
          title: response.data.message,
          position: "top-right",
          showConfirmButton: false,
        });
        this.getTickets()
      })
    },
    deleteTicket(ticket) {
      this.dialogDeleteTicket = true
      this.editedItem = ticket
    },
    confirmDeleteTicket() {
      this.$axios.delete('/admin/deleteTicket/' + this.editedItem.id).then((response) => {
        this.$swal({
          toast: true,
          icon: "success",
          timer: 3000,
          title: response.data.message,
          position: "top-right",
          showConfirmButton: false,
        });
        this.dialogDeleteTicket = false
        this.getTickets()
      })
    },
    watchTicket(ticket) {
      this.$router.push({ name: "Contenu du ticket", params: { idTicket: ticket.id } })
    },
    redirectTo(path) {
      this.$router.push({ name: path })
    }
  }
}
</script>

<style>

</style>