var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-form',{staticStyle:{"margin-bottom":"100px"},model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('p',{staticClass:"fw-light text-center"},[_vm._v("Visibilité du dossier :")]),_c('div',{staticClass:"mb-5 d-flex justify-content-center"},[_c('v-btn',{staticClass:"bg-color-main m-1",style:(_vm.borderChoosenPrivate),attrs:{"loading":_vm.loader},on:{"click":function($event){return _vm.openDialogIsPrivate(1)}}},[_c('v-icon',[_vm._v("mdi-lock")]),_vm._v(" Privé ")],1),_c('v-btn',{staticClass:"bg-color-main m-1",style:(_vm.borderChoosenPublic),attrs:{"loading":_vm.loader},on:{"click":function($event){return _vm.openDialogIsPrivate(0)}}},[_c('v-icon',[_vm._v("mdi-lock-open")]),_vm._v(" Public ")],1)],1),_c('v-text-field',{attrs:{"filled":"","rounded":"","rules":_vm.requiredRules,"loading":_vm.loader,"counter":40,"maxlength":40,"label":"Nom du dossier"},model:{value:(_vm.editedFolder.titre),callback:function ($$v) {_vm.$set(_vm.editedFolder, "titre", $$v)},expression:"editedFolder.titre"}}),_c('v-textarea',{attrs:{"filled":"","rounded":"","prepend-inner-icon":"mdi-image-text","label":"Description","loading":_vm.loader,"counter":200,"maxlength":200},model:{value:(_vm.editedFolder.description),callback:function ($$v) {_vm.$set(_vm.editedFolder, "description", $$v)},expression:"editedFolder.description"}}),_c('label',{staticClass:"text-danger fst-italic"},[_vm._v("Appuyer sur votre touche \"Entrée\" pour enregistrer le mot clé")]),_c('v-combobox',{attrs:{"items":[],"prepend-inner-icon":"mdi-keyboard-variant","rules":_vm.requiredRules,"filled":"","rounded":"","chips":"","label":"Ecrivez des mots clés","multiple":"","counter":8,"required":""},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var attrs = ref.attrs;
var item = ref.item;
var selected = ref.selected;
return [_c('v-chip',_vm._b({staticClass:"bg-color-main rounded-xl",attrs:{"rounded":"","input-value":selected,"label":""}},'v-chip',attrs,false),[_c('span',{staticClass:"pr-2"},[_vm._v(" "+_vm._s(item)+" ")])])]}}]),model:{value:(_vm.editedFolder.mot_clefs),callback:function ($$v) {_vm.$set(_vm.editedFolder, "mot_clefs", $$v)},expression:"editedFolder.mot_clefs"}}),_c('v-select',{attrs:{"label":"Catégories du dossier","items":_vm.categories,"item-text":"nom","item-value":"id","filled":"","loading":_vm.loader,"rounded":"","multiple":""},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{staticClass:"bg-warning text-dark"},[_c('span',[_vm._v(_vm._s(item.nom))])])]}}]),model:{value:(_vm.editedFolder.categories),callback:function ($$v) {_vm.$set(_vm.editedFolder, "categories", $$v)},expression:"editedFolder.categories"}}),(!_vm.editedFolder.isPrivate)?_c('v-alert',{attrs:{"type":"info"}},[_vm._v("Vous ne pourrez mettre que des tutos publics à l'interieur de ce dossier")]):_vm._e(),_c('v-list',{staticClass:"rounded-xl transparent",attrs:{"dense":""}},[_c('v-subheader',[_vm._v("Listes des derniers tutos créer")]),_c('v-text-field',{attrs:{"loading":_vm.loader,"placeholder":"Rechercher un tuto dans votre compte","filled":"","rounded":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('v-select',{attrs:{"loading":_vm.loader,"rounded":"","items":_vm.listTutos,"filled":"","chips":"","item-value":"id","item-text":"titre","label":"Tutos à l'interieur du dossier","multiple":""},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{staticClass:"text-white bg-color-main"},[_vm._v(_vm._s(item.titre))])]}}]),model:{value:(_vm.editedFolder.arrayTutos),callback:function ($$v) {_vm.$set(_vm.editedFolder, "arrayTutos", $$v)},expression:"editedFolder.arrayTutos"}}),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.search.length > 0),expression:"search.length > 0"}]},[_c('v-list-item-group',_vm._l((_vm.listSearch),function(item,index){return _c('v-list-item',{key:index,attrs:{"value":item.id},on:{"click":function($event){return _vm.selectTuto(item)}}},[[_c('v-list-item-content',[_c('v-list-item-title',{domProps:{"textContent":_vm._s(item.titre)}})],1)]],2)}),1)],1)],1),_c('div',{staticClass:"d-flex justify-content-end mt-2 mb-5"},[(_vm.editedFolder.id)?_c('v-btn',{staticClass:"bg-color-main text-white m-2 mb-5",attrs:{"rounded":"","large":"","disabled":!_vm.valid},on:{"click":function($event){return _vm.updateFolder()}}},[_c('v-icon',[_vm._v("mdi-content-save-edit")]),_vm._v("Modifier ")],1):_c('v-btn',{staticClass:"bg-color-main text-white m-2 mb-5",attrs:{"disabled":!_vm.valid,"rounded":"","large":""},on:{"click":function($event){return _vm.createFolder()}}},[_c('v-icon',[_vm._v("mdi-content-save")]),_vm._v("Enregistrer ")],1)],1)],1),_c('dialog-confirm',{attrs:{"titre":_vm.arrayDialog.titre,"message":_vm.arrayDialog.message,"functionCalled":_vm.arrayDialog.functionCalled,"type":"formFolder"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }