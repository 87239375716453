<template>
  <div class="mt-10" style="margin-bottom: 100px">
    <v-container>
      <v-tabs v-model="tab" icons-and-text centered v-if="passwordCorrect" show-arrows>
        <v-tabs-slider color="teal lighten-3"></v-tabs-slider>
        <v-tab>
          Utilisateurs
          <v-icon> mdi-account-group </v-icon>
        </v-tab>
        <v-tab>
          Tickets
          <v-badge v-if="nbTickets > 0" color="black" overlap :content="nbTickets">
            <v-icon > mdi-face-agent </v-icon>
          </v-badge>
          <v-icon v-else> mdi-face-agent </v-icon>
        </v-tab>
        <v-tab>
          Statistique
          <v-icon> mdi-chart-areaspline </v-icon>
        </v-tab>
        <v-tab>
          Catégories
          <v-icon left> mdi-format-list-group </v-icon>
        </v-tab>
        <v-tabs-items v-model="tab" touchless>
          <v-tab-item>
            <gestion-users v-if="passwordCorrect" :loadData="loadData"></gestion-users>
          </v-tab-item>
          <v-tab-item>
            <gestion-tickets v-if="passwordCorrect"></gestion-tickets>
          </v-tab-item>
          <v-tab-item>
            <statistique v-if="passwordCorrect" :loadData="loadData"></statistique>
          </v-tab-item>
          <v-tab-item>
            <gestion-categories v-if="passwordCorrect" :loadData="loadData"></gestion-categories>
          </v-tab-item>
        </v-tabs-items>
      </v-tabs>

      <v-dialog v-model="dialog" persistent max-width="290">
        <v-card class="rounded-xl">
          <v-card-title class="text-h5"> Mot de passe requis </v-card-title>
          <v-card-text class="mt-3">
            <v-text-field prepend-inner-icon="mdi-account" rounded filled name="Mot de passe" v-model="password"
              label="Mot de passe" type="password" v-on:keydown.enter.prevent="validPasswordAdmin()"
              :rules="requiredRules"></v-text-field>
          </v-card-text>
          <v-card-actions>
            <v-btn class="text-color-main bg-white" rounded @click="$router.go(-1)">
              <v-icon>mdi-arrow-left</v-icon> Retour
            </v-btn>
            <v-spacer></v-spacer>
            <v-btn class="bg-color-main text-white" rounded @click="validPasswordAdmin()">
              <v-icon>mdi-send</v-icon> Valider
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-container>
  </div>
</template>

<script>
import GestionUsers from "@/components/admin/GestionUsers.vue";
import GestionCategories from "@/components/admin/GestionCategories.vue";
import moment from "moment";
import Statistique from "@/components/admin/Statistique.vue";
import md5 from "tiny-hashes/md5";
import GestionTickets from "@/components/admin/GestionTickets.vue";

moment.locale("fr");
export default {
  metaInfo() {
    return {
      title: "Panel admin | Pocketuto ",
      meta: [
        { vmid: 'description', name: 'description', content: "SECRET" }
      ]
    }
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.loadDataPage();
    });
  },
  components: {
    GestionUsers,
    GestionCategories,
    Statistique,
    GestionTickets,
  },
  data() {
    return {
      requiredRules: [(v) => !!v || "Le champ est obligatoire"],
      loadData: null,
      dialog: true,
      passwordCorrect: false,
      password: null,
      nbTickets: 0,
      tab: 0,
    };
  },
  mounted() { 
    this.getTickets()
  },
  methods: {
    loadDataPage() {
      this.loadData = Math.floor(Math.random() * 105111415641);
    },
    getTickets() {
      this.$axios.get('admin/tickets').then((response) => {
        this.nbTickets = response.data.tickets.length
      })
    },
    setNbTickets(nb) {
      this.nbTickets = nb
    },
    validPasswordAdmin() {
      var passTyped = md5(this.password);
      if (passTyped == "dfde8cefb38b4264422547d1bdaded6c") {
        this.passwordCorrect = true;
        this.dialog = false;
      } else {
        this.passwordCorrect = false;
      }
    },
  },
};
</script>

<style>

</style>