<template>
  <div class="container">
    <v-text-field v-model="inputHide" v-show="noShowing"></v-text-field>

    <v-list
      v-for="comment in comments"
      :key="comment.id"
      style="margin-bottom: -10px"
      two-line
    >
      <v-list-item
        :class="
          comment.hasParent
            ? 'border border-black rounded-xl ml-5'
            : 'border border-black rounded-xl '
        "
      >
        <v-list-item-avatar class="bg-color-main">
          <v-img :src="comment.photo"></v-img>
        </v-list-item-avatar>

        <v-list-item-content>
          <v-list-item-title class="fs-6 fw-bold"
            >{{ comment.createur }}
            <v-icon v-if="comment.hasParent">mdi-menu-right</v-icon>
            <span v-if="comment.hasParent">{{
              comment.userRepliedPseudo
            }}</span>
          </v-list-item-title>
          <v-text-field
            v-if="editComment[comment.id]"
            v-model="editCommentItem"
          ></v-text-field>
          <p v-else class="fs-7">
            {{ comment.content }}
          </p>
          <small>{{ comment.created_at }}</small>
        </v-list-item-content>
        <v-list-item-action>
          <v-btn
            icon
            class="text-color-main"
            @click="displayTextareaReplyComments(comment)"
          >
            <v-icon>mdi-reply</v-icon>
          </v-btn>
          <v-menu
            absolute
            offset-y
            transition="slide-y-transition"
            style="max-width: 600px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                icon
                v-bind="attrs"
                v-on="on"
                class="text-danger"
                v-if="
                  comment.user_id == user.id ||
                  editedItem.isOwn ||
                  editedItem.hasRight
                "
              >
                <v-icon>mdi-delete</v-icon>
              </v-btn>
            </template>
            <v-list dense class="bg-danger text-white">
              <v-list-item>
                <v-btn
                  class="bg-danger text-white"
                  @click="deleteComment(comment.id)"
                  text
                  x-small
                  >Supprimer le commentaire ?</v-btn
                >
              </v-list-item>
            </v-list>
          </v-menu>
          <v-btn
            v-if="editComment[comment.id]"
            icon
            @click="updateComment(comment)"
            class="text-primary"
          >
            <v-icon>mdi-content-save</v-icon>
          </v-btn>
          <v-btn
            icon
            v-else-if="comment.user_id == user.id"
            @click="editCommentaire(comment)"
          >
            <v-icon class="text-primary">mdi-pencil</v-icon>
          </v-btn>
        </v-list-item-action>
      </v-list-item>
    </v-list>

    <div class="container mb-5" v-if="replyTo">
      <p>
        Réponse à <strong>{{ comment.content }} :</strong>
      </p>
      <v-textarea
        filled
        rounded
        placeholder="Ma réponse . . ."
        v-model="commentaireReply"
        :disabled="loadBtnCommentReply"
        v-on:keydown.enter.prevent="replyComments()"
      ></v-textarea>
      <div class="d-flex justify-content-end">
        <v-btn
          @click="replyComments()"
          :loading="loadBtnCommentReply"
          :disabled="commentaireReply == ''"
          class="bg-color-main"
          rounded
        >
          <v-icon>mdi-send</v-icon>
        </v-btn>
      </div>
    </div>
    <div class="container pb-5" v-else>
      <p>Ecrire un nouveau commentaire :</p>
      <v-textarea
        filled
        rounded
        v-on:keydown.enter.prevent="saveComment()"
        placeholder="Je n'ai pas compris l'étape . . ."
        v-model="commentaire"
        :disabled="loadBtnComment"
      ></v-textarea>
      <div class="d-flex justify-content-end">
        <v-btn
          @click="saveComment()"
          :disabled="commentaire == ''"
          :loading="loadBtnComment"
          class="bg-color-main"
          rounded
        >
          <v-icon>mdi-send</v-icon>
        </v-btn>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["editedItem"],
  data() {
    return {
      commentaire: "",
      commentaireReply: "",
      inputHide: null,
      replyTo: "",
      noShowing: false,
      loadBtnComment: false,
      loadBtnCommentReply: false,
      editComment: [],
      editCommentItem: "",
      showMenuComment: false,
      comments: [],
    };
  },
   mounted() {
    this.getCommentsTuto();
  },
  computed: {
    user() {
      if(this.$store.getters["auth/dataLoad"]) {
        return this.$store.getters["auth/user"];
      }
      return []
    },
  },
  /* watch: {
    comments: {
      deep: true,
      handler(newValue) {
        console.log(newValue)
      }
    }
  }, */
  methods: {
    getCommentsTuto() {
      this.$axios
        .get("/comments/getCommentsTuto/" + this.editedItem.id)
        .then((response) => {
          this.comments = response.data;
        });
    },
    displayTextareaReplyComments(comment) {
      this.comment = comment;
      this.replyTo = comment.id;
      this.inputHide += "1";
    },
    editCommentaire(comment) {
      this.editComment[comment.id] = true;
      this.editCommentItem = comment.content;
      this.inputHide += "1";
    },
    replyComments() {
      this.loadBtnCommentReply = true;
      var payload = {
        content: this.commentaireReply,
      };
      this.$axios
        .post(
          "comments/replyComments/" + this.editedItem.id + "/" + this.replyTo,
          payload
        )
        .then((response) => {
          this.commentaireReply = "";
          this.loadBtnCommentReply = false;
          this.replyTo = "";
          this.comments = response.data;
        });
    },
    saveComment() {
      this.loadBtnComment = true;
      var payload = {
        content: this.commentaire,
      };
      this.$axios
        .post("comments/storeComment/" + this.editedItem.id, payload)
        .then((response) => {
          this.commentaire = "";
          this.loadBtnComment = false;
          this.replyTo = "";
          this.comments = response.data;
        });
    },
    updateComment(comment) {
      var payload = {
        content: this.editCommentItem,
      };
      this.$axios
        .put(
          "comments/updateComment/" + this.editedItem.id + "/" + comment.id,
          payload
        )
        .then((response) => {
          this.editCommentItem = "";
          this.editComment[comment.id] = false;
          this.comments = response.data;
        });
    },
    deleteComment(commentId) {
      this.$axios
        .delete(
          "comments/deleteComment/" + commentId + "/" + this.editedItem.id
        )
        .then((response) => {
          this.comments = response.data;
          this.inputHide += "1";
        });
    },
    strMimified(value) {
      if (value.length > 6) {
        return value.substring(0, 6) + "..";
      } else {
        return value;
      }
    },
  },
};
</script>

<style>
</style>