<template>
  <div>
    <v-overlay :value="loader">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
    <div class="container mt-10" style="padding-bottom: 200px">
      <div class="rounded-xl transparent">
        <v-card-title> Définissez vos paramètres ! </v-card-title>
        <v-card-subtitle>
          <br />
          Cocher la case si vous êtes d'accord ! <br />
          <strong>N'oubliez pas de valider votre choix pour que les changements
            soient pris en compte !</strong>
        </v-card-subtitle>

        <v-card-text>
          <p class="fw-bold">Les sujets qui m'intéressent</p>
          <v-select v-model="user.categorieId" :items="categories" item-text="nom" rounded
            @change="updateCategorieUser($event)" item-value="id" multiple filled>
            <template v-slot:selection="{ item, index }">
              <span v-if="index === chipResponsive" class="grey--text text-caption">
                (+{{ user.categorieId.length - chipResponsive }} autres)
              </span>
              <v-chip class="bg-warning text-dark" v-if="index < chipResponsive">
                <span>{{ item.nom }}</span>
              </v-chip>
            </template>
          </v-select>

          <h3 class="text-break text-center mt-3">Restrictions</h3>
          <v-checkbox v-model="receiveTuto"
            label="Voulez-vous recevoir des tutos de la part des personnes vous ayant en ami ?" hide-details>
          </v-checkbox>
          <v-checkbox v-model="receiveFolder"
            label="Voulez-vous recevoir des dossiers de la part des personnes vous ayant en ami ?" hide-details>
          </v-checkbox>
          <v-checkbox class="text-danger" v-model="receiveRequestFriends"
            label="Voulez-vous que les personnes puissent vous ajouter en ami ?" hide-details></v-checkbox>
          <div class="d-flex justify-content-center">
            <v-btn class="
                text-break text-info text-center text-decoration-underline
                mt-10
                mb-5
              " x-small text :to="{ name: 'Politique du site' }">Politique de <br />
              confidentialité de Pocketuto</v-btn>
          </div>
          <div class="d-flex justify-content-center">
            <v-btn :loading="loadingBtn" rounded class="mt-10 mb-5 bg-color-main text-white" @click="saveChanges()">
              Valider mon choix</v-btn>
          </div>


          <div class="d-flex justify-content-center mt-5">
            <v-btn @click="deleteConfirmDialog()" text class="text-danger m-2">Supprimer mon compte ?</v-btn>
          </div>
        </v-card-text>
      </div>
    </div>
    <v-dialog v-model="dialogDelete" persistent max-width="290">
      <v-card class="text-white">
        <v-toolbar class="lead" style="background-color: #2596be" dark>Que voulez-vous faire ?</v-toolbar>
        <v-card-text>
          <div class="text-center mt-3">
            <h3>
              <strong>Voulez-vous réellement supprimer votre compte sur Pocketuto
                ?</strong>
            </h3>
            <p class="fw-bold text-break mt-5">
              Si vous rencontrez des difficultés liées à l'utilisation ou tous
              autres problèmes. <br />
              Merci de nous le partager !
            </p>
            <p class="fw-bold text-break mt-5">
              Nous faisons tous notre possible pour que l'application soit la
              plus agréable pour vous et vos proches !
            </p>
            <p class="text-center fw-bold mt-5">
              Cette action est définitive, cela supprimera tous vos tutos
              sauvegardés et vous perdrez l'accès au tuto que l'on vous à
              envoyé. <br />
            </p>
          </div>
        </v-card-text>
        <v-card-actions class="d-flex justify-content-center">
          <div class="btn-group-vertical">
            <button class="btn text-white bg-dark d-flex justify-content-center" @click="dialogDelete = false">
              <strong>Je reste sur <br />
                Pocketuto !</strong>
            </button>
            <br />
            <button class="btn text-light bg-danger d-flex justify-content-center" @click="confirmDeleteAccount()">
              <strong>Je supprime mon <br />
                compte quand même</strong>
            </button>
          </div>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapActions } from "vuex";
export default {
  metaInfo() {
    return {
      title: "Paramètres | Pocketuto ",
      meta: [
        { vmid: 'description', name: 'description', content: "Vous pouvez définir vos paramètres" }
      ]
    }
  },
  data() {
    return {
      loadingBtn: false,
      dialogDelete: false,
      isPrivate: null,
      user: [],
      loader: true,
      myRole: null,
      receiveTuto: null,
      categories: [],
      receiveFolder: null,
      categoriesUser: [],
      switch1: true,
      receiveRequestFriends: null,
    };
  },
   mounted() {
    this.$store.dispatch("categories/setCategories");
    if (this.$store.getters["categories/getCategories"].length == 0) {
      var refreshIntervalId = setInterval(() => {
        if (this.$store.getters["categories/dataLoad"]) {
          this.categories = this.$store.getters["categories/getCategories"];
          clearInterval(refreshIntervalId);
        }
      }, 1000);
    } else {
      this.categories = this.$store.getters["categories/getCategories"];
    }
    var refreshIntervalId2 = setInterval(() => {
      if (this.$store.getters["auth/dataLoad"]) {
        this.user = this.$store.getters["auth/user"];
        this.receiveTuto = this.user.receiveTuto;
        this.receiveFolder = this.user.receiveFolder;
        this.receiveRequestFriends = this.user.receiveRequestFriends;
        this.isPrivate = this.user.isPrivate;
        this.categoriesUser = this.user.categories;
        this.myRole = this.user.role;
        this.loader = false;
        clearInterval(refreshIntervalId2);
      }
    }, 1000);
  },
  computed: {
    chipResponsive() {
      if (window.innerWidth < 800) {
        return 3;
      } else {
        return 8;
      }
    },
  },
  methods: {
    ...mapActions({
      logout: "auth/logout",
      setUser: "auth/setUser",
    }),
    deleteConfirmDialog() {
      this.dialogDelete = true;
    },
    confirmDeleteAccount() {
      this.$axios.delete("/users/deleteAccount").then((response) => {
        this.$swal("Compte supprimé !", response.data.message, "success").then(
          () => {
            this.dialogDelete = false;
            this.logout();
            this.$router.push({ name: "Je m'authentifie" }).then(() => {
              document.location.reload();
            });
          }
        );
      });
    },
    saveChanges() {
      this.loadingBtn = true;
      var arrayParams = {};
      arrayParams["receiveTuto"] = this.receiveTuto;
      arrayParams["receiveFolder"] = this.receiveFolder;
      arrayParams["receiveRequestFriends"] = this.receiveRequestFriends;
      arrayParams["role"] = this.myRole;
      arrayParams["isPrivate"] = this.isPrivate;
      this.$axios
        .post("/param/save/" + this.user.id, arrayParams)
        .then((response) => {
          if (response.data.messageError) {
            this.$swal(
              "Oups une erreur est survenu !",
              response.data.messageError,
              "error"
            );
            return;
          }
          this.$swal({
            toast: true,
            title: response.data.message,
            icon: "success",
            animation: true,
            position: "top-right",
            showConfirmButton: false,
            timerProgressBar: true,
            timer: 2000,
          });

          this.setUser(response.data.user);
          document.location.reload();
          setTimeout(() => {
            this.loadingBtn = false;
          }, 5000);
        });
    },
  },
};
</script>

<style>

</style>