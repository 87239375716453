/* eslint-disable */
import Vue from 'vue'
import Vuex from 'vuex'
import auth from "../store/auth"
import notifications from "../store/notifications"
import tutos from "../store/tutos"
import folder from "../store/folder"
import categories from "../store/categories"
import tickets from "./tickets"
import modal from "./modal"

Vue.use(Vuex)

const store = new Vuex.Store({
    namespaced: true,
    state: {},
    mutations: {},
    actions: {

    },
    modules: {
        auth,
        notifications,
        tutos,
        folder,
        categories,
        tickets,
        modal,
    },
})

export default store