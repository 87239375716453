<template>
  <div data-app class="container mt-5">
    <v-card class="elevation-12 rounded-xl content" width="800">
      <v-btn
        class="bg-dark mb-3 text-white m-3"
        v-if="nomElement == 'tutos'"
        rounded
        @click="viewTuto()"
        ><v-icon>mdi-eye</v-icon> Voir le tuto</v-btn
      >
      <v-card-text>
        <label class="mt-3 mb-3"
          >Choisissez un ou plusieurs <strong>ami(e)s</strong> dans la liste
          ci-dessous :</label
        >
        <v-select
          v-model="idUsers"
          :items="arrayUsers"
          :label="title"
          multiple
          chips
          rounded
          filled
          item-text="pseudo"
          item-value="idUser"
          persistent-hint
        >
          <template #selection="{ item }">
            <v-chip class="bg-info text-white">{{ item.pseudo }}</v-chip>
          </template></v-select
        >
        <v-select
          v-if="element.isOwn"
          :items="arrayRights"
          label="Définir les droits"
          v-model="rightSelect"
          rounded
          filled
          chips
          item-text="text"
          item-value="value"
          persistent-hint
        >
        </v-select>
      </v-card-text>
      <v-card-actions>
        <v-row>
          <v-col cols="6" class="d-flex justify-content-center">
            <v-btn
              class="text-color-main bg-white"
              rounded
              @click="$router.go(-1)"
              ><v-icon>mdi-arrow-left</v-icon> Retour</v-btn
            >
          </v-col>
          <v-col cols="6" class="d-flex justify-content-center">
            <v-btn
              class="bg-color-main text-white"
              :disabled="disabled"
              @click="sendTuto()"
              rounded
              ><v-icon>mdi-send</v-icon>Envoyer</v-btn
            >
          </v-col>
        </v-row>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
export default {
  props: ["idElement", "nomElement", "element"],
  data() {
    return {
      dialog: true,
      alreadyClick: false,
      rightSelect: null,
      idUsers: [],
      cssSelect: null,
      disabled: true,
      arrayUsers: [],
      selectedItem: 0,
      arrayRights: [
        { text: "Admin", value: 1 },
        { text: "Lecture seule", value: 0 },
      ],
    };
  },
  watch: {
    idUsers(val) {
      if (val.length == 0) {
        this.disabled = true;
      } else {
        this.disabled = false;
      }
    },
  },
  computed: {
    title() {
      if (this.nomElement == "tutos") {
        return "À qui envoyer ce tuto ?";
      } else {
        return "À qui envoyer ce dossier ?";
      }
    },
  },
   mounted() {
    this.$axios.get("friends/listFriends").then((response) => {
      this.arrayUsers = response.data;
    });
  },
  methods: {
    sendTuto() {
      if (this.idUsers.length == 0 || this.idUsers == []) {
        if (this.rightSelect == null && this.element.isOwn) {
          this.$swal({
            toast: true,
            title:
              "Vous devez choisir un droit à appliquer à vos ami(e)s dans la liste !",
            icon: "warning",
            animation: true,
            position: "center",
            showConfirmButton: false,
            timerProgressBar: true,
            timer: 2000,
          });
        } else {
          this.$swal({
            toast: true,
            title:
              "Vous devez d'abord rechercher au moins un ami(e) et ensuite envoyer !",
            icon: "warning",
            animation: true,
            position: "center",
            showConfirmButton: false,
            timerProgressBar: true,
            timer: 2000,
          });
        }
      } else {
        if (this.rightSelect == null && this.element.isOwn) {
          this.$swal({
            toast: true,
            title:
              "Vous devez choisir un droit à appliquer à vos ami(e)s dans la liste !",
            icon: "warning",
            animation: true,
            position: "center",
            showConfirmButton: false,
            timerProgressBar: true,
            timer: 2000,
          });
        } else {
          var payload = {
            idElement: this.$router.history.current.params.idElement,
            idUsers: this.idUsers,
            rightSelect: this.rightSelect,
          };
          var nomElement = null;
          if (this.nomElement == "dossier") {
            nomElement = "folder";
          } else {
            nomElement = this.nomElement;
          }
          this.$axios
            .post("/" + nomElement + "/send", payload)
            .then((response) => {
              if (response.data.messageError) {
                this.$swal("Oups !", response.data.messageError, "error");
                return;
              }
              if (response.data.messagePremium) {
                this.$swal(
                  "Limite de tutos atteinte pour cet(te) ami(e) !",
                  response.data.messagePremium,
                  "error"
                );
                return;
              }
              this.$swal({
                toast: true,
                title: response.data.message,
                icon: "success",
                animation: true,
                position: "top-right",
                showConfirmButton: false,
                timerProgressBar: true,
                timer: 2000,
              });
              this.$router.go(-1);
            });
        }
      }
    },
    viewTuto() {
      this.$router.push({
        name: "Mes tutos",
        params: { tutoId: this.idElement },
      });
    },
  },
};
</script>

<style scoped>
.theme--light.v-chip:not(.v-chip--active) {
  background-color: #2596be !important;
}
.content {
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
</style>