<template>
  <div class="text-center">
    <v-menu v-model="menu" :close-on-content-click="false" :nudge-width="200" offset-x>
      <template v-slot:activator="{ on, attrs }">
        <v-btn icon dark v-bind="attrs" v-on="on">
          <div v-if="nbNotifActif">
            <v-badge color="red" overlap :content="nbNotifActif">
              <v-icon dark>mdi-bell</v-icon>
            </v-badge>
          </div>
          <div v-else>
            <v-icon dark>mdi-bell</v-icon>
          </div>
        </v-btn>
      </template>
      <v-card>
        <v-row>
          <v-col>
            <v-list>
              <v-list-item @click="goToAccount()" class="m-1">
                <v-list-item-avatar class="bg-primary text-white m-1">
                  <v-img width="50" :src="user.photo"></v-img>
                </v-list-item-avatar>

                <v-list-item-content>
                  <v-list-item-title>{{ user.pseudo }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-col>
          <v-col cols="3" class="m-2">
            <v-btn @click="menu = false" icon>
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-col>
        </v-row>
        <div class="d-flex justify-content-center">
          <v-btn text v-if="receptions.length != 0" @click="openDialogDelete()">
            Supprimer les notifications
          </v-btn>
        </div>

        <v-divider></v-divider>

        <div v-if="receptions.length == 0">
          <p class="text-center p-3 fs-4">
            Aucune notification <br />
            reçue !
          </p>
        </div>
        <div v-else style="overflow: auto">
          <v-list dense>
            <v-list-item-group v-model="selectedItem">
              <v-virtual-scroll :items="receptions" height="400" item-height="150">
                <template v-slot:default="{ item }">
                  <v-list-item>
                    <v-list-item-content @click="changeStatutReception(item)">
                      <span class="badge badge-pill bg-primary badge-primary" v-if="item.is_actif">Nouveau</span>
                      <p class="lead" style="font-size: 20px" v-if="
                        item.model_type == 'Comment' ||
                        item.model_type == 'CommentReply'
                      ">
                        <v-icon class="text-color-main">mdi-comment</v-icon>
                        {{ strMimified(item.text) }}
                      </p>
                      <p class="lead" v-else style="font-size: 20px">
                        <v-icon class="text-color-main" v-if="item.model_type == 'Dossier'">mdi-folder</v-icon>
                        <v-icon class="text-color-main" v-else-if="item.model_type == 'Friend'">mdi-account-supervisor
                        </v-icon>
                        <v-icon class="text-color-main" v-else-if="item.model_type == 'Tuto'">mdi-file-document</v-icon>
                        <v-icon class="text-color-main" v-else-if="item.model_type == 'Support'">mdi-face-agent</v-icon>
                        {{ item.text }}
                      </p>
                      <p>le {{ formatDate(item.date) }}</p>
                    </v-list-item-content>
                  </v-list-item>
                </template>
              </v-virtual-scroll>
              <hr class="text-dark" />
            </v-list-item-group>
          </v-list>
        </div>
      </v-card>
    </v-menu>
    <dialog-confirm :titre="arrayDialog.titre" :message="arrayDialog.message"
      :functionCalled="arrayDialog.functionCalled" type="notification"></dialog-confirm>
  </div>
</template>
<script>
import DialogConfirm from '@/components/modal/DialogConfirm.vue';
import moment from "moment";
moment.locale("fr");
export default {
  components: { DialogConfirm },
  data: () => ({
    user: [],
    fav: true,
    menu: false,
    benched: 0,
    userInitial: null,
    message: false,
    hints: true,
    nbNotifActif: 0,
    receptions: [],
    loader: true,
    editedItem: [],
    selectedItem: [],
    dialogDelete: false,
    arrayDialog: {
      titre: "",
      message: "",
      functionCalled: "",
    }
  }),
   mounted() {
    var refreshIntervalId = setInterval(() => {
      if (this.$store.getters["auth/dataLoad"]) {
        if (
          this.$store.getters["auth/authenticated"] &&
          this.$store.getters["auth/isValidated"]
        ) {
          this.getReceptions();
          this.$store
            .dispatch("notifications/checkIfiAmNotification")
            .then((nb) => {
              this.nbNotifActif = nb;
            });
          this.user = this.$store.getters["auth/user"];
          this.userInitial = this.user.pseudo.charAt(0).toUpperCase();
          clearInterval(refreshIntervalId);
        } else if (
          this.$store.getters["auth/authenticated"] &&
          this.$store.getters["auth/isValidated"] == false
        ) {
          clearInterval(refreshIntervalId);
        }
      }
    }, 1000);
  },
  methods: {
    getReceptions() {
      this.$axios("/receptions/index").then((response) => {
        this.receptions = response.data;
        this.loader = false;
      });
    },
    goToAccount() {
      if (this.$route.name != "Profil") {
        this.$router.push({ name: "Profil" });
      }
    },
    dialogFriendsRequest(reception) {
      this.arrayDialog['message'] = "Voulez-vous accepter cette demande d'ami(e) ?"
      this.arrayDialog['titre'] = "Que voulez-vous faire ?"
      this.arrayDialog['functionCalled'] = "yesOrNotFriendsRequest"
      this.editedItem = reception;
      this.$store.dispatch('modal/setOpen', 'notification')
    },
    yesOrNotFriendsRequest(reception) {
      if (reception.model_type == "Friend") {
        this.$swal({
          title: "Voulez-vous accepter cette demande d'ami(e) ?",
          showDenyButton: true,
          showCancelButton: false,
          confirmButtonText: "Oui",
          denyButtonText: `Non`,
        }).then((result) => {
          if (result.isConfirmed) {
            this.$axios
              .get("/receptions/acceptRequestFriend/" + reception.from_user_id)
              .then((response) => {
                if (response.data.messageError) {
                  this.$swal("Oups !", response.data.messageError, "error");
                  return;
                }
                this.$swal(
                  "Accepté !",
                  "Votre ami(e) a bien été enregistré(e) !",
                  "success"
                ).then(() => {
                  this.getReceptions();
                  document.location.reload();
                });
              });
          } else if (result.isDenied) {
            this.$axios
              .get("/receptions/deniedRequestFriend/" + reception.from_user_id)
              .then((response) => {
                if (response.data.messageError) {
                  this.$swal("Mince !", response.data.messageError, "error");
                  return;
                }
                this.$swal(
                  "Refusé !",
                  "Vous avez refusé la demande d'ami(e) !",
                  "info"
                ).then(() => {
                  this.getReceptions();
                  document.location.reload();
                });
              });
          }
        });
      } else {
        document.location.reload();
      }
    },
    changeStatutReception(reception) {
      if (reception.is_actif && reception.model_type == "Friend") {
        this.$axios
          .get("/receptions/notActif/" + reception.id)
          .then((response) => {
            this.getReceptions();
            this.$store.dispatch(
              "notifications/setNotifications",
              response.data
            );
            this.nbNotifActif =
              this.$store.getters["notifications/has_notification"];
            this.yesOrNotFriendsRequest(reception);
          });
      } else if (reception.model_type == "Friend" && !reception.is_actif) {
        this.yesOrNotFriendsRequest(reception);
      } else if (reception.is_actif && reception.model_type == "Tuto") {
        this.$axios
          .get("/receptions/notActif/" + reception.id)
          .then((response) => {
            this.getReceptions();
            this.$store.dispatch(
              "notifications/setNotifications",
              response.data
            );
            this.nbNotifActif =
              this.$store.getters["notifications/has_notification"];
            this.$router
              .push({
                name: "Contenu du tuto",
                params: {
                  code_tuto: reception.code,
                  slug: this.slugify(reception.libelle, "-"),
                },
              })
              .then(() => {
                document.location.reload();
              });
          });
      } else if (!reception.is_actif && reception.model_type == "Tuto") {
        this.$router
          .push({
            name: "Contenu du tuto",
            params: {
              code_tuto: reception.code,
              slug: this.slugify(reception.libelle, "-"),
            },
          })
          .then(() => {
            document.location.reload();
          });
      } else if (reception.model_type == "Dossier" && reception.is_actif) {
        this.$axios
          .get("/receptions/notActif/" + reception.id)
          .then((response) => {
            this.getReceptions();
            this.$store.dispatch(
              "notifications/setNotifications",
              response.data
            );
            this.nbNotifActif =
              this.$store.getters["notifications/has_notification"];
            if (this.$route.name != "Dossiers") {
              this.$router
                .push({
                  name: "Dossiers",
                })
                .then(() => {
                  document.location.reload();
                });
            }
          });
      } else if (reception.model_type == "Dossier" && !reception.is_actif) {
        if (this.$route.name != "Mes dossiers") {
          this.$router
            .push({
              name: "Dossiers",
            })
            .then(() => {
              document.location.reload();
            });
        }
      } else if (
        (reception.model_type == "Comment" ||
          reception.model_type == "CommentReply") &&
        reception.is_actif
      ) {
        this.$axios
          .get("/receptions/notActif/" + reception.id)
          .then((response) => {
            this.getReceptions();
            this.$store.dispatch(
              "notifications/setNotifications",
              response.data
            );
            this.nbNotifActif =
              this.$store.getters["notifications/has_notification"];
            this.$router
              .push({
                name: "Contenu du tuto",
                params: {
                  code_tuto: reception.code,
                  slug: this.slugify(reception.libelle, "-"),
                },
              })
              .then(() => {
                document.location.reload();
              });
          });
      } else if (
        (reception.model_type == "Comment" ||
          reception.model_type == "CommentReply") &&
        !reception.is_actif
      ) {
        this.$router
          .push({
            name: "Contenu du tuto",
            params: {
              code_tuto: reception.code,
              slug: this.slugify(reception.libelle, "-"),
            },
          })
          .then(() => {
            document.location.reload();
          });
      } else if (reception.model_type == "Support" && reception.is_actif) {
        this.$axios
          .get("/receptions/notActif/" + reception.id)
          .then((response) => {
            this.getReceptions();
            this.$store.dispatch(
              "notifications/setNotifications",
              response.data
            );
            this.nbNotifActif =
              this.$store.getters["notifications/has_notification"];
            this.$router
              .push({
                name: "Contenu du ticket",
                params: { idTicket: reception.model_id },
              })
              .then(() => {
                document.location.reload();
              });
          });
      } else if (reception.model_type == "Support" && !reception.is_actif) {
        this.$router
          .push({
            name: "Contenu du ticket",
            params: { idTicket: reception.model_id },
          })
          .then(() => {
            document.location.reload();
          });
      }
    },
    strMimified(value) {
      if (value.length > 60) {
        return value.substring(0, 60) + "..";
      } else {
        return value;
      }
    },
    confirmDeleteNotifications() {
      this.$axios.delete("receptions/deleteNotifs").then((response) => {
        if (response.data.messageError) {
          this.$swal(
            "Oups une erreur est survenu !",
            response.data.messageError,
            "error"
          );
          return;
        }
        this.$swal({
          toast: true,
          title: response.data.message,
          icon: "success",
          animation: true,
          position: "top-right",
          showConfirmButton: false,
          timerProgressBar: true,
          timer: 2000,
        });

        this.$store.dispatch('modal/setClose','notification')
        this.getReceptions();
      });
    },
    openDialogDelete() {
      this.arrayDialog['message'] = "Tu veux vraiment supprimer toutes les notifications ?"
      this.arrayDialog['titre'] = "Que voulez-vous faire ?"
      this.arrayDialog['functionCalled'] = "confirmDeleteNotifications"
      this.$store.dispatch('modal/setOpen', "notification")
    },
  },
};
</script>
<style>

</style>