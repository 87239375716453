<template>
    <div>
        <h1 class="text-center lead fs-1 mt-5">Votre compte a été validé !</h1>
        <center>
            <v-img src="/img/simpson-success.gif" width="500" class="mt-5"></v-img>
        </center>

        <p class="text-center mt-5 mb-5 fw-bold fs-5">Vous allez être rediriger, patientez...</p>
        <div class="d-flex justify-content-center">
            <v-btn class="bg-color-main text-white" large rounded :disabled="disabledBTN" @click="redirectTo('Tutos')">
                Cliquer ici, si vous <br> n'êtes pas rediriger</v-btn>
        </div>
    </div>
</template>
  
<script>
export default {
    metaInfo() {
        return {
            title: "Votre compte a été validé | Pocketuto ",
            meta: [
                { vmid: 'description', name: 'description', content: "Votre compte Pocketuto a été valider !" }
            ]
        }
    },
     mounted() {
        setTimeout(() => {
            this.redirectTo('Tutos')
        }, 3000)
    },
    methods: {
        redirectTo(path) {
            this.$router.push({ name: path })
            document.location.reload()
        }
    }
}
</script>
  
<style>

</style>