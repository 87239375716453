var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"text-center"},[_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-width":200,"offset-x":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","dark":""}},'v-btn',attrs,false),on),[(_vm.nbNotifActif)?_c('div',[_c('v-badge',{attrs:{"color":"red","overlap":"","content":_vm.nbNotifActif}},[_c('v-icon',{attrs:{"dark":""}},[_vm._v("mdi-bell")])],1)],1):_c('div',[_c('v-icon',{attrs:{"dark":""}},[_vm._v("mdi-bell")])],1)])]}}]),model:{value:(_vm.menu),callback:function ($$v) {_vm.menu=$$v},expression:"menu"}},[_c('v-card',[_c('v-row',[_c('v-col',[_c('v-list',[_c('v-list-item',{staticClass:"m-1",on:{"click":function($event){return _vm.goToAccount()}}},[_c('v-list-item-avatar',{staticClass:"bg-primary text-white m-1"},[_c('v-img',{attrs:{"width":"50","src":_vm.user.photo}})],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(_vm.user.pseudo))])],1)],1)],1)],1),_c('v-col',{staticClass:"m-2",attrs:{"cols":"3"}},[_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){_vm.menu = false}}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1)],1),_c('div',{staticClass:"d-flex justify-content-center"},[(_vm.receptions.length != 0)?_c('v-btn',{attrs:{"text":""},on:{"click":function($event){return _vm.openDialogDelete()}}},[_vm._v(" Supprimer les notifications ")]):_vm._e()],1),_c('v-divider'),(_vm.receptions.length == 0)?_c('div',[_c('p',{staticClass:"text-center p-3 fs-4"},[_vm._v(" Aucune notification "),_c('br'),_vm._v(" reçue ! ")])]):_c('div',{staticStyle:{"overflow":"auto"}},[_c('v-list',{attrs:{"dense":""}},[_c('v-list-item-group',{model:{value:(_vm.selectedItem),callback:function ($$v) {_vm.selectedItem=$$v},expression:"selectedItem"}},[_c('v-virtual-scroll',{attrs:{"items":_vm.receptions,"height":"400","item-height":"150"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var item = ref.item;
return [_c('v-list-item',[_c('v-list-item-content',{on:{"click":function($event){return _vm.changeStatutReception(item)}}},[(item.is_actif)?_c('span',{staticClass:"badge badge-pill bg-primary badge-primary"},[_vm._v("Nouveau")]):_vm._e(),(
                      item.model_type == 'Comment' ||
                      item.model_type == 'CommentReply'
                    )?_c('p',{staticClass:"lead",staticStyle:{"font-size":"20px"}},[_c('v-icon',{staticClass:"text-color-main"},[_vm._v("mdi-comment")]),_vm._v(" "+_vm._s(_vm.strMimified(item.text))+" ")],1):_c('p',{staticClass:"lead",staticStyle:{"font-size":"20px"}},[(item.model_type == 'Dossier')?_c('v-icon',{staticClass:"text-color-main"},[_vm._v("mdi-folder")]):(item.model_type == 'Friend')?_c('v-icon',{staticClass:"text-color-main"},[_vm._v("mdi-account-supervisor ")]):(item.model_type == 'Tuto')?_c('v-icon',{staticClass:"text-color-main"},[_vm._v("mdi-file-document")]):(item.model_type == 'Support')?_c('v-icon',{staticClass:"text-color-main"},[_vm._v("mdi-face-agent")]):_vm._e(),_vm._v(" "+_vm._s(item.text)+" ")],1),_c('p',[_vm._v("le "+_vm._s(_vm.formatDate(item.date)))])])],1)]}}])}),_c('hr',{staticClass:"text-dark"})],1)],1)],1)],1)],1),_c('dialog-confirm',{attrs:{"titre":_vm.arrayDialog.titre,"message":_vm.arrayDialog.message,"functionCalled":_vm.arrayDialog.functionCalled,"type":"notification"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }