<template>
  <div>
    <div v-infinite-scroll="loadTimeLine" infinite-scroll-disabled="busy" :infinite-scroll-limit="maxFriends"
      infinite-scroll-distance="5" class="container">
      <div class="container mt-5">
        <div class="d-flex justify-content-end">
          <v-btn class="bg-color-main text-white p-4 mb-2" rounded @click="dialogAddFriend = true">
            <v-icon>mdi-account-plus</v-icon>
          </v-btn>
        </div>
        <v-text-field v-model="search" filled clearable prepend-inner-icon="mdi-magnify" rounded
          label="Rechercher un ami(e)"></v-text-field>
      </div>

      <div v-if="loader" class="container">
        <div class="container d-flex justify-content-center mt-5">
          <div class="text-center">
            <v-progress-circular :size="30" :width="7" color="#2596be" indeterminate></v-progress-circular>
            <p class="text-center text-color-main fw-bold fs-2">
              Chargement de vos ami(e)s
            </p>
          </div>
        </div>
        <v-skeleton-loader class="rounded-xl"
          type="list-item-avatar, list-item-avatar, list-item-avatar, list-item-avatar, list-item-avatar, list-item-avatar, list-item-avatar, list-item-avatar, list-item-avatar, list-item-avatar, list-item-avatar, list-item-avatar, list-item-avatar, list-item-avatar, list-item-avatar, list-item-avatar, list-item-avatar, list-item-avatar, list-item-avatar, list-item-avatar, list-item-avatar">
        </v-skeleton-loader>
      </div>
      <div v-else-if="friendsFilter.length == 0" class="container">
        <p>Aucun ami(e) trouvé(e) !</p>
      </div>
      <div style="padding-bottom: 50px" v-else-if="friends.length > 0">
        <div class="mb-5">
          <v-list class="rounded-xl transparent">
            <v-list-item-group v-for="friend in friendsFilter" :key="friend.id" color="primary">
              <v-menu absolute v-if="friend.status" offset-y transition="slide-y-transition" style="max-width: 600px"
                transparent class="bg-transparent">
                <template v-slot:activator="{ on, attrs }">
                  <v-list-item v-bind="attrs" v-on="on">
                    <v-list-item-avatar class="bg-primary text-white m-2">
                      <v-img :src="friend.photo"></v-img>
                    </v-list-item-avatar>
                    <v-list-item-content class="text-dark fw-bold">
                      <v-list-item-title class="text-color-main">
                        {{ friend.pseudo }}</v-list-item-title>
                    </v-list-item-content>
                    <v-list-item-action class="fw-bold text-color-main">
                      Ami(e)
                    </v-list-item-action>
                  </v-list-item>
                </template>
                <div dense class="bg-transparent transparent">
                  <v-list-item dark class="bg-danger text-white" @click="dialogDeleteFriend(friend.friend_id)">
                    <v-icon>mdi-delete</v-icon>Supprimer cet(te) ami(e)
                    ?
                  </v-list-item>
                  <v-list-item class="bg-warning text-dark" @click="dialogBloqueFriend(friend.friend_id)">
                    <v-icon>mdi-cancel</v-icon>Bloquer cet(te) ami(e)
                    ?
                  </v-list-item>
                </div>
              </v-menu>
              <v-menu absolute offset-y v-else transition="slide-y-transition" style="max-width: 600px">
                <template v-slot:activator="{ on, attrs }">
                  <v-list-item class="text-muted" v-bind="attrs" v-on="on">
                    <v-list-item-avatar class="bg-secondary text-white m-2">
                      <v-img :src="friend.photo"></v-img>
                    </v-list-item-avatar>
                    <v-list-item-content>
                      <v-list-item-title>
                        {{ friend.pseudo }}</v-list-item-title>
                    </v-list-item-content>
                    <v-list-item-action> En attente </v-list-item-action>
                  </v-list-item>
                </template>
                <v-list dense class="bg-warning text-dark">
                  <v-list-item>
                    <v-btn class="bg-warning text-dark" text @click="openCancelFriendsRequestModal(friend.friend_id)">
                      <v-icon>mdi-account-remove</v-icon> Annuler la demande
                      d'ami(e) ?
                    </v-btn>
                  </v-list-item>
                </v-list>
              </v-menu>
            </v-list-item-group>
            <div v-if="loaderFriends" class="rounded-xl">
              <div class="container d-flex justify-content-center mt-5">
                <div class="text-center">
                  <v-progress-circular :size="30" :width="7" color="#2596be" indeterminate></v-progress-circular>
                  <p class="text-center text-color-main fw-bold fs-2">
                    Chargement de vos ami(e)s
                  </p>
                </div>
              </div>
              <v-skeleton-loader class="rounded-xl"
                type="list-item-avatar, list-item-avatar, list-item-avatar, list-item-avatar, list-item-avatar, list-item-avatar, list-item-avatar, list-item-avatar, list-item-avatar, list-item-avatar, list-item-avatar, list-item-avatar">
              </v-skeleton-loader>
            </div>
          </v-list>
        </div>
      </div>
      <div v-else>
        <v-card class="rounded-xl">
          <h3 class="text-dark lead text-center mt-5 p-5">
            Vous n'avez pas encore d'ami(e)s.
          </h3>
        </v-card>
      </div>
    </div>

    <v-dialog v-model="dialogAddFriend" persistent max-width="600px">
      <v-card class="rounded-xl">
        <v-card-title>
          <span class="text-h5">Ajouter un(e) ami(e)</span>
        </v-card-title>
        <v-form v-model="validFriends">
          <v-card-text>
            <v-container>
              <v-text-field filled prepend-inner-icon="mdi-magnify" rounded v-model="editedItem.pseudo"
                label="Pseudo de votre ami(e)" v-on:keydown.enter.prevent="addFriends()" required></v-text-field>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="#ffffff" rounded class="text-color-main m-1" @click="dialogAddFriend = false">
              <v-icon>mdi-arrow-left</v-icon>Fermer
            </v-btn>
            <v-btn class="bg-color-main text-white" :disabled="!validFriends" @click="addFriends()" rounded>
              <v-icon>mdi-content-save</v-icon>AJOUTER
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
    <dialog-confirm :titre="arrayDialog.titre" :message="arrayDialog.message"
      :functionCalled="arrayDialog.functionCalled" type="friends"></dialog-confirm>
  </div>
</template>

<script>
/* eslint-disable */
import DialogConfirm from '@/components/modal/DialogConfirm.vue';
import infiniteScroll from "vue-infinite-scroll";
export default {
  directives: {
    infiniteScroll,
  },
  components: { DialogConfirm },
  data() {
    return {
      friends: [],
      search: null,
      dialogAddFriend: false,
      validFriends: true,
      friend: [],
      friendsFilter: [],
      dialogActions: false,
      dialogDelete: false,
      dialogBloque: false,
      dialogCancelRequestModal: false,
      loader: true,
      loaderFriends: true,
      maxFriends: 0,
      limitTimeLine: 20,
      idFriend: null,
      idOfMyFriends: null,
      editedItem: {},
      requiredRules: [(v) => !!v || "Le champ est obligatoire"],
      arrayDialog: {
        titre: "",
        message: "",
        functionCalled: "",
      }
    };
  },
  /*  mounted() {
    this.getFriends();
  }, */
  props: ["loadData"],
  watch: {
    loadData() {
      this.getFriends();
    },
    search(val) {
      if (val) {
        if (val.length > 2) {
          this.$axios.get("/friends/searchFriends/" + val).then((response) => {
            this.friendsFilter = response.data;
          });
        }
        if (val.length == 0) {
          this.friendsFilter = this.friends;
        }
      } else {
        this.friendsFilter = this.friends;
      }
    },
  },
  methods: {
    getFriends() {
      this.$axios
        .get("/friends/index/" + this.limitTimeLine)
        .then((response) => {
          this.friends = response.data.friends;
          this.friendsFilter = this.friends;
          this.maxFriends = response.data.maxFriends;
          this.loader = false;
          this.loaderFriends = false;
        });
    },
    onPageChange() {
      this.getFriends();
    },
    loadTimeLine() {
      if (this.maxFriends > this.friends.length && !this.loaderFriends) {
        this.loaderFriends = true;
        setTimeout(() => {
          this.limitTimeLine = this.limitTimeLine + 10;
          this.getFriends();
        }, 1000);
      }
    },
    addFriends() {
      this.$axios.post("/friends/store", this.editedItem).then((response) => {
        if (response.data.messageError) {
          this.$swal("Oups !", response.data.messageError, "error");
          return;
        }
        this.$swal({
          toast: true,
          title: response.data.message,
          icon: "success",
          animation: true,
          position: "top-right",
          showConfirmButton: false,
          timerProgressBar: true,
          timer: 2000,
        });
        if (response.data.trophyWins) {
          var trophyWins = response.data.trophyWins;
          Object.entries(trophyWins).forEach(([key, val]) => {
            const options = {
              title: val.libelle,
              imageUrl: val.image,
              imageHeight: "100px",
              text: val.message,
            };
            this.$swal(options);
          });
        }
        this.getFriends();
        this.editedItem = {
          pseudo: "",
        };
        this.dialogAddFriend = false;
      });
    },
    openCancelFriendsRequestModal(id) {
      this.arrayDialog['message'] = "Voulez-vous vraiment annuler cette demande d'ami(e) ?"
      this.arrayDialog['titre'] = "Que voulez-vous faire ?"
      this.arrayDialog['functionCalled'] = "cancelRequestFriends"
      this.idFriend = id;
      this.$store.dispatch('modal/setOpen', 'friends')
    },
    cancelRequestFriends() {
      this.$axios
        .get("/friends/cancelFriendsRequest/" + this.idFriend)
        .then((response) => {
          if (response.data.messageError) {
            this.$swal(
              "Une erreur est survenu !",
              response.data.messageError,
              "error"
            ).then(() => {
              this.idFriend = null;
              this.$store.dispatch('modal/setClose', 'friends')
            });

            return;
          }
          this.$swal({
            toast: true,
            title: response.data.message,
            icon: "success",
            animation: true,
            position: "top-right",
            showConfirmButton: false,
            timerProgressBar: true,
            timer: 2000,
          });
          this.idFriend = null;
          this.$store.dispatch('modal/setClose', 'friends')
          this.getFriends();
        });
    },
    dialogBloqueFriend(id) {
      this.arrayDialog['message'] = "Voulez-vous vraiment bloquer cette ami(e) ?"
      this.arrayDialog['titre'] = "Que voulez-vous faire ?"
      this.arrayDialog['functionCalled'] = "confirmBloqueFriend"
      this.idOfMyFriends = id;
      this.$store.dispatch('modal/setOpen', 'friends')
    },
    confirmBloqueFriend() {
      this.$axios.get("/friends/bloque/" + this.idOfMyFriends).then((response) => {
        if (response.data.messageError) {
          this.$swal(
            "Une erreur est survenu !",
            response.data.messageError,
            "error"
          ).then(() => {
            this.$store.dispatch('modal/setClose', 'friends')
            this.dialogActions = false;
          });

          return;
        }
        this.$swal({
          toast: true,
          title: response.data.message,
          icon: "success",
          animation: true,
          position: "top-right",
          showConfirmButton: false,
          timerProgressBar: true,
          timer: 2000,
        });
        this.$store.dispatch('modal/setClose', 'friends')
        this.dialogActions = false;
        this.getFriends();
      });

    },
    dialogDeleteFriend(id) {
      this.arrayDialog['message'] = "Voulez-vous vraiment supprimer cette ami(e) ?"
      this.arrayDialog['titre'] = "Que voulez-vous faire ?"
      this.arrayDialog['functionCalled'] = "confirmDeleteFriends"
      this.idOfMyFriends = id;
      this.$store.dispatch('modal/setOpen', 'friends')
    },
    confirmDeleteFriends() {
      this.$axios
        .delete("/friends/delete/" + this.idOfMyFriends)
        .then((response) => {
          if (response.data.messageError) {
            this.$swal(
              "Limite max atteinte !",
              response.data.messageError,
              "error"
            ).then(() => {
              this.dialogActions = false;
              this.$store.dispatch('modal/setClose', 'friends')
            });

            return;
          }
          this.$swal({
            toast: true,
            title: response.data.message,
            icon: "success",
            animation: true,
            position: "top-right",
            showConfirmButton: false,
            timerProgressBar: true,
            timer: 2000,
          });
          this.dialogActions = false;
          this.$store.dispatch('modal/setClose', 'friends')
          this.getFriends();
        });

    },
  },
};
</script>

<style>

</style>