/* eslint-disable */
import axios from 'axios'
export default {
    namespaced: true,
    state: {
        notifications: 0
    },
    mutations: {
        SET_NOTIFICATION(state, notifications) {
            state.notifications = notifications
        },
    },
    getters: {
        has_notification(state) {
            return state.notifications
        },
    },
    actions: {
        setNotifications({ commit }, data) {
            commit("SET_NOTIFICATION", data)
        },
        checkIfiAmNotification({ commit }) {
            return new Promise((resolve, reject) => {
                axios.get("/receptions/checkIfiAmNotification").then((response) => {
                    commit('SET_NOTIFICATION', response.data);
                    resolve(response.data)
                });
            })
        },
    }
}