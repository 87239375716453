<template>
  <div>
    <div class="container mt-5 rounded-xl">
      <h2 class="text-center text-dark p-3 bg-color-main rounded-xl">Les statistiques</h2>
      <v-row>
        <v-col>
          <v-card class="mx-auto rounded-xl" color="#0BEC0B" dark max-width="400">
            <v-card-title>
              <v-icon large left> mdi-format-list-numbered </v-icon>
              <span class="text-h6 fw-light text-break">Tutos total</span>
            </v-card-title>

            <v-card-text class="fs-1 text-white text-center fw-bold">
              <strong>{{ nbTutosTotal }}</strong>
            </v-card-text>

            <v-card-actions> </v-card-actions>
          </v-card>
           <v-card class="mx-auto mt-4 rounded-xl" color="primary" dark max-width="400">
            <v-card-title>
              <v-icon large left> mdi-file </v-icon>
              <span class="text-h6 fw-light text-break">Tutos public</span>
            </v-card-title>

            <v-card-text  class="fs-1 text-white text-center fw-bold">
              <strong>{{ nbTutoPublic }}</strong>
            </v-card-text>

            <v-card-actions> </v-card-actions>
          </v-card>
        </v-col>
        <v-col>
          <v-card class="mx-auto rounded-xl" color="#4D26DA" dark max-width="400">
            <v-card-title>
              <v-icon large left> mdi-account-group </v-icon>
              <span class="text-h6 fw-light text-break">Users total</span>
            </v-card-title>

            <v-card-text  class="fs-1 text-white text-center fw-bold">
              <strong>{{ nbUsers }}</strong>
            </v-card-text>

            <v-card-actions> </v-card-actions>
          </v-card>
           <v-card class="mx-auto rounded-xl mt-4" color="info" dark max-width="400">
            <v-card-title>
              <v-icon large left> mdi-file-lock-open-outline </v-icon>
              <span class="text-h6 fw-light text-break">Tutos privée</span>
            </v-card-title>

            <v-card-text class="fs-1 text-white text-center fw-bold">
              <strong>{{ nbTutoPrivate }}</strong>
            </v-card-text>

            <v-card-actions> </v-card-actions>
          </v-card>
        </v-col>
        <v-col>
          <v-card class="mx-auto rounded-xl" color="#DA2626" dark max-width="400">
            <v-card-title>
              <v-icon large left> mdi-share-variant </v-icon>
              <span class="text-h6 fw-light text-break">Partage de tuto</span>
            </v-card-title>

            <v-card-text class="fs-1 text-white text-center fw-bold">
              {{ nbPartage }}
            </v-card-text>

            <v-card-actions> </v-card-actions>
          </v-card>
           <v-card class="mx-auto mt-4 rounded-xl" color="warning" dark max-width="400">
            <v-card-title>
              <v-icon large left> mdi-folder-multiple </v-icon>
              <span class="text-h6 fw-light text-break">Folders créés</span>
            </v-card-title>

            <v-card-text  class="fs-1 text-white text-center fw-bold">
              <strong>{{ nbFolders }}</strong>
            </v-card-text>

            <v-card-actions> </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
      <v-row>
        <v-col :cols="colsResponsive">
          <v-card
            class="mx-auto text-center m-2 rounded-xl"
            color="green"
            dark
            max-width="600"
          >
            <v-card-text>
              <v-sheet color="rgba(0, 0, 0, .12)">
                <v-sparkline
                  :value="arrayChartsTutoValue"
                  color="rgba(255, 255, 255, .7)"
                  height="100"
                  padding="24"
                  :labels="arrayChartsTutoLabel"
                  stroke-linecap="round"
                  smooth
                >
                  <template v-slot:label="item"> {{ item.value }} </template>
                </v-sparkline>
              </v-sheet>
            </v-card-text>

            <v-card-text>
              <div class="text-h4 font-weight-thin">
                Création de tuto par mois
              </div>
            </v-card-text>

            <v-divider></v-divider>
          </v-card>
        </v-col>
        <v-col :cols="colsResponsive">
          <v-card
            class="mx-auto text-center m-2 rounded-xl"
            color="dark"
            dark
            max-width="600"
          >
            <v-card-text>
              <v-sheet color="rgba(0, 0, 0, .12)">
                <v-sparkline
                  :value="arrayChartsUserValue"
                  color="rgba(255, 255, 255, .7)"
                  height="100"
                  padding="24"
                  :labels="arrayChartsUserLabel"
                  stroke-linecap="round"
                  smooth
                >
                  <template v-slot:label="item"> {{ item.value }} </template>
                </v-sparkline>
              </v-sheet>
            </v-card-text>

            <v-card-text>
              <div class="text-h4 font-weight-thin">
                Création de compte par mois
              </div>
            </v-card-text>

            <v-divider></v-divider>
          </v-card>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
import moment from "moment";
moment.locale("fr");
export default {
  data() {
    return {
      requiredRules: [(v) => !!v || "Le champ est obligatoire"],
      valid: true,
      nbTutosTotal: null,
      nbPartage: null,
      nbUsers: null,
      nbTutoPublic: null,
      nbTutoPrivate: null,
      arrayChartsTutoValue: [],
      arrayChartsTutoLabel: [],
      arrayChartsUserValue: [],
      arrayChartsUserLabel: [],
      nbFolders: [],
      roles: [],
    };
  },
  computed: {
    colsResponsive() {
      if(window.innerWidth < 800) {
        return 12
      } else {
       return 6
      }
    }
  },
  props: ["loadData"],
  watch: {
    loadData() {
      this.getStatistiques();
    },
  },
   mounted() {
    this.getStatistiques();
  },
  methods: {
    getStatistiques() {
      this.$axios.get("/admin/tutos/statistiques").then((response) => {
        this.nbTutosTotal = response.data.nbTutosTotal;
        this.nbPartage = response.data.nbPartage;
        this.nbUsers = response.data.nbUsers;
        this.arrayChartsTutoValue = response.data.chartsTutoValue;
        this.arrayChartsTutoLabel = response.data.chartsTutoLabel;
        this.arrayChartsUserValue = response.data.chartsUserValue;
        this.arrayChartsUserLabel = response.data.chartsUserLabel;
        this.nbTutoPublic = response.data.nbTutoPublic
        this.nbFolders = response.data.nbFolders;
        this.nbTutoPrivate = response.data.nbTutoPrivate
      });
    },
  },
};
</script>

<style>
</style>